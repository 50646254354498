import { takeEvery, put, call, select } from 'redux-saga/effects';
import _ from 'lodash';

import {
  GET_ADDRESS_SUGGESTIONS,
  GET_ADDRESS_DETAILS,
  GET_VALIDATE_ADDRESS_DETAILS,
  SUBMIT_CUSTOMER,
  GET_SIGNED_DOC,
  CREATE_ACCOUNT,
  GET_BRE_DETAILS,
} from '../actions/actionTypes';
import {
  getAddressSuggestionsSuccess,
  getAddressSuggestionsError,
  getValidateAddressDetailsSuccess,
  submitCustomerSuccess,
  getSignedDocSuccess,
  createAccountSuccess,
  setBreDetails,
  setBreErrorCode,
  setBrvError,
  setDaoProp,
  setBillingAddressDetails,
} from '../actions/daoActions';
import {
  getAddressSuggestions,
  getAddressDetails,
  getValidateAddressDetails,
  submitCustomer,
  getSignedDoc,
  createAccount,
  getBREBusinessRuleValidate,
} from '../../api/apiWrapper';
import { setCompanyKey, updateAddressDetail } from '../actions/companyActions';
import { companyLegalMapper } from '../../utils/constants';

export function* DAOSaga() {
  yield takeEvery(GET_ADDRESS_SUGGESTIONS, onGetAddressSuggestions);
  yield takeEvery(GET_ADDRESS_DETAILS, onGetAddressDetails);
  yield takeEvery(GET_VALIDATE_ADDRESS_DETAILS, onGetValidateAddressDetails);
  yield takeEvery(GET_BRE_DETAILS, onGetBreDetails);
  yield takeEvery(SUBMIT_CUSTOMER, onSubmitCustomer);
  yield takeEvery(GET_SIGNED_DOC, onGetSignedDoc);
  yield takeEvery(CREATE_ACCOUNT, onCreateAccount);
}

export function* onGetAddressSuggestions(action) {
  const { request, addressType } = action.payload;
  try {
    const response = yield call(getAddressSuggestions, request);
    yield put(getAddressSuggestionsSuccess(response, addressType));
  } catch (error) {
    yield put(getAddressSuggestionsError(error, addressType));
  }
}

export function* onGetAddressDetails(action) {
  const { request, addressType } = action.payload;
  try {
    const response = yield call(getAddressDetails, request);
    yield put(updateAddressDetail(response, addressType));
  } catch (error) {
    yield put(setDaoProp('addressDetail', {}));
  }
}

export function* onGetValidateAddressDetails(action) {
  const brvErrorList = {};
  let genericError: any = null;
  let comResponse: any = null;
  let billResponse: any = null;
  let brvRegistrationLst: any = [];

  try {
    const brvResponse = yield call(getValidateAddressDetails, action.payload.brvRequest);
    brvRegistrationLst = brvResponse.registrationLst ?? [];
    brvRegistrationLst = brvRegistrationLst.map((item) => ({
      ...item,
      mandatory: true,
    }));
    const isComAddress = brvResponse.companyAddresses.some((address) => address.addressType === 'COM');
    const isBillAddress = brvResponse.companyAddresses.some((address) => address.addressType === 'BIL');
    const companyNoAddress = brvResponse.companyAddresses.length === 0 && brvResponse.companyName;
    if (isComAddress || companyNoAddress) {
      comResponse = brvResponse;
    }
    if (isBillAddress) {
      billResponse = brvResponse;
    }
  } catch (error: any) {
    if (error.response.status === 400) {
      const parsedError = JSON.parse(error.response.data.message);
      parsedError.forEach((brvError) => {
        const legalId = brvError.brvErrFld.substring(companyLegalMapper.companyLegal.length);
        const newBrvError = {
          error: brvError.brvErrCode,
          brvErrorStatus: error.response.status,
        };
        brvErrorList[legalId] = newBrvError;
      });
    } else {
      genericError = error;
    }
  }

  if (genericError) {
    yield put(setBrvError(genericError));
  } else if (Object.keys(brvErrorList).length > 0) {
    yield put(setBrvError(brvErrorList));
  } else {
    yield put(setCompanyKey('brvRegistrationLst', brvRegistrationLst));
    yield put(getValidateAddressDetailsSuccess(comResponse));
    yield put(setBillingAddressDetails(billResponse));
    yield put(setDaoProp('brvHttpStatus', -1));
  }
}

export function* onGetBreDetails(action) {
  try {
    let breResponse: any = null;
    let errorResp: any = null;
    try {
      breResponse = yield call(getBREBusinessRuleValidate, action.payload);
      yield put(setDaoProp('breErrorCode', -1));
    } catch (error) {
      errorResp = error;
      yield put(setBreErrorCode(errorResp.response.status));
    }
    if (errorResp?.response?.status === 400) {
      const breResponse = errorResp?.response?.data;
      yield put(setBreDetails({ breRes: breResponse }));
    } else {
      yield put(setBreDetails({ breRes: breResponse }));
    }
  } catch (error) {
    yield put(setBreDetails(error));
  }
}

export function* onSubmitCustomer() {
  try {
    const { company, mandatoryLegalIds, companyConfig, brvRegistrationLst } = yield select((state) => state.company);
    const { contact, contactFields, validateMyBillData } = yield select((state) => state.contact);
    const { showConsent } = contactFields;
    const { acceptTermsAndConditions, serviceAgreementAndRateCard, receiveNewsAndUpdates } = yield select(
      (state) => state.review
    );
    const { clientId, etlList, isEtl, appTimers } = yield select((state) => state.app);
    const { paymentProfile } = yield select((state) => state.payment);
    const { paymentMethodType } = appTimers;
    const { optionFlow, submitPostData, genShipDoc } = yield select((state) => state.DAOReducer);
    const { selectedLanguage, altLanguageCode } = yield select((state) => state.account);

    const {
      title,
      firstName,
      lastName,
      country,
      email,
      countryCode,
      phoneType,
      number,
      ext,
      e164NoFmt,
      job,
      department,
      preferredCommunicationMethod,
    } = contact;
    const {
      companyName,
      tradingName,
      registeredCountry,
      companyWebsite,
      companyIndustry,
      vAddress,
      bAddress,
      legalCodes,
      companyAddressCountry,
      billingAddressCountry,
      branchCode = false,
    } = company;

    const { showTradingField } = companyConfig;

    const existingRegistrationLst = Object.keys(legalCodes).map((legalId) => {
      return {
        legalId,
        legalVal: legalCodes[legalId].toUpperCase(),
        mandatory: _.includes(mandatoryLegalIds, legalId),
      };
    });
    const combinedRegistrationLst = [...existingRegistrationLst, ...brvRegistrationLst];

    const payload = {
      customer: {
        dhlCountryCd: country[0].code,
        isoCountryCd: country[0].code,
        countryNm: country[0].name,
        title,
        firstNm: firstName,
        lastNm: lastName,
        email,
        ...(job && { jobTitle: job }),
        ...(department && { department }),
        phoneLst: [
          {
            type: phoneType,
            phoneCode: countryCode[0].callCode,
            number,
            ...(ext && { ext }),
            e164NoFmt: !e164NoFmt ? countryCode[0].callCode + number : e164NoFmt,
          },
        ].filter((element) => element !== null),
        ...(companyWebsite && { companyWebsite }),
        ...(companyIndustry.length > 0 && {
          companyIndustry: companyIndustry[0].name,
        }),
        registeredCtryCd: registeredCountry[0]?.code,
        registeredCtryNm: registeredCountry[0]?.name,
        ...(legalCodes && {
          registrationLst: combinedRegistrationLst,
        }),
        ...(branchCode && registeredCountry[0]?.code === 'TH' && { branchCd: branchCode }),
        companyNm: companyName,
        trdNm: showTradingField ? tradingName : '',
        addrLst: [
          {
            addrLine1: vAddress.addressLine1[0],
            addrLine2: vAddress.addressLine2,
            city: vAddress.city,
            postalCd: vAddress.zipCode,
            ...(vAddress.state && { state: vAddress.state }),
            ...(vAddress.province && { province: vAddress.province }),
            countryCd: companyAddressCountry[0]?.code,
            billing: false,
          },
          {
            addrLine1: bAddress.addressLine1[0],
            addrLine2: bAddress.addressLine2,
            city: bAddress.city,
            postalCd: bAddress.zipCode,
            ...(bAddress.state && { state: bAddress.state }),
            ...(bAddress.province && { province: bAddress.province }),
            countryCd: billingAddressCountry[0]?.code,
            billing: true,
          },
        ],
        acceptTermsAndConditions: showConsent ? contact.acceptTermsAndConditions : acceptTermsAndConditions,
        serviceAgreementAndRateCard,
        receiveNewsAndUpdates: showConsent ? contact.receiveNewsAndUpdates : receiveNewsAndUpdates,
        languageCd: selectedLanguage,
        ...(preferredCommunicationMethod && { prfComMth: preferredCommunicationMethod }),
      },
      srcApp: clientId,
      rejectReasonCd: etlList[0]?.code ?? '',
      conventional: isEtl || (showConsent && (paymentProfile.profileExists || !validateMyBillData.valid)),
      preferredLanguage: altLanguageCode,
      paymtMth: paymentMethodType,
      showConsentInContact: !!showConsent,
      accountRequestId: showConsent ? submitPostData.accountId : '',
      genShipDoc,
    };

    const response = yield call(submitCustomer, payload, optionFlow);
    yield put(submitCustomerSuccess(response));
  } catch (error) {
    yield put(submitCustomerSuccess(error));
  }
}

export function* onGetSignedDoc(action) {
  try {
    const response = yield call(getSignedDoc, action.payload);
    yield put(getSignedDocSuccess(response));
  } catch (error) {
    yield put(setDaoProp('signedDocData', {}));
  }
}

export function* onCreateAccount(action) {
  try {
    const response = yield call(createAccount, action.payload);
    yield put(createAccountSuccess(response));
  } catch (error: any) {
    yield put(setDaoProp('createAccountData', {}));
  }
}

export default DAOSaga;
