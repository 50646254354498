import { call, put, takeEvery } from 'redux-saga/effects';

import {
  GET_SUMMARY_STATUS,
  GET_RATES_SERVICE_DOCS,
  GET_SAVE_CONSENT,
  GET_CUSTOMER_DETAILS,
  GET_CUSTOMER_DETAILS_FLOW_TWO,
  PATCH_ACC_ACTIVITY,
  GET_DOCS_REGENERATE,
} from '../actions/actionTypes';
import {
  setSummaryStatus,
  setRatesServiceDocs,
  setSaveConsent,
  setServiceDocs,
  setCustomerDetails,
  setCustomerDetailsFlowTwo,
  setShippingProp,
  setDocuments,
  setDocumentsGenerateErrorCode,
} from '../actions/shippingActions';
import {
  getSummaryStatus,
  getCustomerDetails,
  getCustomerDetailsFlowTwo,
  getShippingRatesServiceDocs,
  getSaveConsent,
  patchAccountEntity,
  getDocsRegenerate,
} from '../../api/apiWrapper';
import { getCountryConfig, getCountryRefData } from '../actions/corporateAccountActions';
import { setAppProp } from '../actions/appActions';
import { setContact } from '../actions/contactActions';
import { setCompany } from '../actions/companyActions';

export function* shippingSaga() {
  yield takeEvery(GET_SUMMARY_STATUS, getSummaryStatusSaga);
  yield takeEvery(GET_CUSTOMER_DETAILS, getCustomerDetailsSaga);
  yield takeEvery(GET_CUSTOMER_DETAILS_FLOW_TWO, getCustomerDetailsFlowTwoSaga);
  yield takeEvery(GET_RATES_SERVICE_DOCS, getRatesServiceDocsSaga);
  yield takeEvery(GET_SAVE_CONSENT, getSaveConsentSaga);
  yield takeEvery(PATCH_ACC_ACTIVITY, patchAccActivitySaga);
  yield takeEvery(GET_DOCS_REGENERATE, onSetDocsRegenerateSaga);
}

export function* getSummaryStatusSaga(action) {
  try {
    const response = yield call(getSummaryStatus, action.payload);
    yield put(setSummaryStatus(response));
  } catch (error) {
    yield put(setSummaryStatus(error));
  }
}

export function* getCustomerDetailsSaga(action) {
  try {
    const response = yield call(getCustomerDetails, action.payload);
    const { customer } = response;
    const contact = {
      country: [
        {
          code: customer.isoCountryCd,
          name: customer.countryNm,
          callCode: customer.phoneLst[0].phoneCode,
        },
      ],
      title: customer.title,
      firstName: customer.firstNm,
      lastName: customer.lastNm,
      email: customer.email,
      phoneType: customer.phoneLst[0].type,
      countryCode: [
        {
          code: customer.isoCountryCd,
          name: customer.countryNm,
          callCode: customer.phoneLst[0].phoneCode,
        },
      ],
      number: customer.phoneLst[0].number,
      ext: customer.phoneLst[0].ext ?? '',
      job: customer.jobTitle ?? '',
      department: customer.department ?? '',
      salutations: [],
      phoneTypes: [],
      departments: [],
    };
    const legalCodes = Object.assign({}, ...customer.registrationLst.map((e) => ({ [e.legalId]: e.legalVal })));
    const vAddress = customer.addrLst.find((e) => e.billing === false);
    const bAddress = customer.addrLst.find((e) => e.billing === true);
    const sameAddress =
      vAddress.addrLine1 === bAddress.addrLine1 &&
      vAddress.addrLine2 === bAddress.addrLine2 &&
      vAddress.city === bAddress.city &&
      vAddress.state === bAddress.state &&
      vAddress.postalCd === bAddress.postalCd &&
      vAddress.province === bAddress.province;
    const company = {
      registeredCountry: [
        {
          code: customer.isoCountryCd,
          name: customer.countryNm,
          callCode: customer.phoneLst[0].phoneCode,
        },
      ],
      legalCodes,
      registrationNotPresent: false,
      validated: true,
      vAddress: {
        addressLine1: [vAddress.addrLine1],
        addressLine2: vAddress.addrLine2,
        city: vAddress.city,
        state: vAddress.state,
        zipCode: vAddress.postalCd,
        province: vAddress.province,
      },
      bAddress: {
        addressLine1: [bAddress.addrLine1],
        addressLine2: bAddress.addrLine2,
        city: bAddress.city,
        state: bAddress.state,
        zipCode: bAddress.postalCd,
        province: bAddress.province,
      },
      billingAddressSame: sameAddress,
      companyWebsite: customer.companyWebsite,
      companyIndustry: customer.companyIndustry[0].name,
      regnoCheckBox: true,
      companyName: customer.companyNm,
    };

    yield put(getCountryConfig({ srcApp: customer.sourceApp, ctryCd: customer.isoCountryCd }));
    yield put(getCountryRefData({ refDatCatTyCd: 'COUNTRY' }));
    yield put(setAppProp('clientId', response.sourceApp));
    // Now commenting the code. When start testing for one span need to get translation from akamai not from QL service.
    // yield put(getTranslations(customer.isoCountryCd));
    yield put(setContact(contact));
    yield put(setCompany(company));
    yield put(setCustomerDetails(response));
  } catch (error) {
    yield put(setCustomerDetails(error));
  }
}

export function* getCustomerDetailsFlowTwoSaga(action) {
  try {
    const response = yield call(getCustomerDetailsFlowTwo, action.payload);
    yield put(setCustomerDetailsFlowTwo(response));
  } catch (error) {
    yield put(setCustomerDetailsFlowTwo(error));
  }
}

export function* getRatesServiceDocsSaga(action) {
  try {
    const response = yield call(getShippingRatesServiceDocs, action.payload);
    yield put(setRatesServiceDocs(response));
    yield put(setServiceDocs(response));
    yield put(setDocuments(response));
  } catch (error) {
    yield put(setDocumentsGenerateErrorCode(error));
  }
}

export function* onSetDocsRegenerateSaga(action) {
  try {
    const response = yield call(getDocsRegenerate, action.payload);
    yield put(setShippingProp('docsRegenerate', response));
  } catch (error) {
    yield put(setShippingProp('docsRegenerate', ''));
  }
}

export function* getSaveConsentSaga(action) {
  try {
    const response = yield call(getSaveConsent, action.payload);
    yield put(setSaveConsent(response));
  } catch (error) {
    yield put(setSaveConsent(error));
  }
}
export function* patchAccActivitySaga(action) {
  try {
    const response = yield call(patchAccountEntity, action.payload);
    yield put(setSaveConsent(response));
  } catch (error) {
    yield put(setSaveConsent(error));
  }
}

export default shippingSaga;
