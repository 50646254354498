import {
  GET_COUNTRY_REF_DATA,
  GET_COUNTRY_REF_DATA_SUCCESS,
  GET_COUNTRY_CONFIG,
  GET_COUNTRY_CONFIG_SUCCESS,
  SET_THANK_YOU_PAGE,
  GENERATE_TRACK_ID_SUCCESS,
  SET_COUNTRY_REF_DATA_ERROR,
  GET_USER_TRACK_ID,
  SET_USER_TRACK_ID,
  SET_CORP_ACC_PROP,
  GET_LANDING_PAGE,
  SET_LANDING_PAGE,
  GET_IXOPAY_DETAILS,
  SET_IXOPAY_DETAILS,
} from '../actions/actionTypes';

const initialState = {
  loading: false,
  loadingCountryConfig: false,
  loadingUserTrackId: false,
  loadingLandingPage: false,
  countryData: {},
  countryConfigData: {
    ctryCfgs: [],
    ctryRules: {
      rules: [],
    },
  },
  userTrackId: '',
  thankYouPage: false,
  country: [],
  currentPage: null as number | null,
  landingPage: '',
  contactDaoRegex: {},
  contactBreRegex: {},
  companyLegalBreRegex: {},
  companyNameBreRegex: {},
  companyNameDaoRegex: {},
  visitingBreRegex: {},
  billingBreRegex: {},
  visitingDaoRegex: {},
  billingDaoRegex: {},
  isoCountryLists: [],
  isoCountriesListLocaleTranslation: [],
  ixopayDetails: {},
  loadingIxopayDetails: false,
  dhlPassTrkId: '',
  deviceType: '',
  deviceName: '',
};

const corporateAccountReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_COUNTRY_REF_DATA:
      return { ...state, loading: true };

    case GET_COUNTRY_REF_DATA_SUCCESS:
      return { ...state, countryData: action.payload, loading: false };

    case SET_COUNTRY_REF_DATA_ERROR:
      return { ...state, countryData: {}, loading: false };

    case GET_COUNTRY_CONFIG:
      return { ...state, loadingCountryConfig: true };

    case GET_COUNTRY_CONFIG_SUCCESS:
      return { ...state, countryConfigData: action.payload, loadingCountryConfig: false };

    case GENERATE_TRACK_ID_SUCCESS:
      return { ...state, userTrackId: action.payload, loadingCountryConfig: false };

    case SET_THANK_YOU_PAGE:
      return { ...state, thankYouPage: action.payload };

    case GET_USER_TRACK_ID:
      return { ...state, loadingUserTrackId: true };

    case SET_USER_TRACK_ID:
      return { ...state, userTrackId: action.payload, loadingUserTrackId: false };

    case SET_CORP_ACC_PROP:
      return { ...state, [action.payload.key]: action.payload.value };

    case GET_LANDING_PAGE:
      return { ...state, loadingLandingPage: true };

    case SET_LANDING_PAGE:
      return { ...state, landingPage: action.payload, loadingLandingPage: false };

    case GET_IXOPAY_DETAILS:
      return { ...state, loadingIxopayDetails: true };

    case SET_IXOPAY_DETAILS:
      return { ...state, ixopayDetails: action.payload, loadingIxopayDetails: false };

    default:
      return state;
  }
};

export default corporateAccountReducer;
