import {
  GET_CUSTOMER_INFO,
  GET_MAINTENANCE_STATUS,
  SET_APP_PROP,
  SET_CUSTOMER_INFO,
  SET_CUSTOMER_INFO_ERROR,
  SET_ETL_LIST,
  SET_ETL_STATUS,
  SET_MAINTENANCE_STATUS,
} from '../actions/actionTypes';

const initialState: any = {
  lang: 'en',
  isEtl: false,
  etlList: [],
  clientId: '',
  flow: {
    conventional: false,
  },
  appTimers: {},
  contactCompanyEtlList: [],
  blackListUrls: [],
  steps: [],
  redirectToMaintenance: -1,
  loadingCustomerInfo: false,
  customerInfoDhlPass: {},
  consentCookie: '',
};

const appReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ETL_STATUS:
      return { ...state, isEtl: action.payload };

    case SET_ETL_LIST:
      return { ...state, etlList: action.payload };

    case SET_APP_PROP:
      return { ...state, [action.payload.key]: action.payload.value };

    case GET_MAINTENANCE_STATUS: {
      return { ...state };
    }

    case SET_MAINTENANCE_STATUS: {
      return { ...state, redirectToMaintenance: action.payload };
    }

    case GET_CUSTOMER_INFO:
      return { ...state, loadingCustomerInfo: true };

    case SET_CUSTOMER_INFO:
      return { ...state, customerInfoDhlPass: action.payload, loadingCustomerInfo: false };

    case SET_CUSTOMER_INFO_ERROR:
      return { ...state, loadingCustomerInfo: false };

    default:
      return state;
  }
};

export default appReducer;
