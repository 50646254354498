import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import chevronBackImg from '../../../assets/icons/chevron_back_red.svg';

import { submitCustomer } from '../../../store/actions/daoActions';
import { setCompanyProp } from '../../../store/actions/companyActions';
import { setThankYouPage } from '../../../store/actions/corporateAccountActions';
import { setReviewEtlList, updateCustomerConsent } from '../../../store/actions/reviewActions';
import { PageTitle, Spinner } from '../../common';
import {
  conventionalThankyouSubType,
  daoSteps,
  exitScenarios,
  flowOneSteps,
  nonDaoSteps,
  OPTION_FLOW_1,
  pageMapper,
  paymentExitSteps,
  paymentExitTypes,
  requestScenarios,
  sorryScenarios,
} from '../../../utils/constants';
import ContactPanel from '../../common/Panel/ContactPanel';
import CompanyPanel from '../../common/Panel/CompanyPanel';

import './ReviewPage.css';
import { postUserTrackId, setAppProp } from '../../../store/actions/appActions';
import { useTranslationSteps } from '../../../hooks/useTranslationSteps';

function ReviewPage(props) {
  const dispatch = useDispatch();
  const { isEtl, etlList, appTimers } = useSelector((state: any) => state.app);
  const { paymentMethodType, showPaymentPage } = appTimers;
  const { optionFlow, breDetails, loadingBreDetails, submitPostData } = useSelector((state: any) => state.DAOReducer);
  const { acceptTermsAndConditions, serviceAgreementAndRateCard, receiveNewsAndUpdates } = useSelector(
    (state: any) => state.review
  );
  const { company } = useSelector((state: any) => state.company);
  const { userTrackId, dhlPassTrkId } = useSelector((state: any) => state.corporateAccount);

  const { translations } = useSelector((state: any) => state.account);
  const { review: content } = translations;
  const { accessibility } = content;
  const { heading, common } = content;
  const { billingAddressSame, vAddress, tradingCompanyNameSame, companyName } = company;
  const { contactFields } = useSelector((state: any) => state.contact);
  const { showConsent } = contactFields;

  const [signedStatus, setSignedStatus] = useState(false);
  const [translatedDaoSteps, translatedNonDaoSteps, translatedFlowOneSteps, translatedPaymentExitSteps] =
    useTranslationSteps(daoSteps, nonDaoSteps, flowOneSteps, paymentExitSteps);

  const updateFinalCustomer = (target, value) => {
    dispatch(updateCustomerConsent(target, value));
  };

  const isSubmitDisable = () => {
    if (!showConsent) {
      if (optionFlow === OPTION_FLOW_1) {
        if (acceptTermsAndConditions && serviceAgreementAndRateCard) {
          return false;
        }

        return true;
      } else {
        if (acceptTermsAndConditions) {
          return false;
        }

        return true;
      }
    }
  };

  useEffect(() => {
    dispatch(setReviewEtlList(breDetails));
  }, [breDetails]);

  useEffect(() => {
    if (isEtl) {
      dispatch(setAppProp('steps', translatedNonDaoSteps));
    } else if (optionFlow === OPTION_FLOW_1) {
      dispatch(setAppProp('steps', translatedFlowOneSteps));
    } else if (paymentExitTypes.includes(paymentMethodType) && !showPaymentPage) {
      dispatch(setAppProp('steps', translatedPaymentExitSteps));
    } else {
      dispatch(setAppProp('steps', translatedDaoSteps));
    }
  }, [isEtl]);

  const submitCustomerData = () => {
    dispatch(submitCustomer(true));

    if (isEtl) {
      if (exitScenarios.includes(etlList[0].code)) {
        props.setFlow(conventionalThankyouSubType.exist);
      }
      if (requestScenarios.includes(etlList[0].code)) {
        props.setFlow(conventionalThankyouSubType.request);
      }
      if (sorryScenarios.includes(etlList[0].code)) {
        props.setFlow(conventionalThankyouSubType.sorry);
      }
      dispatch(setThankYouPage(true));
    } else if (optionFlow === OPTION_FLOW_1) {
      props.handlePage('payment');
    } else {
      props.handlePage('shipping');
    }
  };

  const onEditClick = (editPage) => {
    props.handlePage(editPage);
  };

  const mandatoryLabel = '<span class="legal-id-mandatory">*</span>';

  useEffect(() => {
    if (billingAddressSame) {
      dispatch(setCompanyProp('bAddress', vAddress));
    }
    if (tradingCompanyNameSame) {
      dispatch(setCompanyProp('tradingName', companyName));
    }
  }, []);

  useEffect(() => {
    setSignedStatus(true);
    if (userTrackId || dhlPassTrkId) {
      dispatch(
        postUserTrackId({
          trackingId: userTrackId || dhlPassTrkId,
          activityEndPage: pageMapper.review,
          accReqId: submitPostData?.accountId || '',
        })
      );
    }
    // TODO: As discussed with team, needs a better solution for this
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  return (
    <>
      {loadingBreDetails && (
        <div className="spinner-container review-spinner my-5">
          <Spinner
            animation="border"
            variant="secondary"
          />
        </div>
      )}
      {!loadingBreDetails && (
        <Row>
          <Col
            xs={12}
            className="mx-auto my-5"
          >
            <Row>
              <Col>
                <PageTitle
                  title={heading.REVIEW_DETAIL_TITLE}
                  subtitle={heading.REVIEW_DETAIL_SUBTITLE}
                />
              </Col>
            </Row>

            <Row>
              <Col>
                <ContactPanel
                  isEditable={signedStatus}
                  onEdit={() => onEditClick('contact')}
                  content={content}
                />
              </Col>
            </Row>

            <Row style={{ marginTop: '2rem' }}>
              <Col>
                <CompanyPanel
                  isEditable={signedStatus}
                  onEdit={() => onEditClick('company')}
                  content={content}
                />
              </Col>
            </Row>

            {!showConsent && (
              <div className="row">
                <div className="col-md-12">
                  <div className="form-check mt-4 mb-0">
                    <input
                      className="form-check-input custom-checkbox"
                      type="checkbox"
                      defaultChecked={acceptTermsAndConditions}
                      onClick={(e) =>
                        updateFinalCustomer(
                          (e.target as HTMLInputElement).value,
                          (e.target as HTMLInputElement).checked
                        )
                      }
                      value="acceptTermsAndConditions"
                      id="checkboxTnc"
                      aria-label={common.CHECK_TNC_ALT}
                    />
                    <label
                      className="form-check-label consent-text ms-3"
                      htmlFor="checkboxTnc"
                      dangerouslySetInnerHTML={{
                        __html: mandatoryLabel + common.LABEL_TNC,
                      }}
                    ></label>
                  </div>
                </div>
              </div>
            )}
            {optionFlow === OPTION_FLOW_1 && (
              <div className="row">
                <div className="col-md-12">
                  <div className="form-check mt-4 mb-0">
                    <input
                      className="form-check-input custom-checkbox"
                      type="checkbox"
                      defaultChecked={serviceAgreementAndRateCard}
                      onClick={(e) =>
                        updateFinalCustomer(
                          (e.target as HTMLInputElement).value,
                          (e.target as HTMLInputElement).checked
                        )
                      }
                      value="serviceAgreementAndRateCard"
                      id="checkboxSarc"
                      aria-label={common.CHECK_SARC_ALT}
                    />
                    <label
                      className="form-check-label consent-text ms-3"
                      htmlFor="checkboxSarc"
                    >
                      <span className="legal-id-mandatory">*</span>
                      {common.LABEL_SARC}
                    </label>
                  </div>
                </div>
              </div>
            )}
            {!showConsent && (
              <div className="row">
                <div className="col-md-12">
                  <div className="form-check mt-4 mb-0">
                    <input
                      className="form-check-input custom-checkbox"
                      type="checkbox"
                      defaultChecked={receiveNewsAndUpdates}
                      onClick={(e) =>
                        updateFinalCustomer(
                          (e.target as HTMLInputElement).value,
                          (e.target as HTMLInputElement).checked
                        )
                      }
                      value="receiveNewsAndUpdates"
                      id="checkboxNnd"
                      aria-label={common.CHECK_NND_ALT}
                    />
                    <label
                      className="form-check-label consent-text ms-3"
                      htmlFor="checkboxNnd"
                    >
                      {common.LABEL_NND}
                    </label>
                  </div>
                </div>
              </div>
            )}

            <div className="row">
              <div className="mt-4 col-md-6 prevBtn order-sm-1 order-md-0">
                <span
                  onClick={props.prevPage}
                  className="btn-back-review"
                  role="button"
                  tabIndex={0}
                  title={accessibility?.label?.BACK_TO_PREVIOUS_STEP}
                >
                  <img
                    src={chevronBackImg}
                    className="icon-back"
                    alt={common.ICON_BACK_ALT}
                  />
                  {common.LABEL_BTN_BACK}
                </span>
              </div>
              <div className="col-md-6 nextBtn mt-4">
                <button
                  aria-label={
                    isSubmitDisable()
                      ? accessibility?.label?.NEXT_BUTTON_DISABLED
                      : accessibility?.label?.NEXT_BUTTON_ENABLED
                  }
                  className="btn btn-primary px-4 py-2"
                  disabled={isSubmitDisable()}
                  onClick={submitCustomerData}
                  title={common.TOOLTIP_BTN_SUBMIT}
                >
                  {common.LABEL_BTN_SUBMIT}
                </button>
              </div>
            </div>
          </Col>
        </Row>
      )}
    </>
  );
}
export default ReviewPage;
