import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { replace } from 'lodash';

import './ThankYouPage.css';
import { postUserTrackId } from '../../../store/actions/appActions';
import { pageMapper } from '../../../utils/constants';

type TThankYou = {
  title?: string;
  subTitle?: string;
};

const ConventionalThankYouPage = ({ title, subTitle }: TThankYou) => {
  const { translations } = useSelector((state: any) => state.account);
  const { thankyou: thankyouContent } = translations;

  const { userTrackId, ixopayDetails, dhlPassTrkId } = useSelector((state: any) => state.corporateAccount);
  const dispatch = useDispatch();
  const { submitPostData } = useSelector((state: any) => state.DAOReducer);

  useEffect(() => {
    // TODO: As discussed with team, needs a better solution for this
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  const contactUsLink = thankyouContent.CONTACT_US_INFORMATION_LINK;

  const setContactUsText = (text) => {
    return replace(text, '{{contactLink}}', contactUsLink);
  };

  useEffect(() => {
    if (
      (userTrackId && submitPostData?.accountId) ||
      (ixopayDetails?.dhltrackingid && ixopayDetails?.accountRequestId) ||
      (dhlPassTrkId && submitPostData?.accountId)
    ) {
      dispatch(
        postUserTrackId({
          trackingId: userTrackId || ixopayDetails.dhltrackingid || dhlPassTrkId,
          activityEndPage: pageMapper.thankyou,
          accReqId: submitPostData.accountId || ixopayDetails.accountRequestId,
        })
      );
    }
  }, [submitPostData?.accountId, ixopayDetails]);

  return (
    <div className="row thankyou-card-wrap">
      <div className="col-md-12">
        <div className="text-center thankyou-page-section1">
          <div className="thankyou-conventional-title">
            <span
              dangerouslySetInnerHTML={{
                __html: title as string,
              }}
            ></span>
          </div>
          <div className="thankyou-conventional-subtitle">
            <span
              dangerouslySetInnerHTML={{
                __html: setContactUsText(subTitle),
              }}
            ></span>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ConventionalThankYouPage;
