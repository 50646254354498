import {
  SET_CONTACT,
  SET_CONTACT_KEY,
  SET_CONTACT_PROP,
  SET_VALIDATE_MY_BILL_ERROR,
  SET_VALIDATE_PHONE,
  VALIDATE_MY_BILL,
  VALIDATE_MY_BILL_SUCCESS,
} from '../actions/actionTypes';

const initialState: any = {
  loadingValidateMyBill: false,
  validateMyBillData: {},
  validatePhone: {},
  contact: {
    country: [],
    title: '',
    firstName: '',
    lastName: '',
    email: '',
    phoneType: 'Mobile',
    countryCode: [],
    number: '',
    e164NoFmt: '',
    ext: '',
    job: '',
    department: '',
    preferredLanguage: '',
    preferredCommunicationMethod: '',
    acceptTermsAndConditions: false,
    receiveNewsAndUpdates: false,
  },
  contactMandatoryFields: [],
  preferredLanguages: [],
  preferredCommunicationMethods: [],
  validateMyBillHttpStatus: -1,
  consentContactValues: false,
};

const contactReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CONTACT: {
      return { ...state, contact: action.payload };
    }

    case SET_CONTACT_PROP:
      return { ...state, contact: { ...state.contact, [action.payload.key]: action.payload.value } };

    case SET_CONTACT_KEY:
      return { ...state, [action.payload.key]: action.payload.value };

    case SET_VALIDATE_PHONE:
      return { ...state, validatePhone: { ...state.validatePhone, [action.payload.key]: action.payload.value } };

    case VALIDATE_MY_BILL:
      return { ...state, loadingValidateMyBill: true };

    case VALIDATE_MY_BILL_SUCCESS:
      return {
        ...state,
        validateMyBillData: action.payload,
        loadingValidateMyBill: false,
        validateMyBillHttpStatus: -1,
      };

    case SET_VALIDATE_MY_BILL_ERROR: {
      return {
        ...state,
        validateMyBillData: {},
        validateMyBillHttpStatus: action.payload,
      };
    }

    default:
      return state;
  }
};

export default contactReducer;
