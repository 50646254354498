import { DEFAULT_ADDRESS, DEFAULT_COMPANY_FORM } from '../../utils/constants';
import {
  SET_REGISTRATION_DETAILS,
  SET_COMPANY_RELATED_DETAILS,
  SET_REGISTERED_COUNTRY_CONFIG,
  SET_REGISTRATION_RELATED_DETAILS,
  SET_COMPANY_AND_ADDRESSES,
  SET_COMPANY_PROP,
  SET_COMPANY,
  RESET_COMPANY_INFO,
  GET_COUNTRY_REG_TYPE_DATA,
  GET_VISITING_ADDRESS,
  SET_VISITING_ADDRESS,
  SET_COMPANY_KEY,
  GET_BILLING_ADDRESS,
  SET_BILLING_ADDRESS,
  GET_DOC_UPLOAD,
  GET_DOC_UPLOAD_SUCCESS,
  GET_DOC_UPLOAD_STATUS_CODE,
  GET_DELETED_FILES,
  SET_COMPANY_COUNTRY_MANDATORY_DISABLED_FIELDS,
} from '../actions/actionTypes';

const initialState: any = {
  loadingRegistrationDetails: false,
  registrationDetails: {},
  countryConfigData: {
    ctryCfgs: [],
  },
  mergeAddr1Addr2: false,
  optionalLegalIds: [],
  mandatoryLegalIds: [],
  conditionalLegalIds: [],
  filteredLegalIds: [],
  mandatoryFields: [],
  vAddressDisabledFields: [],
  bAddressDisabledFields: [],
  mandatoryVisitingAddress: [],
  mandatoryBillingAddress: [],
  company: DEFAULT_COMPANY_FORM,
  mandatoryVisitingAddressFields: [],
  mandatoryBillingAddressFields: [],
  mandatoryVisitAddressFields: [],
  mandatoryBillAddressFields: [],
  visitingAddressDisabledFields: [],
  billingAddressDisabledFields: [],
  loadingFile: false,
  fileUploadStatusCode: -1,
  fileUpload: {},
  selectedFiles: [],
  fileUploadConfig: {},
  companyConfig: {},
  companyMandatoryFields: [],
  brvRegistrationLst: [],
};

const companyReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_COMPANY: {
      return { ...state, company: action.payload };
    }

    case RESET_COMPANY_INFO:
      return {
        ...state,
        company: {
          ...state.company,
          companyName: '',
          tradingName: '',
          validated: false,
          vAddress: DEFAULT_ADDRESS,
          bAddress: DEFAULT_ADDRESS,
          billingAddressSame: true,
          tradingCompanyNameSame: true,
          companyWebsite: '',
          companyIndustry: [],
        },
      };

    case GET_COUNTRY_REG_TYPE_DATA:
      return { ...state, loadingRegistrationDetails: true };

    case SET_REGISTRATION_DETAILS:
      return { ...state, registrationDetails: action.payload, loadingRegistrationDetails: false };

    case SET_REGISTERED_COUNTRY_CONFIG:
      return { ...state, countryConfigData: action.payload };

    case SET_COMPANY_RELATED_DETAILS:
      const { regnoCheckBox, mergeAddr1Addr2, optionalLegalIds } = action.payload;

      return { ...state, company: { ...state.company, regnoCheckBox }, mergeAddr1Addr2, optionalLegalIds };

    case SET_REGISTRATION_RELATED_DETAILS:
      const { mandatoryLegalIds, conditionalLegalIds, filteredLegalIds } = action.payload;

      return {
        ...state,
        mandatoryLegalIds,
        conditionalLegalIds,
        filteredLegalIds,
      };

    case SET_COMPANY_COUNTRY_MANDATORY_DISABLED_FIELDS:
      const {
        mandatoryVisitingAddress,
        mandatoryBillingAddress,
        mandatoryFields,
        vAddressDisabledFields,
        bAddressDisabledFields,
      } = action.payload;

      return {
        ...state,
        mandatoryFields,
        vAddressDisabledFields,
        bAddressDisabledFields,
        mandatoryVisitingAddress,
        mandatoryBillingAddress,
        mandatoryVisitingAddressFields: [],
        mandatoryBillingAddressFields: [],
        visitingAddressDisabledFields: [],
        billingAddressDisabledFields: [],
      };

    case SET_COMPANY_AND_ADDRESSES:
      const { companyName, tradingName = '', vAddress = DEFAULT_ADDRESS, bAddress = DEFAULT_ADDRESS } = action.payload;

      return { ...state, company: { ...state.company, companyName, tradingName, vAddress, bAddress } };

    case SET_COMPANY_PROP:
      return { ...state, company: { ...state.company, [action.payload.key]: action.payload.value } };

    case GET_VISITING_ADDRESS:
      return { ...state };

    case SET_VISITING_ADDRESS:
      const { mandatoryVisitingAddressFields, mandatoryVisitAddressFields, visitingAddressDisabledFields } =
        action.payload;

      return {
        ...state,
        mandatoryVisitingAddressFields,
        mandatoryVisitAddressFields,
        visitingAddressDisabledFields,
        mandatoryVisitingAddress: [],
        vAddressDisabledFields: [],
      };

    case GET_BILLING_ADDRESS:
      return { ...state };

    case SET_BILLING_ADDRESS:
      const { mandatoryBillingAddressFields, mandatoryBillAddressFields, billingAddressDisabledFields } =
        action.payload;

      return {
        ...state,
        mandatoryBillingAddressFields,
        mandatoryBillAddressFields,
        billingAddressDisabledFields,
        bAddressDisabledFields: [],
        mandatoryBillingAddress: [],
      };

    case SET_COMPANY_KEY:
      return { ...state, [action.payload.key]: action.payload.value };

    case GET_DOC_UPLOAD:
      return { ...state, loadingFile: true };

    case GET_DOC_UPLOAD_SUCCESS:
      const { docId, fileName, message } = action.payload;

      return {
        ...state,
        selectedFiles: state.selectedFiles.map((file) => (file.name === fileName ? { ...file, docId, message } : file)),
        fileUpload: action.payload,
        loadingFile: false,
      };

    case GET_DOC_UPLOAD_STATUS_CODE: {
      return { ...state, fileUploadStatusCode: action.payload, loadingFile: false };
    }

    case GET_DELETED_FILES: {
      return { ...state, selectedFiles: state.selectedFiles.filter((i) => i.name !== action.payload.name) };
    }

    default:
      return state;
  }
};

export default companyReducer;
