import _ from 'lodash';
import {
  bAddressMapper,
  companyLegalMapper,
  companyNameMapper,
  contactMapper,
  missingFlags,
  vAddressMapper,
} from '../../utils/constants';
import {
  GET_COUNTRY_REF_DATA,
  GET_COUNTRY_REF_DATA_SUCCESS,
  GET_COUNTRY_CONFIG,
  GET_COUNTRY_CONFIG_SUCCESS,
  SET_THANK_YOU_PAGE,
  GENERATE_TRACK_ID,
  GENERATE_TRACK_ID_SUCCESS,
  SET_COUNTRY_REF_DATA_ERROR,
  GET_USER_TRACK_ID,
  SET_USER_TRACK_ID,
  SET_CORP_ACC_PROP,
  SET_LANDING_PAGE,
  GET_LANDING_PAGE,
  GET_IXOPAY_DETAILS,
  SET_IXOPAY_DETAILS,
  SEND_COOKIE_CONSENT,
} from './actionTypes';
import { storage } from '../../utils/helper';
import { Country } from '../../utils/types';

export const getCountryRefData = (request) => {
  return {
    type: GET_COUNTRY_REF_DATA,
    payload: request,
  };
};

export const getCountryRefDataSuccess = (response) => {
  return {
    type: GET_COUNTRY_REF_DATA_SUCCESS,
    payload: response,
  };
};

export const setIsoCountryList = (ctryList) => {
  const countryDataRefResponse = ctryList.refDatLst;
  const countryDataPhoneList: any = [];
  countryDataRefResponse.forEach((ctr) =>
    countryDataPhoneList.push({
      value: ctr.refDatCtry,
      label: ctr.extPrm.isoCtryName,
      code: ctr.extPrm.intlDlInTelNo,
    })
  );
  const sortedCountryData = _.orderBy(countryDataPhoneList, 'label', 'asc');
  const isoCountryLists: Country[] = sortedCountryData
    .filter((country) => {
      // filteing countries with missing label and flags.
      return !!country.label && !missingFlags.includes(country.value);
    })
    .map((filteredCountry) => {
      return {
        code: filteredCountry.value,
        name: filteredCountry.label,
        callCode: '+' + filteredCountry.code,
      };
    });

  return {
    type: SET_CORP_ACC_PROP,
    payload: {
      key: 'isoCountryLists',
      value: isoCountryLists,
    },
  };
};

export const setCountryRefDataError = () => {
  return {
    type: SET_COUNTRY_REF_DATA_ERROR,
  };
};

export const getCountryConfig = (request) => {
  return {
    type: GET_COUNTRY_CONFIG,
    payload: request,
  };
};

export const getCountryConfigSuccess = (response) => {
  const isDaoCountry = response?.ctryCfgs?.find((ctryCfg) => ctryCfg.typeCd === 'DAO_COUNTRY')?.value === 'Y';
  storage.remove('isDaoCountry');
  storage.set('isDaoCountry', JSON.stringify(isDaoCountry));

  return {
    type: GET_COUNTRY_CONFIG_SUCCESS,
    payload: response,
  };
};

export const generateTrackId = () => {
  return {
    type: GENERATE_TRACK_ID,
    payload: {},
  };
};

export const setTrackingId = (response) => {
  return {
    type: GENERATE_TRACK_ID_SUCCESS,
    payload: response,
  };
};

export const setThankYouPage = (thankYouPage) => {
  return {
    type: SET_THANK_YOU_PAGE,
    payload: thankYouPage,
  };
};

export const getUserTrackId = (request) => {
  return {
    type: GET_USER_TRACK_ID,
    payload: request,
  };
};

export const setUserTrackId = (response) => {
  return {
    type: SET_USER_TRACK_ID,
    payload: response,
  };
};

export const setCorporateAccountProp = (key, value) => {
  return {
    type: SET_CORP_ACC_PROP,
    payload: { key, value },
  };
};

export const setContactBreRegex = (configurations) => {
  const firstNameBreRegexValue = configurations?.ctryRules?.rules?.find(
    (rule) => rule.attributeName === contactMapper.firstName
  )?.regexVal;

  const lastNameBreRegexValue = configurations?.ctryRules?.rules?.find(
    (rule) => rule.attributeName === contactMapper.lastName
  )?.regexVal;

  const emailBreRegexValue = configurations?.ctryRules?.rules?.find(
    (rule) => rule.attributeName === contactMapper.email
  )?.regexVal;

  const telExtBreRegexValue = configurations?.ctryRules?.rules?.find(
    (rule) => rule.attributeName === contactMapper.ext
  )?.regexVal;

  const jobBreRegexValue = configurations?.ctryRules?.rules?.find(
    (rule) => rule.attributeName === contactMapper.job
  )?.regexVal;

  return {
    type: SET_CORP_ACC_PROP,
    payload: {
      key: 'contactBreRegex',
      value: {
        firstNameBreRegexValue,
        lastNameBreRegexValue,
        emailBreRegexValue,
        telExtBreRegexValue,
        jobBreRegexValue,
      },
    },
  };
};

export const setContactDaoRegex = (configurations) => {
  const firstNameDaoAttrValue = configurations.ctryRules.rules.find(
    (rule) => rule.attributeName === contactMapper.firstName
  );
  const lastNameDaoAttrValue = configurations.ctryRules.rules.find(
    (rule) => rule.attributeName === contactMapper.lastName
  );
  const jobDaoAttrValue = configurations.ctryRules.rules.find((rule) => rule.attributeName === contactMapper.job);
  const emailDaoAttrValue = configurations.ctryRules.rules.find((rule) => rule.attributeName === contactMapper.email);

  const firstNameDaoRegexValue = firstNameDaoAttrValue.regexValDao ? firstNameDaoAttrValue.regexValDao[0] : null;
  const lastNameDaoRegexValue = lastNameDaoAttrValue.regexValDao ? lastNameDaoAttrValue.regexValDao[0] : null;
  const emailDaoRegexValue = emailDaoAttrValue.regexValDao ? emailDaoAttrValue.regexValDao[0] : null;
  const jobDaoRegexValue = jobDaoAttrValue.regexValDao ? jobDaoAttrValue.regexValDao[0] : null;

  return {
    type: SET_CORP_ACC_PROP,
    payload: {
      key: 'contactDaoRegex',
      value: {
        firstNameDaoRegexValue,
        lastNameDaoRegexValue,
        emailDaoRegexValue,
        jobDaoRegexValue,
      },
    },
  };
};

export const setCompanyLegalBreRegex = (configurations) => {
  const companyLegalBreRegexValue = configurations?.ctryRules?.rules?.filter((rule) =>
    rule.attributeName.startsWith(companyLegalMapper.companyLegal)
  );

  return {
    type: SET_CORP_ACC_PROP,
    payload: {
      key: 'companyLegalBreRegex',
      value: {
        companyLegalBreRegexValue,
      },
    },
  };
};

export const setCompanyNameBreRegex = (configurations) => {
  const companyNameBreRegexValue = configurations?.ctryRules?.rules?.find(
    (rule) => rule.attributeName === companyNameMapper.companyName
  )?.regexVal;

  return {
    type: SET_CORP_ACC_PROP,
    payload: {
      key: 'companyNameBreRegex',
      value: {
        companyNameBreRegexValue,
      },
    },
  };
};

export const setCompanyNameDaoRegex = (configurations) => {
  const companyNameDaoAttrValue1 = configurations?.ctryRules?.rules?.find(
    (rule) => rule.attributeName === companyNameMapper.companyName
  );
  const companyNameDaoAttrValue2 = configurations?.ctryRules?.rules?.find(
    (rule) => rule.attributeName === companyNameMapper.companyName
  );

  const companyNameDaoRegexValue1 = companyNameDaoAttrValue1.regexValDao
    ? companyNameDaoAttrValue1.regexValDao[0]
    : null;
  const companyNameDaoRegexValue2 = companyNameDaoAttrValue2.regexValDao
    ? companyNameDaoAttrValue2.regexValDao[1]
    : null;

  return {
    type: SET_CORP_ACC_PROP,
    payload: {
      key: 'companyNameDaoRegex',
      value: {
        companyNameDaoRegexValue1,
        companyNameDaoRegexValue2,
      },
    },
  };
};

export const setVisitingBreRegex = (configurations) => {
  const vAddressLine1BreRegexValue = configurations?.ctryRules?.rules?.find(
    (rule) => rule.attributeName === vAddressMapper.addressLine1
  )?.regexVal;

  const vAddressLine2BreRegexValue = configurations?.ctryRules?.rules?.find(
    (rule) => rule.attributeName === vAddressMapper.addressLine2
  )?.regexVal;

  const vAddressCityBreRegexValue = configurations?.ctryRules?.rules?.find(
    (rule) => rule.attributeName === vAddressMapper.city
  )?.regexVal;

  const vAddressStateBreRegexValue = configurations?.ctryRules?.rules?.find(
    (rule) => rule.attributeName === vAddressMapper.state
  )?.regexVal;

  const vAddressZipcodeBreRegexValue = configurations?.ctryRules?.rules?.find(
    (rule) => rule.attributeName === vAddressMapper.zipCode
  )?.regexVal;

  const vAddressProvinceBreRegexValue = configurations?.ctryRules?.rules?.find(
    (rule) => rule.attributeName === vAddressMapper.province
  )?.regexVal;

  return {
    type: SET_CORP_ACC_PROP,
    payload: {
      key: 'visitingBreRegex',
      value: {
        vAddressLine1BreRegexValue,
        vAddressLine2BreRegexValue,
        vAddressCityBreRegexValue,
        vAddressStateBreRegexValue,
        vAddressZipcodeBreRegexValue,
        vAddressProvinceBreRegexValue,
      },
    },
  };
};

export const setBillingBreRegex = (configurations) => {
  const bAddressLine1BreRegexValue = configurations?.ctryRules?.rules?.find(
    (rule) => rule.attributeName === bAddressMapper.addressLine1
  )?.regexVal;

  const bAddressLine2BreRegexValue = configurations?.ctryRules?.rules?.find(
    (rule) => rule.attributeName === bAddressMapper.addressLine2
  )?.regexVal;

  const bAddressCityBreRegexValue = configurations?.ctryRules?.rules?.find(
    (rule) => rule.attributeName === bAddressMapper.city
  )?.regexVal;

  const bAddressStateBreRegexValue = configurations?.ctryRules?.rules?.find(
    (rule) => rule.attributeName === bAddressMapper.state
  )?.regexVal;

  const bAddressZipcodeBreRegexValue = configurations?.ctryRules?.rules?.find(
    (rule) => rule.attributeName === bAddressMapper.zipCode
  )?.regexVal;

  const bAddressProvinceBreRegexValue = configurations?.ctryRules?.rules?.find(
    (rule) => rule.attributeName === bAddressMapper.province
  )?.regexVal;

  return {
    type: SET_CORP_ACC_PROP,
    payload: {
      key: 'billingBreRegex',
      value: {
        bAddressLine1BreRegexValue,
        bAddressLine2BreRegexValue,
        bAddressCityBreRegexValue,
        bAddressStateBreRegexValue,
        bAddressZipcodeBreRegexValue,
        bAddressProvinceBreRegexValue,
      },
    },
  };
};

export const setVisitingDaoRegex = (configurations) => {
  const vAddressLine1DaoAttrValue1 = configurations?.ctryRules?.rules?.find(
    (rule) => rule.attributeName === vAddressMapper.addressLine1
  );
  const vAddressLine1DaoAttrValue2 = configurations?.ctryRules?.rules?.find(
    (rule) => rule.attributeName === vAddressMapper.addressLine1
  );
  const vAddressLine2DaoAttrValue1 = configurations?.ctryRules?.rules?.find(
    (rule) => rule.attributeName === vAddressMapper.addressLine2
  );
  const vAddressLine2DaoAttrValue2 = configurations?.ctryRules?.rules?.find(
    (rule) => rule.attributeName === vAddressMapper.addressLine2
  );
  const vAddressCityDaoAttrValue = configurations?.ctryRules?.rules?.find(
    (rule) => rule.attributeName === vAddressMapper.city
  );
  const vAddressStateDaoAttrValue = configurations?.ctryRules?.rules?.find(
    (rule) => rule.attributeName === vAddressMapper.state
  );
  const vAddressZipCodeDaoAttrValue = configurations?.ctryRules?.rules?.find(
    (rule) => rule.attributeName === vAddressMapper.zipCode
  );
  const vAddressProvinceDaoAttrValue = configurations?.ctryRules?.rules?.find(
    (rule) => rule.attributeName === vAddressMapper.province
  );
  const vAddressLine1DaoRegexValue1 = vAddressLine1DaoAttrValue1.regexValDao
    ? vAddressLine1DaoAttrValue1.regexValDao[0]
    : null;
  const vAddressLine1DaoRegexValue2 = vAddressLine1DaoAttrValue2.regexValDao
    ? vAddressLine1DaoAttrValue2.regexValDao[1]
    : null;
  const vAddressLine2DaoRegexValue1 = vAddressLine2DaoAttrValue1.regexValDao
    ? vAddressLine2DaoAttrValue1.regexValDao[0]
    : null;
  const vAddressLine2DaoRegexValue2 = vAddressLine2DaoAttrValue2.regexValDao
    ? vAddressLine2DaoAttrValue2.regexValDao[1]
    : null;
  const vAddressCityDaoRegexValue = vAddressCityDaoAttrValue.regexValDao
    ? vAddressCityDaoAttrValue.regexValDao[0]
    : null;
  const vAddressStateDaoRegexValue = vAddressStateDaoAttrValue.regexValDao
    ? vAddressStateDaoAttrValue.regexValDao[0]
    : null;
  const vAddressZipCodeDaoRegexValue = vAddressZipCodeDaoAttrValue.regexValDao
    ? vAddressZipCodeDaoAttrValue.regexValDao[0]
    : null;
  const vAddressProvinceDaoRegexValue = vAddressProvinceDaoAttrValue.regexValDao
    ? vAddressProvinceDaoAttrValue.regexValDao[0]
    : null;

  return {
    type: SET_CORP_ACC_PROP,
    payload: {
      key: 'visitingDaoRegex',
      value: {
        vAddressLine1DaoRegexValue1,
        vAddressLine1DaoRegexValue2,
        vAddressLine2DaoRegexValue1,
        vAddressLine2DaoRegexValue2,
        vAddressCityDaoRegexValue,
        vAddressStateDaoRegexValue,
        vAddressZipCodeDaoRegexValue,
        vAddressProvinceDaoRegexValue,
      },
    },
  };
};

export const setBillingDaoRegex = (configurations) => {
  const bAddressLine1DaoAttrValue1 = configurations?.ctryRules?.rules?.find(
    (rule) => rule.attributeName === bAddressMapper.addressLine1
  );
  const bAddressLine1DaoAttrValue2 = configurations?.ctryRules?.rules?.find(
    (rule) => rule.attributeName === bAddressMapper.addressLine1
  );
  const bAddressLine2DaoAttrValue1 = configurations?.ctryRules?.rules?.find(
    (rule) => rule.attributeName === bAddressMapper.addressLine2
  );
  const bAddressLine2DaoAttrValue2 = configurations?.ctryRules?.rules?.find(
    (rule) => rule.attributeName === bAddressMapper.addressLine2
  );
  const bAddressCityDaoAttrValue = configurations?.ctryRules?.rules?.find(
    (rule) => rule.attributeName === bAddressMapper.city
  );
  const bAddressStateDaoAttrValue = configurations?.ctryRules?.rules?.find(
    (rule) => rule.attributeName === bAddressMapper.state
  );
  const bAddressZipCodeDaoAttrValue = configurations?.ctryRules?.rules?.find(
    (rule) => rule.attributeName === bAddressMapper.zipCode
  );
  const bAddressProvinceDaoAttrValue = configurations?.ctryRules?.rules?.find(
    (rule) => rule.attributeName === bAddressMapper.province
  );

  const bAddressLine1DaoRegexValue1 = bAddressLine1DaoAttrValue1.regexValDao
    ? bAddressLine1DaoAttrValue1.regexValDao[0]
    : null;
  const bAddressLine1DaoRegexValue2 = bAddressLine1DaoAttrValue2.regexValDao
    ? bAddressLine1DaoAttrValue2.regexValDao[1]
    : null;
  const bAddressLine2DaoRegexValue1 = bAddressLine2DaoAttrValue1.regexValDao
    ? bAddressLine2DaoAttrValue1.regexValDao[0]
    : null;
  const bAddressLine2DaoRegexValue2 = bAddressLine2DaoAttrValue2.regexValDao
    ? bAddressLine2DaoAttrValue2.regexValDao[1]
    : null;
  const bAddressCityDaoRegexValue = bAddressCityDaoAttrValue.regexValDao
    ? bAddressCityDaoAttrValue.regexValDao[0]
    : null;
  const bAddressStateDaoRegexValue = bAddressStateDaoAttrValue.regexValDao
    ? bAddressStateDaoAttrValue.regexValDao[0]
    : null;
  const bAddressZipCodeDaoRegexValue = bAddressZipCodeDaoAttrValue.regexValDao
    ? bAddressZipCodeDaoAttrValue.regexValDao[0]
    : null;
  const bAddressProvinceDaoRegexValue = bAddressProvinceDaoAttrValue.regexValDao
    ? bAddressProvinceDaoAttrValue.regexValDao[0]
    : null;

  return {
    type: SET_CORP_ACC_PROP,
    payload: {
      key: 'billingDaoRegex',
      value: {
        bAddressLine1DaoRegexValue1,
        bAddressLine1DaoRegexValue2,
        bAddressLine2DaoRegexValue1,
        bAddressLine2DaoRegexValue2,
        bAddressCityDaoRegexValue,
        bAddressStateDaoRegexValue,
        bAddressZipCodeDaoRegexValue,
        bAddressProvinceDaoRegexValue,
      },
    },
  };
};

export const getLandingPage = (request) => {
  return {
    type: GET_LANDING_PAGE,
    payload: request,
  };
};

export const setLandingPage = (response) => {
  return {
    type: SET_LANDING_PAGE,
    payload: response,
  };
};

export const getIxopayDetails = (request) => {
  return {
    type: GET_IXOPAY_DETAILS,
    payload: request,
  };
};

export const setIxopayDetails = (response) => {
  return {
    type: SET_IXOPAY_DETAILS,
    payload: response,
  };
};

export const sendCookieConsent = (content) => {
  return {
    type: SEND_COOKIE_CONSENT,
    payload: content,
  };
};
