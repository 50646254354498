import {
  GET_ADDRESS_SUGGESTIONS_SUCCESS,
  GET_ADDRESS_SUGGESTIONS,
  GET_ADDRESS_DETAILS_SUCCESS,
  SET_ADDRESS_DETAILS,
  GET_ADDRESS_SUGGESTIONS_ERROR,
  GET_VALIDATE_ADDRESS_DETAILS,
  GET_VALIDATE_ADDRESS_DETAILS_SUCCESS,
  SUBMIT_CUSTOMER,
  SUBMIT_CUSTOMER_SUCCESS,
  GET_SIGNED_DOC,
  GET_SIGNED_DOC_SUCCESS,
  CREATE_ACCOUNT_SUCCESS,
  CREATE_ACCOUNT,
  SET_OPTION_FLOW,
  SET_DAO_PROP,
  GET_BRE_DETAILS,
  SET_BRE_DETAILS,
  SET_BRE_ERROR_CODE,
  SET_BRV_ERROR,
  SET_BILLING_ADDRESS_DETAILS,
} from '../actions/actionTypes';

const initialState = {
  loading: false,
  loadingAddressDetails: false,
  loadingValidateAddress: false,
  loadingSubmitData: false,
  loadingSignedDocData: false,
  loadingPaymentStatus: false,
  loadingAccountCreation: false,
  loadingBreDetails: false,
  addressSuggestion: {
    vAddress: {
      addrProviderLst: [],
    },
    bAddress: {
      addrProviderLst: [],
    },
  },
  loadingAddress: {
    vAddress: false,
    bAddress: false,
  },
  addressSuggestionError: {
    vAddress: '',
    bAddress: '',
  },
  addressDetail: {},
  companyInformation: {},
  submitPostData: {
    name: '',
    code: '',
  },
  signedDocData: {},
  paymentData: {},
  createAccountData: {},
  optionFlow: '',
  breDetails: {},
  breErrorCode: -1,
  brvHttpStatus: -1,
  brvError: {},
  billingAddressInfo: {},
  genShipDoc: true,
};

const DAOReducer = (state = initialState, action) => {
  const { addressType } = action?.payload || {};

  switch (action.type) {
    case GET_ADDRESS_SUGGESTIONS:
      state = { ...state, loadingAddress: { ...state.loadingAddress, [addressType]: true } };
      break;

    case GET_ADDRESS_SUGGESTIONS_SUCCESS:
      const { addressSuggestion } = action.payload;
      state = {
        ...state,
        addressSuggestion: { ...state.addressSuggestion, [addressType]: addressSuggestion },
        loadingAddress: { ...state.loadingAddress, [addressType]: false },
      };
      break;

    case GET_ADDRESS_SUGGESTIONS_ERROR:
      const { error } = action.payload;
      state = {
        ...state,
        addressSuggestionError: { ...state.addressSuggestionError, [addressType]: error },
        loadingAddress: { ...state.loadingAddress, [addressType]: false },
      };
      break;

    case GET_ADDRESS_DETAILS_SUCCESS:
      const { addressDetail } = action.payload;
      state = { ...state, addressDetail: { ...state.addressDetail, [addressType]: addressDetail } };
      break;

    case SET_ADDRESS_DETAILS:
      const { addressDetail: detail } = action.payload;
      state = { ...state, addressDetail: { ...state.addressDetail, [addressType]: detail } };
      break;

    case GET_VALIDATE_ADDRESS_DETAILS:
      state = { ...state, loadingValidateAddress: true };
      break;

    case GET_VALIDATE_ADDRESS_DETAILS_SUCCESS:
      state = {
        ...state,
        companyInformation: action.payload,
        loadingValidateAddress: false,
        brvError: {},
      };
      break;

    case SET_BILLING_ADDRESS_DETAILS:
      state = {
        ...state,
        billingAddressInfo: action.payload,
        brvError: {},
      };
      break;

    case GET_BRE_DETAILS:
      state = { ...state, loadingBreDetails: true };
      break;

    case SET_BRE_DETAILS:
      state = { ...state, breDetails: action.payload, loadingBreDetails: false };
      break;

    case SET_BRE_ERROR_CODE:
      state = { ...state, breErrorCode: action.payload };
      break;

    case SET_BRV_ERROR:
      const { brvError = {}, brvHttpStatus = -1 } = action.payload;
      state = { ...state, brvError, brvHttpStatus, loadingValidateAddress: false };
      break;

    case SUBMIT_CUSTOMER:
      state = { ...state, loadingSubmitData: true, genShipDoc: action.payload.genShipDoc };
      break;

    case SUBMIT_CUSTOMER_SUCCESS:
      state = { ...state, submitPostData: action.payload, loadingSubmitData: false };
      break;

    case GET_SIGNED_DOC:
      state = { ...state, loadingSignedDocData: true };
      break;
    case GET_SIGNED_DOC_SUCCESS:
      state = { ...state, signedDocData: action.payload, loadingSignedDocData: false };
      break;
    case CREATE_ACCOUNT:
      state = { ...state, loadingAccountCreation: true };
      break;
    case CREATE_ACCOUNT_SUCCESS:
      state = { ...state, createAccountData: action.payload, loadingAccountCreation: false };
      break;
    case SET_OPTION_FLOW:
      state = { ...state, optionFlow: action.payload };
      break;
    case SET_DAO_PROP:
      state = { ...state, [action.payload.key]: action.payload.value };
      break;
    default:
      state = { ...state };
      break;
  }

  return state;
};

export default DAOReducer;
