import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { replace } from 'lodash';

import './FileUploader.css';
import FileInput from './FileInput';
import FileTypeImg from './FileTypeImg';

import Button from '../Button/Button';
import BasicModal2 from '../Modal/BasicModal2';

import FileRemoveIcon from '../../../assets/icons/cancel_black.svg';
import { getDeletedFiles } from '../../../store/actions/companyActions';
import { checkType, formatBytes, getFileSizeMB, trimFileName } from '../../../utils/helper';
import { fileNamePattern } from '../../../utils/regEx';

type TFileUploader = {
  onUpload: (files: File[]) => void;
  onDelete: (files: File[]) => void;
  children?: any;
  types?: string[];
  maxSize?: number;
  openDialogOnClick?: boolean;
  loading: boolean;
  uploadError?: any;
  percentage?: number;
  document: any;
  uploadingFileNameList: string[];
  setUploadingFileNameList: any;
};

const FileUploader = ({
  onUpload,
  onDelete,
  types,
  maxSize,
  openDialogOnClick = false,
  loading,
  uploadError,
  percentage,
  document,
  uploadingFileNameList,
  setUploadingFileNameList,
}: TFileUploader) => {
  const { translations } = useSelector((state: any) => state.account);
  const { selectedFiles, fileUploadConfig, fileUpload } = useSelector((state: any) => state.company);
  const { fileUploadSize, fileUploadTypes } = fileUploadConfig;
  const {
    company: { heading, modal },
  } = translations;
  const fileSizeTranslation = replace(modal.BODY_FILE_SIZE, '{{FILE_SIZE}}', fileUploadSize);
  const fileTypesTranslation = replace(modal.BODY_FILE_FORMAT, '{{FILE_TYPES}}', fileUploadTypes);
  const dispatch = useDispatch();
  const [currentFile, setCurrentFile] = useState<any[]>([]);
  const [uploadedFile, setUploadedFile] = useState<any>(null);
  const [tempFile, setTempFile] = useState<any>(null);
  const [modalState, setModalState] = useState({
    show: false,
    title: '',
    content: '',
  });

  const inputRef: any = useRef(null);

  const openFileDialog = () => {
    inputRef && inputRef.current.click();
  };

  const removeUploadingFileNameList = (fileName) => {
    const indexToRemove = uploadingFileNameList.indexOf(fileName);
    const fileNameListCopy = uploadingFileNameList;
    fileNameListCopy.splice(indexToRemove, 1);
    setUploadingFileNameList(fileNameListCopy);
  };

  const handleRemoveFile = (file) => {
    removeUploadingFileNameList(file.name);
    setUploadedFile(null);
    dispatch(getDeletedFiles(file));
    if (onDelete) {
      onDelete(file);
    }
  };

  const validateFile = (file: File) => {
    const invalidFileType = types && !checkType(file, types);
    const exceedFileSize = types && checkType(file, types) && maxSize && getFileSizeMB(file.size) > maxSize;
    const duplicateFile =
      selectedFiles.find((fileName) => fileName.name === file.name) || uploadingFileNameList.includes(file.name);
    const emptyFileSize = file.size === 0;
    const invalidFileName = file.type === '' || !fileNamePattern.test(file.name);
    const isValid = !invalidFileType && !exceedFileSize && !duplicateFile && !emptyFileSize && !invalidFileName;

    if (invalidFileType) {
      setModalState({
        show: true,
        title: modal.TITLE_FILE_FORMAT,
        content: fileTypesTranslation,
      });
    } else if (exceedFileSize) {
      setModalState({
        show: true,
        title: modal.TITLE_FILE_SIZE,
        content: fileSizeTranslation,
      });
    } else if (duplicateFile) {
      setModalState({
        show: true,
        title: modal.TITLE_DUPLICATE_FILE,
        content: modal.BODY_DUPLICATE_FILE,
      });
    } else if (emptyFileSize) {
      setModalState({
        show: true,
        title: modal.TITLE_EMPTY_FILE,
        content: modal.BODY_EMPTY_FILE,
      });
    } else if (invalidFileName) {
      setModalState({
        show: true,
        title: modal.TITLE_NO_FILE_NAME,
        content: modal.BODY_NO_FILE_NAME,
      });
    }

    return isValid;
  };

  const handleSelectFiles = async (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    const files = 'dataTransfer' in e ? [...e.dataTransfer.files] : [...e.target.files];
    const file = 'dataTransfer' in e ? e.dataTransfer.files[0] : e.target.files[0];
    const validFile = validateFile(file);
    if (!validFile) {
      return;
    }

    const fileReader = new FileReader();
    fileReader.onload = () => {
      const fileLink = URL.createObjectURL(file);
      setCurrentFile([
        {
          name: file.name,
          size: file.size,
          link: fileLink || '',
          type: file.type,
          title: document.TITLE,
          mandatory: document.MANDATORY,
        },
      ]);
      const fileNameListCopy = [...uploadingFileNameList, file.name];
      setUploadingFileNameList(fileNameListCopy);
      setTempFile(files);
      onUpload(files);
    };
    fileReader.readAsDataURL(file);
  };

  const handleRetry = (file) => {
    const updatedCurrentFile = currentFile.map((currentFile) => {
      if (currentFile.name === file.name) {
        currentFile.retrying = true;
      }

      return currentFile;
    });
    setCurrentFile(updatedCurrentFile);
    onUpload(tempFile);
  };

  const errorFileRetry = currentFile.find((item) => 'retrying' in item);

  useEffect(() => {
    const foundFile = selectedFiles.find((item) => item.title === document.TITLE) ?? null;
    setUploadedFile(foundFile);
    if (currentFile.length > 0 && fileUpload && fileUpload.fileName === currentFile[0].name) {
      setUploadedFile(currentFile.find((item) => item.title === document.TITLE) ?? null);
      selectedFiles.push(...currentFile.filter((file) => file.retrying === true || !('retrying' in file)));
      setCurrentFile([]);
      removeUploadingFileNameList(currentFile[0].name);
      setTempFile(null);
    }
  }, [currentFile, fileUpload]);

  useEffect(() => {
    if (!loading && (uploadError >= 400 || uploadError === 0)) {
      const updatedCurrentFile = currentFile.map((currentFile) => {
        if (currentFile.title === document.TITLE) {
          currentFile.retrying = false;
        }

        return currentFile;
      });
      setCurrentFile(updatedCurrentFile);
    }
  }, [loading, uploadError]);

  return (
    <>
      <div className="file-upload-container mb-4">
        <div className="d-flex flex-column flex-md-row align-items-center justify-content-between">
          <div className="col-12 col-md-8 d-flex justify-content-center justify-content-md-start">
            <p className="mb-md-0">
              {document.TITLE}
              {document.MANDATORY && <span className="legal-id-mandatory">*</span>}
            </p>
          </div>
          <div className="col-12 col-md-4 d-flex justify-content-center justify-content-md-end">
            {currentFile.map((file, index) => {
              if (file.title === document.TITLE) {
                if (file.retrying) {
                  return (
                    <div
                      className="file-upload-document-container d-flex flex-fill"
                      key={index}
                    >
                      <FileTypeImg fileType="uploading" />
                      <div className="d-flex flex-column flex-grow-1">
                        <span>{trimFileName(file.name)}</span>
                        <span className="file-upload-size">
                          {heading.UPLOADING}... <span dir="ltr">{percentage}%</span>
                        </span>
                      </div>
                    </div>
                  );
                }
                if (file.retrying === false) {
                  return (
                    <div
                      className="file-upload-document-container d-flex flex-fill"
                      key={index}
                    >
                      <FileTypeImg fileType="fail" />
                      <div className="d-flex flex-column flex-grow-1">
                        <span>{trimFileName(file.name)}</span>
                        <span className="file-upload-size">
                          {heading.UPLOAD_FAILED}{' '}
                          <span
                            className="file-upload-link"
                            onClick={() => handleRetry(file)}
                          >
                            {heading.UPLOAD_RETRY}
                          </span>
                        </span>
                      </div>
                      <img
                        alt="Remove File"
                        src={FileRemoveIcon}
                        onClick={() => {
                          setCurrentFile([]);
                          setTempFile(null);
                          removeUploadingFileNameList(file.name);
                        }}
                        style={{
                          cursor: 'pointer',
                          width: '1.5rem',
                          height: '1.5rem',
                        }}
                      />
                    </div>
                  );
                }
              }

              return null;
            })}
            {currentFile[0] && !errorFileRetry && currentFile[0]?.title === document.TITLE ? (
              // Uploading
              <div className="file-upload-document-container d-flex flex-fill">
                <FileTypeImg fileType="uploading" />
                <div className="d-flex flex-column flex-grow-1">
                  <span>{trimFileName(currentFile[0]?.name)}</span>
                  <span className="file-upload-size">
                    {heading.UPLOADING}&nbsp;<span dir="ltr">{percentage}%</span>...
                  </span>
                </div>
              </div>
            ) : uploadedFile ? (
              // Upload success
              <div className="file-upload-document-container d-flex flex-fill align-items-center">
                <FileTypeImg fileType={uploadedFile?.type} />
                <div className="d-flex flex-column flex-grow-1">
                  <a
                    href={uploadedFile?.link}
                    target="_blank"
                    rel="noreferrer"
                    className="file-upload-link"
                  >
                    {trimFileName(uploadedFile?.name)}
                  </a>
                  <span className="file-upload-size">
                    <span dir="ltr">{formatBytes(uploadedFile?.size)}</span>
                  </span>
                </div>
                <img
                  alt="Remove File"
                  src={FileRemoveIcon}
                  onClick={() => handleRemoveFile(uploadedFile)}
                  style={{
                    cursor: 'pointer',
                    width: '1.5rem',
                    height: '1.5rem',
                  }}
                />
              </div>
            ) : !errorFileRetry ? (
              // No upload yet
              <>
                {openDialogOnClick && (
                  <FileInput
                    inputRef={inputRef}
                    types={types}
                    onInputFiles={handleSelectFiles}
                  />
                )}
                <Button
                  variant="secondary"
                  className="file-upload-button"
                  onClick={openDialogOnClick ? openFileDialog : undefined}
                >
                  {heading.BROWSE_FILES}
                </Button>
              </>
            ) : null}
          </div>
        </div>
      </div>
      <BasicModal2
        show={modalState.show}
        title={modalState.title}
        content={modalState.content}
        actions={[{ type: 'secondary', name: modal.FILE_UPLOAD_CLOSE }]}
        actionHandler={() => setModalState({ ...modalState, show: false })}
      />
    </>
  );
};

export default FileUploader;
