import {
  SET_APP_PROP,
  SET_ETL_LIST,
  SET_ETL_STATUS,
  UPDATE_ETL_LIST,
  SET_TRACK_USER_BY_ACTIVITY,
  GET_MAINTENANCE_STATUS,
  SET_MAINTENANCE_STATUS,
  GET_TIMEOUT_SESSION,
  SET_TIMEOUT_SESSION_ERROR,
  GET_CUSTOMER_INFO,
  SET_CUSTOMER_INFO,
  SET_CUSTOMER_INFO_ERROR,
} from './actionTypes';

export const updateEtlList = (
  validateMyBillData,
  companyInformation = {},
  values = {},
  contactCountryCode,
  isValidated = false
) => {
  return {
    type: UPDATE_ETL_LIST,
    payload: {
      validateMyBillData,
      companyInformation,
      values,
      contactCountryCode,
      isValidated,
    },
  };
};

export const setEtlStatus = (status) => {
  return {
    type: SET_ETL_STATUS,
    payload: status,
  };
};

export const setAppProp = (key, value) => {
  return {
    type: SET_APP_PROP,
    payload: { key, value },
  };
};

export const setEtlList = (etlList) => {
  return {
    type: SET_ETL_LIST,
    payload: etlList,
  };
};

export const postUserTrackId = (content) => {
  return {
    type: SET_TRACK_USER_BY_ACTIVITY,
    payload: content,
  };
};

export const setAppTimers = (configurations) => {
  const hardTimeoutValue = configurations?.ctryCfgs?.find((ctryCfg) => ctryCfg.typeCd === 'HARD_TIMEOUT')?.value || '';
  const warnTimeoutValue = configurations?.ctryCfgs?.find((ctryCfg) => ctryCfg.typeCd === 'WARN_TIMEOUT')?.value || '';
  const shippingTimeoutValue =
    configurations?.ctryCfgs?.find((ctryCfg) => ctryCfg.typeCd === 'SHIPPING_PG_TIMEOUT')?.value || '';
  const shippingOssTimeoutValue =
    configurations?.ctryCfgs?.find((ctryCfg) => ctryCfg.typeCd === 'ONESPAN_TIMEOUT')?.value || '';
  const ixopayIframeLoadingTimeoutValue =
    configurations?.ctryCfgs?.find((ctryCfg) => ctryCfg.typeCd === 'IXO_IFRAME_LD_TIMEOUT')?.value || '';
  const defaultDisplayLanguage =
    configurations?.ctryCfgs?.find((ctryCfg) => ctryCfg.typeCd === 'DEFAULT_DISPLAY_LANGUAGE')?.value || '';
  const ixopayPageRedirect =
    configurations?.ctryCfgs?.find((ctryCfg) => ctryCfg.typeCd === 'IXOPAY_PG_REDIRECT')?.value === 'Y';

  const paymentMethodType =
    configurations?.ctryCfgs?.find((ctryCfg) => ctryCfg.typeCd === 'acc_payMthSDO_payMthTy')?.value || '';
  const paymentExitSubType =
    configurations?.ctryCfgs?.find((ctryCfg) => ctryCfg.typeCd === 'acc_payMthSDO_payMthTy_ExTy')?.value || '';
  const showTradingField = configurations?.ctryCfgs?.find((ctryCfg) => ctryCfg.typeCd === 'SHOW_TRD_NM')?.value === 'Y';
  const showPaymentPage = configurations?.ctryCfgs?.find((ctryCfg) => ctryCfg.typeCd === 'SHOW_PAYMENT')?.value === 'Y';
  const dhlPass = configurations?.ctryCfgs?.find((ctryCfg) => ctryCfg.typeCd === 'DHLPASS_LOGIN')?.value === 'Y';
  // converting timeout value into milliseconds
  const hardTimeout = parseInt(hardTimeoutValue) * 60000;
  const warnTimeout = parseInt(warnTimeoutValue) * 60000;
  const leftOverTime = hardTimeout - warnTimeout;
  const shippingTimeout = parseInt(shippingTimeoutValue) * 60000;
  const shippingOssTimeout = parseInt(shippingOssTimeoutValue) * 60000;
  const paymentIxopayIframeTimeout = parseInt(ixopayIframeLoadingTimeoutValue) * 1000;

  return {
    type: SET_APP_PROP,
    payload: {
      key: 'appTimers',
      value: {
        hardTimeout,
        warnTimeout,
        leftOverTime,
        shippingTimeout,
        shippingOssTimeout,
        paymentIxopayIframeTimeout,
        defaultDisplayLanguage,
        paymentMethodType,
        paymentExitSubType,
        showTradingField,
        showPaymentPage,
        ixopayPageRedirect,
        dhlPass,
      },
    },
  };
};

export const setBlackListUrls = (configurations) => {
  const blackListUrls: any = [];
  configurations?.ctryCfgs?.forEach((ctryCfg: any) => {
    if (ctryCfg.typeCd === 'BLACKLIST_URL_NAME') {
      return blackListUrls.push(ctryCfg.value);
    }
  });

  return {
    type: SET_APP_PROP,
    payload: { key: 'blackListUrls', value: blackListUrls },
  };
};

export const getMaintenanceStatus = (request) => {
  return {
    type: GET_MAINTENANCE_STATUS,
    payload: request,
  };
};

export const setMaintenanceStatus = (response) => {
  return {
    type: SET_MAINTENANCE_STATUS,
    payload: response,
  };
};

export const getTimeoutSession = (request) => {
  return {
    type: GET_TIMEOUT_SESSION,
    payload: request,
  };
};

export const setTimeoutSessionError = (error) => {
  return {
    type: SET_TIMEOUT_SESSION_ERROR,
    payload: error,
  };
};

export const getCustomerInfo = (request) => {
  return {
    type: GET_CUSTOMER_INFO,
    payload: request,
  };
};

export const setCustomerInfo = (response) => {
  return {
    type: SET_CUSTOMER_INFO,
    payload: response,
  };
};

export const setCustomerInfoError = (error) => {
  return {
    type: SET_CUSTOMER_INFO_ERROR,
    payload: error,
  };
};
