import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';

import companyImg from '../../../assets/icons/company_black.svg';
import editImg from '../../../assets/icons/edit_red.svg';
import webImg from '../../../assets/icons/web.svg';
import storeImg from '../../../assets/icons/store.svg';
import locationImg from '../../../assets/icons/location.svg';
import pdfImg from '../../../assets/icons/file_pdf_rgb_black.png';
import wordImg from '../../../assets/icons/file_doc_rgb_black_small.png';
import jpgImg from '../../../assets/icons/file_jpg_rgb_black.png';
import pngImg from '../../../assets/icons/file_png_rgb_black.png';
import excelImg from '../../../assets/icons/file_xls_rgb_black.png';

import './Panel.css';
import { directionLayout, fileTypes } from '../../../utils/constants';
import { trimFileName } from '../../../utils/helper';

type TCompanyPanel = {
  isEditable: boolean;
  onEdit?: () => void;
  content: any;
};

function CompanyPanel(props: TCompanyPanel) {
  const { isEditable, onEdit, content } = props;
  const { company, selectedFiles, fileUploadConfig, companyConfig } = useSelector((state: any) => state.company);
  const { hideCountryAndLegalIdSection, showTradingField, removeCompanyIndustry } = companyConfig;
  const { isEtl } = useSelector((state: any) => state.app);
  const { fileUpload } = fileUploadConfig;
  const { translations } = useSelector((state: any) => state.account);
  const {
    company: { legal },
  } = translations;

  const {
    companyName,
    registeredCountry,
    companyWebsite,
    companyIndustry,
    billingAddressSame,
    vAddress,
    bAddress,
    legalCodes,
    companyAddressCountry,
    billingAddressCountry,
    tradingName,
  } = company;

  const { company: companyLbl } = content;

  const fileTypeImages = {
    [fileTypes.pdf]: pdfImg,
    [fileTypes.word]: wordImg,
    [fileTypes.png]: pngImg,
    [fileTypes.jpg]: jpgImg,
    [fileTypes.excel]: excelImg,
  };

  const renderEditBtn = () => {
    if (isEditable) {
      return (
        <button
          id="companyEditBtn"
          type="button"
          className="btn edit-btn p-0"
          onClick={onEdit}
        >
          <img
            src={editImg}
            className="edit-icon"
            alt={companyLbl?.EDIT_COMPANY_PAGE}
          />
        </button>
      );
    }

    return null;
  };

  const renderWebsite = () => {
    if (companyWebsite) {
      return (
        <a
          className="web-link"
          href={companyWebsite}
        >
          {companyWebsite}
        </a>
      );
    }

    return '-';
  };

  const renderIndustry = () => {
    if (companyIndustry?.length > 0) {
      return companyIndustry[0]?.name;
    }

    return '-';
  };

  const renderCompanyAndBillingAddress = () => {
    if (billingAddressSame) {
      return (
        <>
          <Row className="mt-4">
            <Col>
              <p className="card-text optional-header-text mb-2">{companyLbl.LABEL_COMPANY_BILL_ADDRESS}</p>
              <div className="d-flex align-items-center">
                <img
                  src={locationImg}
                  alt={companyLbl.ADDRESS_ALT}
                />
                <span
                  className={`panel-main-text${document.documentElement.dir === directionLayout.rtl ? ' dir-ltr' : ''}`}
                >
                  {vAddress.addressLine1[0]}
                  {vAddress.addressLine2 && `, ${vAddress.addressLine2}`}
                  {vAddress.city && `, ${vAddress.city}`}
                  {vAddress.state && `, ${vAddress.state}`}
                  {vAddress.zipCode && `, ${vAddress.zipCode}`}
                  {vAddress.province && `, ${vAddress.province}`}
                  {companyAddressCountry && `, ${companyAddressCountry[0]?.name}`}
                </span>
              </div>
            </Col>
          </Row>
        </>
      );
    }

    return (
      <>
        <Row className="mt-4">
          <Col>
            <p className="card-text optional-header-text mb-2">{companyLbl.LABEL_COMPANY_ADDRESS}</p>
            <div className="d-flex align-items-center">
              <img
                src={locationImg}
                alt={companyLbl.ADDRESS_ALT}
              />
              <span
                className={`panel-main-text${document.documentElement.dir === directionLayout.rtl ? ' dir-ltr' : ''}`}
              >
                {vAddress.addressLine1[0]}
                {vAddress.addressLine2 && `, ${vAddress.addressLine2}`}
                {vAddress.city && `, ${vAddress.city}`}
                {vAddress.state && `, ${vAddress.state}`}
                {vAddress.zipCode && `, ${vAddress.zipCode}`}
                {vAddress.province && `, ${vAddress.province}`}
                {companyAddressCountry && `, ${companyAddressCountry[0]?.name}`}
              </span>
            </div>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col>
            <p className="card-text optional-header-text mb-2">{companyLbl.LABEL_BILL_ADDRESS}</p>
            <div className="d-flex align-items-center">
              <img
                src={locationImg}
                alt={companyLbl.BILLING_ADDRESS_ALT}
              />
              <span
                className={`panel-main-text${document.documentElement.dir === directionLayout.rtl ? ' dir-ltr' : ''}`}
              >
                {bAddress.addressLine1[0]}
                {bAddress.addressLine2 && `, ${bAddress.addressLine2}`}
                {bAddress.city && `, ${bAddress.city}`}
                {bAddress.state && `, ${bAddress.state}`}
                {bAddress.zipCode && `, ${bAddress.zipCode}`}
                {bAddress.province && `, ${bAddress.province}`}
                {billingAddressCountry && `, ${billingAddressCountry[0]?.name}`}
              </span>
            </div>
          </Col>
        </Row>
      </>
    );
  };

  const renderCompanyLegalIdDocuments = () => {
    if (fileUpload && selectedFiles.length > 0 && !isEtl) {
      return (
        <>
          <Row className="mt-4">
            <Col>
              <p className="card-text optional-header-text mb-2">{companyLbl.LABEL_UPLOAD_DOCUMENT}</p>
              {selectedFiles?.map((file, index) => {
                return (
                  <div
                    className="d-flex align-items-center mb-2 company-fileupload"
                    key={index}
                  >
                    <img
                      src={fileTypeImages[file.type]}
                      alt={companyLbl.ADDRESS_ALT}
                    />
                    <span className="panel-main-text">
                      <a
                        href={file.link}
                        target="_blank"
                        className="file-upload-link"
                        rel="noreferrer"
                      >
                        {trimFileName(file.name)}
                      </a>
                    </span>
                  </div>
                );
              })}
            </Col>
          </Row>
        </>
      );
    }
  };

  return (
    <div className={isEditable ? 'shadow rounded bg-white form-content' : 'col d-flex flex-column flex-nowrap'}>
      <div
        id="companyCard"
        className="card panel-card flex-grow-1"
      >
        <div
          id="companyCardBody"
          className="card-body p-0"
        >
          {renderEditBtn()}
          <div className="panel-row d-flex company-panel-row">
            <div className="d-flex card-icon-name align-items-center">
              <div className="card-icon">
                <img
                  src={companyImg}
                  alt={companyLbl.ICON_ALT}
                />
              </div>
              <div className="name-text">{companyName}</div>
            </div>
            {!hideCountryAndLegalIdSection && (
              <div className="country-text">
                {companyLbl.REGISTERED_IN} {registeredCountry[0]?.name}
              </div>
            )}
          </div>
          <Row className="panel-row">
            <Col className="d-flex align-items-center">
              <img
                src={webImg}
                alt={companyLbl.WEB_ICON_ALT}
              />
              <span className="panel-main-text">{renderWebsite()}</span>
            </Col>
          </Row>
          {!removeCompanyIndustry && (
            <Row className="panel-row">
              <Col className="d-flex align-items-center">
                <img
                  src={storeImg}
                  alt={companyLbl.STORE_ICON_ALT}
                />
                <span className="panel-main-text">{renderIndustry()}</span>
              </Col>
            </Row>
          )}
          {showTradingField && (
            <Row className="mt-4">
              <Col>
                <p className="card-text optional-header-text mb-2">{companyLbl.LABEL_TRADING_NAME}</p>
                <p className="mb-0">{tradingName}</p>
              </Col>
            </Row>
          )}

          {renderCompanyAndBillingAddress()}

          {renderCompanyLegalIdDocuments()}
          {legalCodes && Object.keys(legalCodes).length > 0 && (
            <>
              {Object.keys(legalCodes).map((legalId, index, array) => {
                return (
                  legalCodes[legalId] && (
                    <Row
                      className="mt-4"
                      key={index}
                    >
                      <Col>
                        <p className="card-text optional-header-text mb-2">{legal[legalId]}</p>
                        <p className="mb-0">{legalCodes[legalId]}</p>
                      </Col>
                    </Row>
                  )
                );
              })}
            </>
          )}
          {company.branchCode && registeredCountry[0]?.code === 'TH' && (
            <Row className="mt-4">
              <Col>
                <p className="card-text optional-header-text mb-2">{companyLbl.LABEL_BRANCH_CODE}</p>
                <p className="mb-0">{company.branchCode}</p>
              </Col>
            </Row>
          )}
        </div>
      </div>
    </div>
  );
}
export default CompanyPanel;
