import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { FieldArray, Form } from 'formik';
import _ from 'lodash';

import { Button, Spinner } from '../../common';
import chevronBackImg from '../../../assets/icons/chevron_back_red.svg';
import plusRedImg from '../../../assets/icons/plus_red.svg';
import spinnerImg from '../../../assets/icons/spinner.gif';

import FormikControl from '../../common/FormikControl/FormikControl';
import {
  getCompanyInfo,
  setCompany,
  setCompanyProp,
  getRegisteredCountryDetails,
  resetCompanyInfo,
  getLegalIdTranslations,
  setCompanyKey,
  setFileUploadConfig,
  getDocUploadSuccess,
} from '../../../store/actions/companyActions';
import { updateEtlList, postUserTrackId, setAppProp } from '../../../store/actions/appActions';
import Tooltip from '../../common/Tooltip/Tooltip';
import CompanyInfo from './CompanyInfo';
import './CompanyPage.css';
import {
  DEFAULT_ADDRESS,
  DEFAULT_COMPANY_FORM,
  DEFAULT_COMPANY_INFORMATION,
  LEGAL_ID_PLACEHOLDER,
  COUNTRY_PLACEHOLDER,
  BRANCH_CODE_PLACEHOLDER,
  BRV_EXTERNAL_WEBSITE_ERROR,
  pageMapper,
  daoSteps,
  nonDaoSteps,
  flowOneSteps,
  OPTION_FLOW_1,
  directionLayout,
  languageCode,
  paymentExitTypes,
  paymentExitSteps,
  addressFields,
  companyLegalMapper,
} from '../../../utils/constants';
import CountryModal from './CountryModal';
import LegalIdModal from './LegalIdModal';
import { setBrvError, setDaoProp, submitCustomer } from '../../../store/actions/daoActions';
import RetrieveCompanyInfoModal from './RetrieveCompanyInfoModal';
import { useTranslationSteps } from '../../../hooks/useTranslationSteps';
import { useMediaQuery } from '../../../hooks/useMediaQuery';
import { checkFranceSiren, checkFranceSiret } from '../../../utils/checkFranceLegalId';
import { objectsEqual } from '../../../utils/helper';
import { setContactKeyProp } from '../../../store/actions/contactActions';

const CompanyPage = ({ formik, onInputChange, prev, onInputVAddressCountryChange, onInputBAddressCountryChange }) => {
  const { values, setFieldValue, setFieldTouched, resetForm, errors } = formik;
  const dispatch = useDispatch<any>();
  const [showCountryAlert, setShowCountryAlert] = useState(false);
  const [showLegalAlert, setShowLegalAlert] = useState(false);
  const [showConditionalLegalAlert, setShowConditionalLegalAlert] = useState(false);
  const [showCompanyInfo, setShowCompanyInfo] = useState(true);
  const [addressLine1Changed, setAddressLine1Changed] = useState(false);
  const [showRetrieveCompanyAlert, setShowRetrieveCompanyAlert] = useState(false);
  const [legalCodes, setLegalCodes] = useState({});
  const [dirtyLegalCodes, setDirtyLegalCodes] = useState({});
  const [dirtyConditionalLegalCodes, setDirtyConditionalLegalCodes] = useState({});
  const [legalIdCode, setLegalIdCode] = useState('');
  const [conditionalLegalIdCode, setConditionalLegalIdCode] = useState('');

  const { userTrackId, isoCountriesListLocaleTranslation, dhlPassTrkId } = useSelector(
    (state: any) => state.corporateAccount
  );
  const {
    optionFlow,
    companyInformation,
    addressDetail,
    loadingValidateAddress,
    brvError,
    brvHttpStatus,
    billingAddressInfo,
    submitPostData,
  } = useSelector((state: any) => state.DAOReducer);
  const { isEtl, appTimers } = useSelector((state: any) => state.app);
  const { paymentMethodType, showTradingField, showPaymentPage } = appTimers;
  const { paymentProfile } = useSelector((state: any) => state.payment);
  const {
    company,
    countryConfigData,
    filteredLegalIds,
    mandatoryLegalIds,
    conditionalLegalIds,
    optionalLegalIds,
    loadingRegistrationDetails,
    selectedFiles,
    fileUploadStatusCode,
    fileUpload,
    companyConfig,
  } = useSelector((state: any) => state.company);
  const { hideCountryAndLegalIdSection, hideRetrieveCompanyDetailButton, companyCtryCd, defaultDisplayLanguage } =
    companyConfig;
  const { translations, selectedLanguage, daoConfigAkamai } = useSelector((state: any) => state.account);
  const {
    common: { select, dropDown },
    company: { heading, label, legal, tooltip, common, modal, error, accessibility },
    shipping: { spinner },
  } = translations;
  const companyDaoCountry =
    countryConfigData?.ctryCfgs?.find((ctryCfg) => ctryCfg.typeCd === 'DAO_COUNTRY')?.value === 'Y';
  const { contact, validateMyBillData, contactFields, consentContactValues } = useSelector(
    (state: any) => state.contact
  );
  const { showConsent } = contactFields;
  const { validated, regnoCheckBox, tradingName, companyName } = company;
  const companyCountryCode = company.registeredCountry[0]?.code;
  const configConditionalLegalIdMandatory = countryConfigData?.ctryRules?.rules?.reduce((mandatoryArray, rule) => {
    if (
      (rule.mandatoryInd === null || rule.mandatoryInd === 'Y') &&
      rule.attributeName.startsWith(companyLegalMapper.companyLegal)
    ) {
      mandatoryArray.push(rule.attributeName);
    }

    return mandatoryArray;
  }, []);

  const getConfigConditionalLegalIdValues = (configConditionalLegalIdMandatory || []).map((item) => {
    return item.replace(companyLegalMapper.companyLegal, '');
  });

  const conditionalLegalIdMandatoryFields = conditionalLegalIds.filter((conditionalLegalId) =>
    getConfigConditionalLegalIdValues.includes(conditionalLegalId.alwVal)
  );

  const mandatoryLegalCodesFilled = filteredLegalIds.every(({ legalIdCode, mandatory }) =>
    mandatory ? !!values.legalCodes[legalIdCode] : true
  );
  const conditionalLegalCodeFilled =
    !conditionalLegalIdMandatoryFields ||
    conditionalLegalIdMandatoryFields.length === 0 ||
    conditionalLegalIdMandatoryFields.some(
      (legalId) => values.legalCodes[legalId.alwVal] !== undefined && values.legalCodes[legalId.alwVal] !== ''
    );
  const legalCodesFilled = mandatoryLegalCodesFilled && conditionalLegalCodeFilled;
  const brvExternalWebsiteError =
    brvError && Object.values(brvError).length > 0
      ? Object.values(brvError).some((err: any) => err.error === BRV_EXTERNAL_WEBSITE_ERROR)
      : false;
  const [translatedDaoSteps, translatedNonDaoSteps, translatedFlowOneSteps, translatedPaymentExitSteps] =
    useTranslationSteps(daoSteps, nonDaoSteps, flowOneSteps, paymentExitSteps);

  const conditionalLegalIdOptions = conditionalLegalIdMandatoryFields?.map((conditionalLegalId) => ({
    value: conditionalLegalId.alwVal,
    ...(legal[conditionalLegalId.alwVal] && { label: legal[conditionalLegalId.alwVal] }),
  }));
  const optionalLegalIdOptions = optionalLegalIds.map((optionalLegalId) => ({
    value: optionalLegalId,
  }));

  const isMediumDevice = useMediaQuery('only screen and (min-width : 320px) and (max-width : 767px)');

  useEffect(() => {
    if (
      conditionalLegalIdMandatoryFields?.length > 0 &&
      !values.conditionalLegalIdType &&
      !values.legalCodes[values.conditionalLegalIdType]
    ) {
      setFieldValue('conditionalLegalIdType', conditionalLegalIdMandatoryFields[0].alwVal);
      setFieldValue(`legalCodes.${conditionalLegalIdMandatoryFields[0].alwVal}`, '');
    }
  }, [conditionalLegalIds, countryConfigData]);

  useEffect(() => {
    if (legal.defaultVal) {
      mandatoryLegalIds.forEach((key) => {
        if (legal.defaultVal && Object.prototype.hasOwnProperty.call(legal.defaultVal, key)) {
          const legalIdDefaultValue = legal.defaultVal[key];
          setFieldValue(`legalCodes.${key}`, legalIdDefaultValue);
        }
      });
    }
  }, [mandatoryLegalIds]);

  useEffect(() => {
    if (!companyCountryCode) {
      dispatch(setCompanyProp('registeredCountry', contact.country));
      dispatch(getRegisteredCountryDetails(contact.country));
      setFieldValue('registeredCountry', contact.country);
      dispatch(setCompanyProp('companyAddressCountry', contact.country));
      setFieldValue('companyAddressCountry', contact.country);
      dispatch(setCompanyProp('billingAddressCountry', contact.country));
      setFieldValue('billingAddressCountry', contact.country);
    }

    if ((dhlPassTrkId && !showConsent) || (userTrackId && !showConsent) || (submitPostData.accountId && showConsent)) {
      dispatch(
        postUserTrackId({
          trackingId: userTrackId || dhlPassTrkId,
          activityEndPage: pageMapper.company,
          accReqId: submitPostData.accountId || '',
          cntryCd: contact.country[0].code,
        })
      );
    }
  }, [submitPostData.accountId]);

  useEffect(() => {
    if (values.companyName !== '') {
      setTimeout(() => setFieldTouched('companyName', true));
    }
    if (values.vAddress.addressLine1.length > 0) {
      setTimeout(() => setFieldTouched('vAddress.addressLine1', true));
    }
    if (values.vAddress.addressLine2 !== '') {
      setTimeout(() => setFieldTouched('vAddress.addressLine2', true));
    }
    if (values.vAddress.city !== '') {
      setTimeout(() => setFieldTouched('vAddress.city', true));
    }
    if (values.vAddress.state !== '') {
      setTimeout(() => setFieldTouched('vAddress.state', true));
    }
    if (values.vAddress.province !== '') {
      setTimeout(() => setFieldTouched('vAddress.province', true));
    }
    if (values.vAddress.zipCode !== '') {
      setTimeout(() => setFieldTouched('vAddress.zipCode', true));
    }
    if (values?.bAddress?.addressLine1?.length > 0) {
      setTimeout(() => setFieldTouched('bAddress.addressLine1', true));
    }
    if (values.bAddress.addressLine2 !== '') {
      setTimeout(() => setFieldTouched('bAddress.addressLine2', true));
    }
    if (values.bAddress.city !== '') {
      setTimeout(() => setFieldTouched('bAddress.city', true));
    }
    if (values.bAddress.state !== '') {
      setTimeout(() => setFieldTouched('bAddress.state', true));
    }
    if (values.bAddress.province !== '') {
      setTimeout(() => setFieldTouched('bAddress.province', true));
    }
    if (values.bAddress.zipCode !== '') {
      setTimeout(() => setFieldTouched('bAddress.zipCode', true));
    }
    if (values.companyWebsite !== '') {
      setTimeout(() => setFieldTouched('companyWebsite', true));
    }
  }, []);

  useEffect(() => {
    setFieldValue('regnoCheckBox', regnoCheckBox);
  }, [regnoCheckBox]);

  useEffect(() => {
    if (countryConfigData?.ctryCfgs?.length > 0) {
      if (isEtl) {
        dispatch(setAppProp('steps', translatedNonDaoSteps));
      } else if (optionFlow === OPTION_FLOW_1) {
        dispatch(setAppProp('steps', translatedFlowOneSteps));
      } else if (paymentExitTypes.includes(paymentMethodType) && !showPaymentPage) {
        dispatch(setAppProp('steps', translatedPaymentExitSteps));
      } else {
        dispatch(setAppProp('steps', translatedDaoSteps));
      }
    }
  }, [isEtl, countryConfigData]);

  useEffect(() => {
    if (showConsent && !_.isEmpty(paymentProfile) && !_.isEmpty(validateMyBillData)) {
      if (consentContactValues) {
        dispatch(submitCustomer(false));
        dispatch(setContactKeyProp('consentContactValues', false));
      }
    }
  }, [paymentProfile, validateMyBillData]);

  const onCountryChange = (changedCountry) => {
    const changedCountryCd = changedCountry[0]?.code;
    if (changedCountryCd) {
      setShowCountryAlert(true);
    }
  };

  useEffect(() => {
    dispatch(updateEtlList(validateMyBillData, companyInformation, values, contact.country[0].code, validated));
  }, [
    countryConfigData,
    validateMyBillData,
    companyInformation,
    JSON.stringify(values),
    contact.country[0].code,
    validated,
    paymentProfile,
  ]);

  useEffect(() => {
    checkFranceSiret(setFieldValue, companyCountryCode, values.legalCodes['SIRET']);
  }, [values.legalCodes['SIRET']]);

  const onLegalIdChange = (legalType) => {
    if (mandatoryLegalIds.includes(legalType)) {
      if (values.validated && !dirtyLegalCodes[legalType]) {
        setShowLegalAlert(true);
        setLegalIdCode(legalType);
      }
    }
  };

  const onLegalIdTypeChange = (initialType, newType) => {
    if (initialType in values.legalCodes) {
      values.legalCodes[newType] = '';
      delete values.legalCodes[initialType];
      setFieldValue('legalCodes', values.legalCodes);
    }
  };

  const onConditionalLegalIdChange = (legalType) => {
    const getconditionalLegalIdsTypes: any = [];
    conditionalLegalIdMandatoryFields?.forEach((type: any) => {
      if (type.attrNm === 'LEADIDENTIFIERGROUP') {
        return getconditionalLegalIdsTypes.push(type.alwVal);
      }
    });
    if (getconditionalLegalIdsTypes.includes(legalType)) {
      if (values.validated && !dirtyConditionalLegalCodes[legalType]) {
        setShowConditionalLegalAlert(true);
        setConditionalLegalIdCode(legalType);
      }
    }
  };

  useEffect(() => {
    let vAddress;
    let bAddress;

    if (companyInformation?.companyAddresses?.length) {
      const vAddressApi = companyInformation?.companyAddresses?.find((address) => address.addressType === 'COM');
      const bAddressApi = billingAddressInfo?.companyAddresses?.find((address) => address.addressType === 'BIL');

      vAddress = { ...vAddressApi };

      bAddress = { ...bAddressApi };

      if (vAddress?.addressLine1) {
        vAddress.addressLine1 = [vAddress.addressLine1];
      }
      if (vAddressApi) {
        setTimeout(() => setFieldTouched('vAddress.addressLine1', true));
      }

      if (bAddress?.addressLine1) {
        bAddress.addressLine1 = [bAddress.addressLine1];
      }

      if (bAddressApi) {
        addressFields.forEach((field) => {
          setTimeout(() => setFieldTouched(`bAddress.${field}`, true));
        });
      }

      if (!bAddressApi && objectsEqual(vAddressApi, values['bAddress'])) {
        setFieldValue('billingAddressSame', true);
      } else if (bAddressApi && !objectsEqual(vAddressApi, values['bAddress']) && !isEtl) {
        setFieldValue('billingAddressSame', false);
      }
    }

    if (companyInformation?.companyName && !values.validated) {
      const compNameCheckApplied = companyInformation?.compNameCheckApplicable === 'Y';
      if (compNameCheckApplied) {
        setFieldValue('companyName', companyInformation?.companyName);
        setTimeout(() => setFieldTouched('companyName', true));
      } else {
        setFieldValue('companyName', '');
      }
      setFieldValue('vAddress', vAddress || DEFAULT_ADDRESS);
      setFieldValue('bAddress', bAddress || DEFAULT_ADDRESS);
      setFieldValue('validated', true);
      setFieldTouched('companyName', false);
    }
    if (addressLine1Changed) {
      setTimeout(() => setFieldTouched('vAddress', true));
      setFieldValue('vAddress', addressDetail['vAddress'] || DEFAULT_ADDRESS);
      setFieldValue('bAddress', addressDetail['bAddress'] || DEFAULT_ADDRESS);
      setAddressLine1Changed(false);
    }
    if (!_.isEmpty(brvError)) {
      setFieldValue('validated', true);
      if (!_.isEmpty(brvError)) {
        Object.keys(brvError).forEach((key) => {
          setFieldTouched(`legalCodes.${key}`, true);
        });
      }
    }
  }, [companyInformation, addressDetail, brvError]);

  useEffect(() => {
    if (showTradingField) {
      if (companyName === tradingName) {
        setFieldValue('tradingCompanyNameSame', true);
      } else {
        setFieldValue('tradingCompanyNameSame', false);
      }
    }
  }, [countryConfigData]);

  const handleCountryAlert = (value) => {
    setShowCountryAlert(false);
    if (value) {
      dispatch(setDaoProp('companyInformation', DEFAULT_COMPANY_INFORMATION));
      dispatch(setDaoProp('billingAddressInfo', DEFAULT_COMPANY_INFORMATION));
      dispatch(setCompanyProp('registeredCountry', values.registeredCountry));
      DEFAULT_COMPANY_FORM.registeredCountry = values.registeredCountry;
      dispatch(setCompany(DEFAULT_COMPANY_FORM));
      resetForm({ values: DEFAULT_COMPANY_FORM });
      dispatch(getRegisteredCountryDetails(values.registeredCountry));
      dispatch(setCompanyProp('companyAddressCountry', values.registeredCountry));
      setFieldValue('companyAddressCountry', values.registeredCountry);
      dispatch(setCompanyProp('billingAddressCountry', values.registeredCountry));
      setFieldValue('billingAddressCountry', values.registeredCountry);
      dispatch(setBrvError({}));
      dispatch(setFileUploadConfig({}));
    } else {
      setFieldValue('registeredCountry', company.registeredCountry);
    }
  };

  useEffect(() => {
    if (defaultDisplayLanguage && companyCtryCd === contact.country[0].code) {
      dispatch(getLegalIdTranslations(`${companyCtryCd}/${companyCtryCd}_${selectedLanguage}.json`));
    } else if (defaultDisplayLanguage && companyCtryCd !== contact.country[0].code) {
      dispatch(
        getLegalIdTranslations(
          `${companyCtryCd}/${companyCtryCd}_en-${
            daoConfigAkamai[languageCode.enGB].includes(companyCtryCd) ? 'GB' : 'US'
          }.json`
        )
      );
    } else if (defaultDisplayLanguage === '') {
      dispatch(getLegalIdTranslations('DEFAULT.json'));
    }
  }, [companyConfig]);

  const handleLegalAlert = (value, legalType) => {
    setShowLegalAlert(false);
    if (value) {
      const currentLegalIdCodeField = { ...dirtyLegalCodes };
      currentLegalIdCodeField[legalType] = true;
      setDirtyLegalCodes(currentLegalIdCodeField);
      setFieldValue('validated', false);
      setFieldValue('companyName', '');
      setFieldValue('tradingName', '');
      dispatch(setDaoProp('companyInformation', DEFAULT_COMPANY_INFORMATION));
      dispatch(setDaoProp('billingAddressInfo', DEFAULT_COMPANY_INFORMATION));
      dispatch(resetCompanyInfo());
      setShowCompanyInfo(false);
      dispatch(setBrvError({}));
      setTimeout(() => setFieldTouched('legalCodes', true));
    } else {
      if (_.isEmpty(company.legalCodes)) {
        setFieldValue('legalCodes', legalCodes);
      } else if (_.isEmpty(legalCodes)) {
        setFieldValue('legalCodes', company.legalCodes);
      } else {
        setFieldValue('legalCodes', legalCodes);
      }
    }
  };

  const handleConditionalLegalAlert = (value, legalType) => {
    setShowConditionalLegalAlert(false);
    if (value) {
      const currentLegalIdCodeField = { ...dirtyConditionalLegalCodes };
      currentLegalIdCodeField[legalType] = true;
      setDirtyConditionalLegalCodes(currentLegalIdCodeField);
      setFieldValue('validated', false);
      setFieldValue('companyName', '');
      setFieldValue('tradingName', '');
      dispatch(setDaoProp('companyInformation', DEFAULT_COMPANY_INFORMATION));
      dispatch(setDaoProp('billingAddressInfo', DEFAULT_COMPANY_INFORMATION));
      dispatch(resetCompanyInfo());
      setShowCompanyInfo(false);
      dispatch(setBrvError({}));
      setTimeout(() => setFieldTouched('legalCodes', true));
    } else {
      if (_.isEmpty(company.legalCodes)) {
        setFieldValue('legalCodes', legalCodes);
      } else if (_.isEmpty(legalCodes)) {
        setFieldValue('legalCodes', company.legalCodes);
      } else {
        setFieldValue('legalCodes', legalCodes);
      }
    }
  };

  const retrieveCompanyInfo = () => {
    if (values.validated && showCompanyInfo) {
      setShowRetrieveCompanyAlert(true);
    } else {
      fetchCompanyDetails();
    }
  };

  const fetchCompanyDetails = () => {
    for (const key in values.legalCodes) {
      if (!values.legalCodes[key]) {
        delete values.legalCodes[key];
      }
    }
    dispatch(setDaoProp('companyInformation', DEFAULT_COMPANY_INFORMATION));
    dispatch(setDaoProp('billingAddressInfo', DEFAULT_COMPANY_INFORMATION));
    dispatch(setCompanyProp('tradingName', companyName));
    setTimeout(() => setFieldTouched('companyName', false));
    setFieldTouched('vAddress', false);
    setFieldTouched('bAddress', false);
    setFieldValue('vAddress', DEFAULT_ADDRESS);
    setFieldValue('bAddress', DEFAULT_ADDRESS);
    setFieldValue('companyWebsite', '');
    setFieldValue('companyIndustry', []);
    setLegalCodes(values.legalCodes);
    setDirtyLegalCodes({});
    setDirtyConditionalLegalCodes({});
    dispatch(getCompanyInfo(values.legalCodes));
    setShowCompanyInfo(true);
    dispatch(setCompanyProp('companyAddressCountry', values.registeredCountry));
    setFieldValue('companyAddressCountry', values.registeredCountry);
    dispatch(setCompanyProp('billingAddressCountry', values.registeredCountry));
    setFieldValue('billingAddressCountry', values.registeredCountry);
    setFieldValue('billingAddressSame', true);
    setFieldValue('tradingCompanyNameSame', true);
    dispatch(setCompanyKey('selectedFiles', []));
  };

  const registrationNotPresentChange = (e) => {
    const checked = e.target.checked;
    if (checked) {
      setFieldValue('legalCodes', {});
      setFieldTouched('legalCodes', false);
      setFieldValue('validated', false);
      setShowCompanyInfo(true);
      dispatch(setDaoProp('companyInformation', DEFAULT_COMPANY_INFORMATION));
      dispatch(setDaoProp('billingAddressInfo', DEFAULT_COMPANY_INFORMATION));
      dispatch(getRegisteredCountryDetails(values.registeredCountry));
      dispatch(setBrvError({}));
    }
    setFieldValue('companyName', '');
    setFieldValue('tradingName', '');
    dispatch(setCompanyProp('companyName', ''));
    dispatch(setCompanyProp('tradingName', ''));
    setFieldTouched('companyName', false);
    setFieldValue('vAddress', DEFAULT_ADDRESS);
    setFieldTouched('vAddress', false);
    setFieldValue('bAddress', DEFAULT_ADDRESS);
    setFieldTouched('bAddress', false);
    setFieldValue('companyWebsite', '');
    setFieldTouched('companyWebsite', false);
    setFieldValue('companyIndustry', []);
    setFieldTouched('companyIndustry', false);
    setTimeout(() => setFieldTouched('companyName', false));
    setTimeout(() => setFieldTouched('companyAddressCountry', false));
    dispatch(setCompanyProp('companyAddressCountry', values.registeredCountry));
    setFieldValue('companyAddressCountry', values.registeredCountry);
    dispatch(setCompanyProp('billingAddressCountry', values.registeredCountry));
    setFieldValue('billingAddressCountry', values.registeredCountry);
    dispatch(setCompanyKey('brvRegistrationLst', []));
  };

  const onPrev = () => {
    dispatch(setCompany(values));
    prev();
  };

  const handleRetrieveCompanyInfoModal = (value) => {
    setShowRetrieveCompanyAlert(false);
    if (!value) {
      setFieldValue('validated', false);
      fetchCompanyDetails();
      dispatch(getRegisteredCountryDetails(values.registeredCountry));
    }
  };

  const renderMandatoryLegalIds = () => {
    const sortedLegalIds =
      legal?.seqMandLegalIds?.length > 0
        ? filteredLegalIds.sort((a, b) => {
            const indexA = a.legalIdCode ? legal.seqMandLegalIds.indexOf(a.legalIdCode) : -1;
            const indexB = b.legalIdCode ? legal.seqMandLegalIds.indexOf(b.legalIdCode) : -1;

            return (indexA === -1 ? Infinity : indexA) - (indexB === -1 ? Infinity : indexB);
          })
        : filteredLegalIds;

    return sortedLegalIds.map(
      (refData: any) =>
        refData.mandatory && (
          <Row
            className="field-row"
            key={refData.legalIdCode}
          >
            <Col xs={12}>
              <FormikControl
                control="input"
                name={`legalCodes.${refData.legalIdCode}`}
                ariaLabel={legal?.accessibility?.label[refData.legalIdCode]}
                type="text"
                checkMark={true}
                label={legal[refData.legalIdCode] ?? refData.legalIdDsc}
                mandatoryLabel={refData.mandatory ? ' * ' : ' '}
                placeholder={LEGAL_ID_PLACEHOLDER}
                fieldInfo={{ info: legal?.tooltip?.label[refData.legalIdCode] }}
                // As discussed with Business team, enabled legal id fields for non-dao flow
                disabled={values.registrationNotPresent || checkFranceSiren(companyCountryCode, refData.legalIdCode)}
                onChange={() => onLegalIdChange(refData.legalIdCode)}
                delayErrorMsg={true}
                mandatoryMsg={error.legal[refData.legalIdCode]?.EMPTY ?? error.legal.EMPTY}
                errorMsgClassName={
                  companyCtryCd !== contact.country[0].code && document.documentElement.dir === directionLayout.rtl
                    ? 'direction-ltr'
                    : ''
                }
              />
            </Col>
          </Row>
        )
    );
  };

  const renderBranchCode = () => {
    return (
      <Row className="field-row">
        <Col xs={12}>
          <FormikControl
            control="input"
            name="branchCode"
            ariaLabel={accessibility.label.BRANCH_CODE}
            type="text"
            checkMark={true}
            label={label.BRANCH_CODE}
            mandatoryLabel=" *"
            placeholder={BRANCH_CODE_PLACEHOLDER}
            {...(tooltip?.BRANCH_CODE && {
              fieldInfo: { info: tooltip.BRANCH_CODE },
            })}
            disabled={values.registrationNotPresent}
            errorMsgClassName={
              companyCtryCd !== contact.country[0].code && document.documentElement.dir === directionLayout.rtl
                ? 'direction-ltr'
                : ''
            }
          />
        </Col>
      </Row>
    );
  };

  const renderConditionalLegalIds = () => {
    return (
      <div className="row">
        {conditionalLegalIdMandatoryFields.length > 1 && (
          <div className="col-md-3 mt-4">
            <FormikControl
              control="select"
              name="conditionalLegalIdType"
              ariaLabel={accessibility.label.CONDITIONAL_SELECT}
              label={label.CONDITIONAL_LEGAL_ID_SELECT}
              mandatoryLabel="*"
              placeholder={select.INITIAL_VALUE}
              options={conditionalLegalIdOptions}
              onChange={(e) => onLegalIdTypeChange(values.conditionalLegalIdType, e.target.value)}
              disabled={values.registrationNotPresent}
              {...(tooltip?.CONDITIONAL_LEGAL_ID_SELECT && {
                fieldInfo: { info: tooltip.CONDITIONAL_LEGAL_ID_SELECT },
              })}
            />
          </div>
        )}
        <div className={`mt-4 ${conditionalLegalIdMandatoryFields.length > 1 ? 'col-md-9' : 'col-md-12'}`}>
          <FormikControl
            control="input"
            name={`legalCodes.${values.conditionalLegalIdType}`}
            ariaLabel={accessibility.label.CONDITIONAL_TEXT}
            type="text"
            checkMark={true}
            label={legal[values.conditionalLegalIdType] ?? values.conditionalLegalIdType}
            fieldInfo={{ info: legal?.tooltip?.label[values.conditionalLegalIdType] }}
            mandatoryLabel=" * "
            placeholder={LEGAL_ID_PLACEHOLDER}
            disabled={values.registrationNotPresent}
            onChange={() => onConditionalLegalIdChange(values.conditionalLegalIdType)}
            delayErrorMsg={true}
            errorMsgClassName={
              companyCtryCd !== contact.country[0].code && document.documentElement.dir === directionLayout.rtl
                ? 'direction-ltr'
                : ''
            }
          />
        </div>
      </div>
    );
  };

  const renderOptionalLegalIds = () => {
    return (
      <>
        <Row className="field-row">
          <Col xs={12}>
            <p className="fw-bold mb-0">{label.OTHER_TRADE_ID}</p>
          </Col>
        </Row>
        <FieldArray name="optionalLegalIds">
          {({ push }) => (
            <>
              {values.optionalLegalIds?.map((optionalLegalId, index) => {
                const availableOptions = optionalLegalIdOptions.filter(
                  (optionalLegalId) =>
                    !values.optionalLegalIds?.some((r, i) => i !== index && r.type === optionalLegalId.value)
                );

                return (
                  <div
                    className="row"
                    key={index}
                  >
                    {optionalLegalIds?.length > 1 && (
                      <div className="col-md-3 mt-4">
                        <FormikControl
                          control="select2"
                          name={`optionalLegalIds.${index}.type`}
                          ariaLabel={accessibility.label.OPTIONAL_SELECT}
                          label={label.OPTIONAL_LEGALID_SELECT}
                          placeholder={select.INITIAL_VALUE}
                          options={availableOptions}
                          onChange={(e) => onLegalIdTypeChange(optionalLegalId.type, e.target.value)}
                          disabled={values.registrationNotPresent}
                          {...(tooltip?.OPTIONAL_LEGALID_SELECT && {
                            fieldInfo: { info: tooltip.OPTIONAL_LEGALID_SELECT },
                          })}
                        />
                      </div>
                    )}
                    <div className={`mt-4 ${optionalLegalIds?.length > 1 ? 'col-md-9' : 'col-md-12'}`}>
                      <FormikControl
                        control="input"
                        name={`legalCodes.${optionalLegalId.type}`}
                        ariaLabel={accessibility.label[optionalLegalId.type]}
                        type="text"
                        fieldInfo={{ info: legal?.tooltip?.label[optionalLegalId.type] }}
                        label={legal[optionalLegalId.type] ?? optionalLegalId.type}
                        placeholder={LEGAL_ID_PLACEHOLDER}
                        disabled={values.registrationNotPresent}
                        delayErrorMsg={true}
                        errorMsgClassName={
                          companyCtryCd !== contact.country[0].code &&
                          document.documentElement.dir === directionLayout.rtl
                            ? 'direction-ltr'
                            : ''
                        }
                      />
                    </div>
                  </div>
                );
              })}
              {!(values.optionalLegalIds?.length >= optionalLegalIds.length) && (
                <Row className="field-row">
                  <Col xs={12}>
                    <span
                      className="btn-back d-inline-flex align-middle"
                      role="button"
                      onClick={() => {
                        push({
                          type: optionalLegalIdOptions.find(
                            (option) => !values.optionalLegalIds?.some((legalId) => legalId.type === option.value)
                          ).value,
                          value: '',
                        });
                      }}
                      title={accessibility.label.ADD_OPTIONAL_BTN}
                    >
                      <img
                        src={plusRedImg}
                        alt={common.ICON_ADD_OPTIONAL_BTN}
                      />
                      <span className="ms-2">{common.ADD_OPTIONAL_BTN}</span>
                    </span>
                  </Col>
                </Row>
              )}
            </>
          )}
        </FieldArray>
      </>
    );
  };

  const renderCompanyInfo = () => {
    if (
      values.registrationNotPresent ||
      (values.validated && _.isEmpty(brvError)) ||
      isEtl ||
      !companyDaoCountry ||
      brvHttpStatus >= 500 ||
      brvExternalWebsiteError ||
      hideCountryAndLegalIdSection ||
      hideRetrieveCompanyDetailButton
    ) {
      return (
        <>
          {showCompanyInfo && (
            <CompanyInfo
              formik={formik}
              addressLine1Change={setAddressLine1Changed}
              onInputVAddressCountryChange={onInputVAddressCountryChange}
              onInputBAddressCountryChange={onInputBAddressCountryChange}
            />
          )}
        </>
      );
    }

    return null;
  };

  const checkMandatoryDocument = () => {
    if (modal.showDocuments?.sampleList && !isEtl) {
      const mandatoryDocumentTitle = Object.values(modal.showDocuments.sampleList)
        .filter((doc: any) => doc.MANDATORY)
        .map((doc: any) => doc.TITLE);

      return mandatoryDocumentTitle.every((doc: any) => selectedFiles.some((file: any) => file.title.includes(doc)));
    } else {
      return true;
    }
  };

  useEffect(() => {
    if (fileUploadStatusCode >= 200 && fileUploadStatusCode < 300) {
      dispatch(getDocUploadSuccess(fileUpload));
    }
  }, [fileUpload]);

  return (
    <>
      <Row>
        <Col className="p-0">
          <h1 className="m-0">
            <p className={`content-title ${companyCountryCode === 'US' ? 'company-mb-2' : ''}`}>
              {heading.COMPANY_TELL_US_MSG}
            </p>
          </h1>
          {!hideCountryAndLegalIdSection && <p className="content-subtitle">{heading.MANDATORY_FIELDS_MSG}</p>}
        </Col>
      </Row>
      <Form>
        {!hideCountryAndLegalIdSection && (
          <Row
            className={`shadow ${
              values.registrationNotPresent || validated || isEtl ? 'rounded-top' : 'rounded'
            } bg-white form-content`}
          >
            <Col>
              <Row className="field-row">
                <Col>
                  <FormikControl
                    control="typeahead"
                    name="registeredCountry"
                    ariaLabel={accessibility.label.COUNTRY}
                    className="flag-label"
                    label={label.REGISTERED_COUNTRY}
                    mandatoryLabel="*"
                    emptyLabel={dropDown.EMPTY_LABEL}
                    options={isoCountriesListLocaleTranslation}
                    placeholder={COUNTRY_PLACEHOLDER}
                    filterBy={(option, props) => {
                      if (props.selected.length) {
                        return true;
                      }

                      return ['name', 'code'].some(
                        (key) => option[key].toLowerCase().indexOf(props.text.toLowerCase()) !== -1
                      );
                    }}
                    menuItemChildren={(option: any) => {
                      return (
                        <>
                          <div className="typeahead-menu-item">
                            <span className={`fi fi-${option.code.toLowerCase()} typeahead-menu-flag`}></span>
                            <span>{option.name}</span>
                          </div>
                        </>
                      );
                    }}
                    onChange={onCountryChange}
                    onInputChange={onInputChange}
                    {...(tooltip?.REGISTERED_COUNTRY && { fieldInfo: { info: tooltip.REGISTERED_COUNTRY } })}
                  />
                </Col>
              </Row>
              {loadingRegistrationDetails ? (
                <div className="spinner-container field-row">
                  <Spinner
                    animation="border"
                    variant="secondary"
                  />
                </div>
              ) : (
                <>
                  {conditionalLegalIdMandatoryFields?.length > 0 && renderConditionalLegalIds()}
                  {renderMandatoryLegalIds()}
                  {companyCountryCode === 'TH' && renderBranchCode()}
                  {optionalLegalIds.length > 0 && renderOptionalLegalIds()}
                </>
              )}
              <div className="row">
                {values.regnoCheckBox ? (
                  <div className="col-md-6 mt-4">
                    <div className="d-flex reg-not-present">
                      <FormikControl
                        control="checkbox"
                        name="registrationNotPresent"
                        ariaLabel={accessibility.label.DONT_HAVE_REGISTRATION_NO}
                        onChange={registrationNotPresentChange}
                        label={label.NO_RESGISTRATION_NUMBER}
                      />
                    </div>
                  </div>
                ) : null}
                <div className={`mt-4 retrieveBtn ${values.regnoCheckBox ? 'col-md-6' : 'col-md-12'}`}>
                  {!hideRetrieveCompanyDetailButton && (
                    <Button
                      className="px-4 py-2 btn-secondary-border"
                      id="retrieveBtn"
                      disabled={
                        !legalCodesFilled ||
                        values.registrationNotPresent ||
                        !companyDaoCountry ||
                        isEtl ||
                        errors.legalCodes ||
                        errors.branchCode
                      }
                      variant="secondary"
                      onClick={retrieveCompanyInfo}
                    >
                      {common.RETRIEVE_BTN}
                    </Button>
                  )}
                </div>
              </div>
              {!hideRetrieveCompanyDetailButton && (
                <Row className="field-row">
                  <Col className="dao-text-right">
                    <span className="mx-2">{label.WHAT_DOES_THIS_MEAN}</span>
                    <Tooltip
                      info={label.RETRIEVE_INFORMATION_TOOLTIP}
                      placement={
                        isMediumDevice
                          ? 'bottom'
                          : document.documentElement.dir === directionLayout.ltr
                          ? 'right'
                          : document.documentElement.dir === directionLayout.rtl
                          ? 'left'
                          : 'right'
                      }
                    />
                  </Col>
                </Row>
              )}
              {loadingValidateAddress && (
                <div className="py-3 company-details company-spinner">
                  <h5 className="spinner-title">{heading.RETRIEVE_COMPANY_INFORMATION}...</h5>
                  <img
                    className="spinner-img"
                    src={spinnerImg}
                    alt={spinner.IMG_ALT}
                  />
                </div>
              )}
            </Col>
          </Row>
        )}
        {!loadingValidateAddress && renderCompanyInfo()}
        <div className="row">
          <div className="col-md-6 mt-4 prevBtn order-sm-1 order-md-0">
            <span
              className="btn-back"
              role="button"
              onClick={onPrev}
              title={accessibility?.label?.BACK_TO_PREVIOUS_STEP}
            >
              <img
                src={chevronBackImg}
                className="icon-back"
                alt={common.ICON_BACK_ALT}
              />
              {common.LABEL_BTN_BACK}
            </span>
          </div>
          <div className="col-md-6 mt-4 nextBtn">
            <Button
              type="submit"
              aria-label={
                !formik.isValid ? accessibility?.label?.NEXT_BUTTON_DISABLED : accessibility?.label?.NEXT_BUTTON_ENABLED
              }
              disabled={!formik.isValid || !checkMandatoryDocument()}
              className="px-4 py-2"
            >
              {label.NEXT_BUTTON}
            </Button>
          </div>
        </div>
        <CountryModal
          show={showCountryAlert}
          changeCountry={handleCountryAlert}
          modal={modal}
        />
        <LegalIdModal
          show={showLegalAlert}
          legalIdCode={legalIdCode}
          changeNum={handleLegalAlert}
        />
        <LegalIdModal
          show={showConditionalLegalAlert}
          legalIdCode={conditionalLegalIdCode}
          changeNum={handleConditionalLegalAlert}
        />
        <RetrieveCompanyInfoModal
          show={showRetrieveCompanyAlert}
          retrieveCompanyInfoModal={handleRetrieveCompanyInfoModal}
        />
      </Form>
    </>
  );
};

export default CompanyPage;
