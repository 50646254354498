import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { useSelector } from 'react-redux';

import { FormikControl } from '../../common';
import { WEBSITE_PLACEHOLDER } from '../../../utils/constants';

const AdditionalInfo = () => {
  const { translations } = useSelector((state: any) => state.account);
  const {
    common: { select, dropDown },
    company: {
      heading,
      label,
      tooltip,
      accessibility,
      error,
      dropdowns: { companyIndustries },
    },
  } = translations;
  const { companyMandatoryFields, company, companyConfig } = useSelector((state: any) => state.company);
  const { removeCompanyIndustry } = companyConfig;
  const companyCountryCode = company.registeredCountry[0]?.code;

  const companyIndustryOptions = Array.isArray(companyIndustries)
    ? companyIndustries.map((industry) => ({
        code: industry,
        name: industry,
      }))
    : [];

  return (
    <>
      <Row className="field-row">
        <Col>
          <p className="address">{heading.ADDITIONAL_INFORMATION}</p>
        </Col>
      </Row>
      <Row className="field-row">
        <Col>
          <FormikControl
            control="input"
            name="companyWebsite"
            ariaLabel={accessibility.label.COMPANY_WEBSITE}
            type="text"
            label={label.COMPANY_WEBSITE}
            mandatoryLabel={companyMandatoryFields.includes('companyWebsite') ? '*' : ''}
            placeholder={WEBSITE_PLACEHOLDER}
            fieldInfo={{ info: `${companyCountryCode === 'US' ? label.COMPANY_WEBSITE_TOOLTIP : ''}` }}
            delayErrorMsg={true}
            mandatoryMsg={error.website.EMPTY}
          />
        </Col>
      </Row>
      {!removeCompanyIndustry && (
        <Row className="field-row">
          <Col>
            <FormikControl
              control="typeahead"
              name="companyIndustry"
              ariaLabel={accessibility.label.COMPANY_INDUSTRY}
              className="flag-label"
              label={label.COMPANY_INDUSTRY}
              mandatoryLabel={companyMandatoryFields.includes('companyIndustry') ? '*' : ''}
              options={companyIndustryOptions}
              emptyLabel={dropDown.EMPTY_LABEL}
              placeholder={select.INITIAL_VALUE}
              hideIcon={true}
              {...(tooltip?.COMPANY_INDUSTRY && { fieldInfo: { info: tooltip.COMPANY_INDUSTRY } })}
            />
          </Col>
        </Row>
      )}
    </>
  );
};

export default AdditionalInfo;
