import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { deviceTypes } from '../utils/constants';

export const useDeviceType = () => {
  const [deviceInfo, setDeviceInfo] = useState({
    type: deviceTypes.desktop,
    name: deviceTypes.unknown,
    osName: deviceTypes.unknown,
    osVersion: deviceTypes.unknown,
  });
  const { daoConfigAkamai } = useSelector((state: any) => state.account);

  const mobileTypeRegex = new RegExp(daoConfigAkamai?.deviceRegex?.mobile?.type, 'i');
  const mobileNameRegex = new RegExp(daoConfigAkamai?.deviceRegex?.mobile?.name, 'i');
  const tabletTypeRegex = new RegExp(daoConfigAkamai?.deviceRegex?.tablet?.type, 'i');
  const tabletNameRegex = new RegExp(daoConfigAkamai?.deviceRegex?.tablet?.name, 'i');
  const osVersionRegex = new RegExp(daoConfigAkamai?.deviceRegex?.osVersion, 'i');

  useEffect(() => {
    if (daoConfigAkamai?.deviceRegex?.mobile) {
      const userAgent = navigator.userAgent;
      const osMatch = userAgent.match(osVersionRegex);
      let deviceType = deviceTypes.desktop;
      let deviceName = deviceTypes.unknown;
      let osName: any = deviceTypes.unknown;
      let osVersion: any = deviceTypes.unknown;

      if (tabletTypeRegex.test(userAgent)) {
        deviceType = deviceTypes.tablet;
        const match = userAgent.match(tabletNameRegex);
        deviceName = match ? match[0] : deviceTypes.unknown;
      } else if (mobileTypeRegex.test(userAgent)) {
        deviceType = deviceTypes.mobile;
        const match = userAgent.match(mobileNameRegex);
        deviceName = match ? match[0] : deviceTypes.unknown;
      }

      if (osMatch) {
        if (osMatch.groups) {
          for (const [group, name] of Object.entries(daoConfigAkamai?.deviceRegex?.osName)) {
            if (osMatch.groups[group]) {
              osName = name;
              osVersion = osMatch[0].replace(/.*?([0-9_.]+).*/, '$1').replace(/_/g, '.');
              break;
            }
          }
        }
      }

      setDeviceInfo({ type: deviceType, name: deviceName, osName, osVersion });
    }
  }, [daoConfigAkamai]);

  return deviceInfo;
};
