import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import _, { replace } from 'lodash';

import './CorporateAccount.css';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import 'react-bootstrap-typeahead/css/Typeahead.bs5.css';

import { BasicModal, Stepper } from '../../common';
import CompanyContainer from '../Company/CompanyContainer';
import ContactContainer from '../Contact/ContactContainer';
import PaymentPage from '../Payment/PaymentPage';
import ReviewPage from '../Review/ReviewPage';
import ShippingPage from '../Shipping/ShippingPage';
import ThankYouPageContainer from '../ThankYou/ThankYouPageContainer';

import { getDaoConfigAkamai, getTranslationsAkamai } from '../../../store/actions/accountActions';
import { getCustomerInfo, getTimeoutSession, postUserTrackId, setAppProp } from '../../../store/actions/appActions';
import { setContactProp, validatePhoneNumber } from '../../../store/actions/contactActions';
import {
  getCountryConfig,
  getCountryRefData,
  getUserTrackId,
  generateTrackId,
  setCorporateAccountProp,
  getLandingPage,
  setThankYouPage,
  getIxopayDetails,
  sendCookieConsent,
} from '../../../store/actions/corporateAccountActions';
import { createAccount } from '../../../store/actions/daoActions';
import { getCustomerDetails } from '../../../store/actions/shippingActions';

import Account from './Account';
import { useMediaQuery } from '../../../hooks/useMediaQuery';
import {
  ixopayRedirectTransStatus,
  pageMapper,
  PROD_URL,
  thankyouPageType,
  TRACKING_KEY,
} from '../../../utils/constants';
import { convertMsToTime, modifyUrl, removeParams, storage } from '../../../utils/helper';
import { useDeviceType } from '../../../hooks/useDeviceType';

function CorporateAccount({ setShowLandingBtn, skipToSection, landingRef, mainRef, skipRef }) {
  const dispatch = useDispatch();
  const { appTimers, steps, customerInfoDhlPass, loadingCustomerInfo, consentCookie } = useSelector(
    (state: any) => state.app
  );
  const { defaultDisplayLanguage, hardTimeout, warnTimeout, leftOverTime, ixopayPageRedirect, dhlPass } = appTimers;
  const { translations, selectedLanguage, fontFamily } = useSelector((state: any) => state.account);
  const { contact } = useSelector((state: any) => state.contact);
  const {
    common: { timeout, corpFlow },
  } = translations;
  const { modal } = timeout;
  const {
    WARN_TIMEOUT_MESSAGE,
    HARD_TIMEOUT_TITLE,
    WARN_TIMEOUT_TITLE,
    WARN_HARD_TIMEOUT_BUTTON,
    HARD_TIMEOUT_MESSAGE,
  } = modal;
  const {
    TEXT_ERROR,
    TEXT_LOADING,
    CONTACT_DETAILS_STEP,
    COMPANY_DETAILS_STEP,
    REVIEW_DETAILS_STEP,
    SHIPPING_STEP,
    PAYMENT_METHOD_STEP,
  } = corpFlow;
  const {
    countryConfigData,
    countryData,
    thankYouPage,
    loading,
    userTrackId,
    currentPage,
    landingPage,
    isoCountriesListLocaleTranslation,
    ixopayDetails,
    loadingCountryConfig,
  } = useSelector((state: any) => state.corporateAccount);
  const { customerDetails } = useSelector((state: any) => state.shipping);
  const { submitPostData } = useSelector((state: any) => state.DAOReducer);

  const [searchParams] = useSearchParams();
  const url = new URL(window.location.href);
  const packageId = searchParams.get('package');
  const packageStatus = searchParams.get('status');
  const countryCode = searchParams.get('country')?.toUpperCase();
  const languageCode = searchParams.get('lang');
  const clientId = searchParams.get('clientid');
  const paramKeyNo = searchParams.get('p') ?? searchParams.get('P');
  const operationTypeValue = searchParams.get('test');
  const operationType = operationTypeValue?.toLowerCase() === 'true' ? 'D' : 'I';
  const showDaoThankYouPage = searchParams.get('showdaothankyoupage');
  const showDaoThankYouPageType = showDaoThankYouPage?.toLowerCase() === 'true' ? 'D' : null;

  const [flow, setFlow] = useState<any>();
  const [displayContent, setDisplayContent] = useState(false);
  const [showWarnTimeoutModal, setShowWarnTimeoutModal] = useState(false);
  const [showPaymentPage, setShowPaymentPage] = useState(false);
  const [showContactPage, setShowContactPage] = useState(false);
  const [showHardTimeoutModal, setShowHardTimeoutModal] = useState(false);
  const [startWarnTimerFromContact, setStartWarnTimerFromContact] = useState(false);

  const warnMessageTimeout = replace(WARN_TIMEOUT_MESSAGE, '{{LEFT_OVER_TIME}}', convertMsToTime(leftOverTime));
  const translationQuery = languageCode
    ? `${countryCode}/${countryCode}_${languageCode}.json?lngCd=${languageCode}`
    : `${defaultDisplayLanguage ? `${countryCode}/${countryCode}_${defaultDisplayLanguage}.json` : 'DEFAULT.json'}`;
  const isMobileDevice = useMediaQuery('only screen and (min-width : 320px) and (max-width : 767px)');
  const paramsPaymentStatus = searchParams.get('payment');
  const accountRequestId = searchParams.get('accrqid');
  const trackingId = searchParams.get('trkid');
  const dhlPassTrkid = searchParams.get('state');
  const dhlPassCode = searchParams.get('code');

  const alertUser = (event) => {
    event.preventDefault();
    event.returnValue = '';
  };

  const closeWarnTimeoutModal = () => {
    setShowWarnTimeoutModal(false);
    dispatch(
      getTimeoutSession({
        msg: 'WARN Timeout: Clicked',
      })
    );
  };

  const closeHardTimeoutModal = () => {
    window.location.reload();
  };

  const next = () => dispatch(setCorporateAccountProp('currentPage', currentPage + 1));
  const prev = () => dispatch(setCorporateAccountProp('currentPage', currentPage - 1));

  const setPage = (page) => {
    switch (page) {
      case 'landing':
        dispatch(setCorporateAccountProp('currentPage', -1));
        break;
      case 'contact':
        dispatch(setCorporateAccountProp('currentPage', 0));
        break;
      case 'company':
        dispatch(setCorporateAccountProp('currentPage', 1));
        break;
      case 'address':
        dispatch(setCorporateAccountProp('currentPage', 1));
        break;
      case 'review':
        dispatch(setCorporateAccountProp('currentPage', 2));
        break;
      case 'shipping':
        dispatch(setCorporateAccountProp('currentPage', 3));
        break;
      case 'payment':
        dispatch(setCorporateAccountProp('currentPage', 4));
        break;
    }
  };

  const openCorporateAccount = () => {
    setDisplayContent(true);

    if (dhlPass && !dhlPassCode && landingPage !== 'contact') {
      window.location.href = dhlPassLoginUrl;
      dispatch(setCorporateAccountProp('currentPage', -2));
    } else {
      dispatch(setCorporateAccountProp('currentPage', 0));
    }
  };

  const submitCreateAccount = () => {
    const { accountId } = submitPostData;
    const { accountRequestId } = ixopayDetails;
    const accountRequestIdData = {
      accountId: accountRequestId,
    };
    setFlow(thankyouPageType.dao);
    dispatch(setThankYouPage(true));
    if (paramsPaymentStatus === ixopayRedirectTransStatus.success) {
      dispatch(createAccount(accountRequestIdData));
      removeParams(['payment', 'lang', 'accrqid', 'retryct', 'trkid']);
    } else {
      dispatch(createAccount({ accountId }));
    }
  };

  const urlDomain = process.env.REACT_APP_BE_QL_URL;
  const modifiedUrlDomain = modifyUrl(urlDomain);
  const dhlPassLoginUrl = `https://uat.dhlpass.dhl.com/auth/oidc/authorize?response_type=code&client_id=dao&redirect_uri=${modifiedUrlDomain}/auth/finish/&state=${userTrackId}&CountryCode=${countryCode}&LangCode=${selectedLanguage}&country=${countryCode}&clientid=${clientId}&lang=${selectedLanguage}`;
  const { type, name, osName, osVersion } = useDeviceType();

  useEffect(() => {
    storage.set(TRACKING_KEY, userTrackId || trackingId || dhlPassTrkid);
    dispatch(setCorporateAccountProp('deviceType', type));
    dispatch(setCorporateAccountProp('deviceName', `${name}, ${osName} ${osVersion}`));
    if ((userTrackId && !paramsPaymentStatus) || !dhlPassTrkid) {
      if (currentPage === -1) {
        dispatch(
          postUserTrackId({
            trackingId: userTrackId || dhlPassTrkid,
            activityEndPage: pageMapper.landing,
            accReqId: '',
            cntryCd: countryCode,
            clientId,
            paramKey: paramKeyNo || null,
            opsTy: operationType || showDaoThankYouPageType,
            deviceType: type,
            deviceName: `${name}, ${osName} ${osVersion}`,
          })
        );
      }
    }
    dispatch(setCorporateAccountProp('dhlPassTrkId', dhlPassTrkid));
  }, [userTrackId, currentPage]);

  useEffect(() => {
    if (paramsPaymentStatus) {
      dispatch(getCountryConfig({ srcApp: clientId, ctryCd: countryCode }));
      dispatch(getIxopayDetails(accountRequestId));
      setShowPaymentPage(true);
    }
  }, []);

  useEffect(() => {
    if (userTrackId && countryCode && clientId && consentCookie && consentCookie !== 'undefined') {
      dispatch(
        sendCookieConsent({
          trackingId: userTrackId,
          content: {
            dhlCountryCd: countryCode,
            srcApp: clientId,
            cookieConsentValue: consentCookie,
          },
        })
      );
    }
  }, [consentCookie, userTrackId, countryCode, clientId]);

  useEffect(() => {
    if (
      !thankYouPage &&
      ((!ixopayPageRedirect && [0, 1, 2, 3, 4].includes(currentPage)) ||
        (ixopayPageRedirect && [0, 1, 2, 3].includes(currentPage)))
    ) {
      window.addEventListener('beforeunload', alertUser);

      return () => {
        window.removeEventListener('beforeunload', alertUser);
      };
    }
  }, [thankYouPage, currentPage]);

  useEffect(() => {
    if (showDaoThankYouPage?.toLowerCase() === 'true' && !url.hostname.includes(PROD_URL)) {
      dispatch(setCorporateAccountProp('currentPage', -2));
      dispatch(setCorporateAccountProp('thankYouPage', true));
    }
  }, [currentPage]);

  useEffect(() => {
    if (currentPage >= 0) setStartWarnTimerFromContact(true);
    if (currentPage === -1 || (thankYouPage ?? false)) {
      setShowLandingBtn(true);
    } else {
      setShowLandingBtn(false);
    }
  }, [currentPage, thankYouPage]);

  useEffect(() => {
    if (startWarnTimerFromContact && !thankYouPage && warnTimeout) {
      const warnTimer = setTimeout(() => {
        dispatch(
          getTimeoutSession({
            msg: 'WARN Timeout: Trigerred',
          })
        );
        setShowWarnTimeoutModal(true);
      }, warnTimeout);

      const hardTimer = setTimeout(() => {
        setShowWarnTimeoutModal(false);
        setShowHardTimeoutModal(true);
        dispatch(
          getTimeoutSession({
            msg: 'HARD Timeout',
          })
        );
      }, hardTimeout);

      return () => {
        clearTimeout(warnTimer);
        clearTimeout(hardTimer);
      };
    }
  }, [warnTimeout, startWarnTimerFromContact, thankYouPage]);

  useEffect(() => {
    if (packageId && packageStatus) {
      dispatch(getCustomerDetails(packageId));
      dispatch(getUserTrackId({ pckId: packageId }));
      setDisplayContent(true);
      setPage('shipping');
    } else if (countryCode && clientId && !paramsPaymentStatus) {
      !dhlPassCode && dispatch(generateTrackId());
      dispatch(getCountryConfig({ srcApp: clientId, ctryCd: countryCode }));
      dispatch(getCountryRefData({ refDatCatTyCd: 'COUNTRY' }));
      dispatch(
        getTimeoutSession({
          msg: 'Session start',
        })
      );
    }
  }, []);

  useEffect(() => {
    skipToSection(skipRef)();
  }, [currentPage]);

  useEffect(() => {
    if (countryConfigData?.ctryCfgs?.length > 0 && !CONTACT_DETAILS_STEP) {
      dispatch(getTranslationsAkamai(translationQuery));
      dispatch(getDaoConfigAkamai('dao-cfg.json'));
    }
  }, [countryConfigData]);

  useEffect(() => {
    if (dhlPassCode && _.isEmpty(customerInfoDhlPass)) {
      dispatch(getCustomerInfo({ code: dhlPassCode }));
      setShowContactPage(true);
    }
  }, []);

  useEffect(() => {
    if (showContactPage && CONTACT_DETAILS_STEP) {
      setDisplayContent(true);
      setPage('contact');
      setShowContactPage(false);
    }
  }, [currentPage]);

  useEffect(() => {
    if (showPaymentPage && CONTACT_DETAILS_STEP) {
      setDisplayContent(true);
      setPage('payment');
      setShowPaymentPage(false);
    }
  }, [translations]);

  useEffect(() => {
    if (CONTACT_DETAILS_STEP && !paramsPaymentStatus) {
      dispatch(getLandingPage({ clientId, parameter: paramKeyNo }));
    }
  }, [translations]);

  useEffect(() => {
    if (landingPage) {
      setDisplayContent(true);
      setPage(landingPage);

      if (dhlPass && !dhlPassCode && landingPage === 'contact') {
        window.location.href = dhlPassLoginUrl;
      }
    }
  }, [landingPage]);

  useEffect(() => {
    if (isoCountriesListLocaleTranslation && isoCountriesListLocaleTranslation.length > 0) {
      if (isoCountriesListLocaleTranslation.find((country) => country.code === countryCode) !== undefined) {
        const contactCountry = isoCountriesListLocaleTranslation.find((country) => country.code === countryCode);
        if (dhlPass && !_.isEmpty(customerInfoDhlPass)) {
          const dhlPassCountry = isoCountriesListLocaleTranslation.find(
            (country) => country.code === customerInfoDhlPass?.phoneCountryCode
          );
          dispatch(setContactProp('countryCode', [dhlPassCountry]));
          dispatch(setContactProp('email', customerInfoDhlPass?.email));
          dispatch(setContactProp('number', customerInfoDhlPass?.mobileNumber));
          dispatch(setContactProp('firstName', customerInfoDhlPass?.given_name));
          dispatch(setContactProp('lastName', customerInfoDhlPass?.family_name));
          if (customerInfoDhlPass?.mobileNumber) {
            const phoneNoReq = {
              phoneType: contact?.phoneType,
              phoneNumber: `+${customerInfoDhlPass?.countryCode}${customerInfoDhlPass?.mobileNumber}`,
              countryCode: `${customerInfoDhlPass?.phoneCountryCode}`,
            };
            dispatch(validatePhoneNumber(phoneNoReq));
          }
        } else {
          dispatch(setContactProp('countryCode', [contactCountry]));
        }
        dispatch(setCorporateAccountProp('country', [contactCountry]));
        dispatch(setAppProp('clientId', clientId));
      }
    }
  }, [isoCountriesListLocaleTranslation, customerInfoDhlPass]);

  return (
    <div
      ref={landingRef}
      tabIndex={-1}
      className="outline-0"
    >
      {currentPage === -1 && (
        <>
          <Account openCorporateAccount={openCorporateAccount} />
        </>
      )}
      {(loading && !displayContent && loadingCountryConfig) ||
        (dhlPassCode && loadingCustomerInfo) ||
        (currentPage === -2 && (
          <Container fluid="xxl">
            <Row>
              <Col
                xs={{ span: 10, offset: 1 }}
                className="py-5 content"
              >
                <div
                  className="spinner-border modal-process-text"
                  role="status"
                >
                  <span className="visually-hidden">{TEXT_LOADING}</span>
                </div>
              </Col>
            </Row>
          </Container>
        ))}
      {!loading && displayContent && (
        <div className="main-box position-relative">
          <Container fluid>
            {!thankYouPage && currentPage !== -1 && (
              <Row className="stepper-wrapper">
                {isMobileDevice && (
                  <div className="mobile-stepper">
                    {currentPage === 0 && <span>{CONTACT_DETAILS_STEP}</span>}
                    {currentPage === 1 && <span>{COMPANY_DETAILS_STEP}</span>}
                    {currentPage === 2 && <span>{REVIEW_DETAILS_STEP}</span>}
                    {currentPage === 3 && <span>{SHIPPING_STEP}</span>}
                    {currentPage === 4 && <span>{PAYMENT_METHOD_STEP}</span>}
                  </div>
                )}
                <Col
                  lg={12}
                  xl={11}
                  className="p-0 mx-auto"
                >
                  <Stepper
                    steps={steps}
                    activeStep={currentPage}
                    fontFamily={{ fontFamily: `${fontFamily || 'Delivery, Arial, sans-serif'}` }}
                  />
                </Col>
              </Row>
            )}
          </Container>
          <Container
            fluid="xxl"
            ref={mainRef}
            tabIndex={-1}
            className="outline-0"
          >
            {!thankYouPage && (
              <>
                <Row className="justify-content-xs-center dao-sub-contianer">
                  <Col
                    xs={12}
                    lg={8}
                    className="mx-auto mb-5"
                  >
                    {currentPage === 0 && (
                      <>
                        <ContactContainer nextPage={next} />
                      </>
                    )}
                    {currentPage === 1 && (
                      <>
                        <CompanyContainer
                          prev={prev}
                          nextPage={next}
                        />
                      </>
                    )}
                    {currentPage === 2 && (
                      <>
                        <ReviewPage
                          handlePage={setPage}
                          prevPage={prev}
                          setFlow={setFlow}
                        />
                      </>
                    )}
                    {currentPage === 3 && (
                      <>
                        <ShippingPage
                          nextPage={next}
                          setFlow={setFlow}
                          handlePage={setPage}
                          submitCreateAccount={submitCreateAccount}
                        />
                      </>
                    )}
                    {currentPage === 4 && (
                      <>
                        <PaymentPage
                          setFlow={setFlow}
                          handlePage={setPage}
                          submitCreateAccount={submitCreateAccount}
                        />
                      </>
                    )}
                  </Col>
                </Row>
              </>
            )}
          </Container>
          <Container fluid>{thankYouPage && <ThankYouPageContainer flow={flow} />}</Container>
        </div>
      )}
      {!loading && _.isEmpty(countryData) && !customerDetails.requestId && !ixopayDetails?.accountRequestId && (
        <Container fluid="xxl">
          <Row>
            <Col
              xs={{ span: 10, offset: 1 }}
              className="py-5 content"
            >
              <p>{TEXT_ERROR}</p>
            </Col>
          </Row>
        </Container>
      )}
      <BasicModal
        show={showWarnTimeoutModal}
        title={WARN_TIMEOUT_TITLE}
        content={warnMessageTimeout}
        action1Title={WARN_HARD_TIMEOUT_BUTTON}
        action1Handler={closeWarnTimeoutModal}
      />
      <BasicModal
        show={showHardTimeoutModal}
        title={HARD_TIMEOUT_TITLE}
        content={HARD_TIMEOUT_MESSAGE}
        action1Title={WARN_HARD_TIMEOUT_BUTTON}
        action1Handler={closeHardTimeoutModal}
      />
    </div>
  );
}
export default CorporateAccount;
