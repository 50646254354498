import _ from 'lodash';

export const getMappedFields = (mandatoryList, addrTypeMapName) => {
  const manAddrList: any = [];
  for (const [key, value] of Object.entries(addrTypeMapName)) {
    if (_.includes(mandatoryList, value)) {
      manAddrList.push(key);
    }
  }

  return manAddrList;
};

export const convertBase64toBlob = (dataURI) => {
  const byteCharacters = atob(dataURI);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);
  const file = new Blob([byteArray], { type: 'application/pdf;base64' });

  return file;
};

export const convertMsToTime = (milliseconds) => {
  const seconds = Math.floor(milliseconds / 1000);
  let minutes = Math.floor(seconds / 60);
  minutes = minutes % 60;

  return `${minutes}`;
};

export const storage = {
  get(key) {
    return window.localStorage.getItem(key);
  },
  set(key, value) {
    window.localStorage.setItem(key, value);
  },
  remove(key) {
    window.localStorage.removeItem(key);
  },
};

export const getFileSizeMB = (size: number): number => {
  return size / 1000 / 1000;
};

export const formatBytes = (bytes, decimals = 2) => {
  if (bytes === 0) return '0 Bytes';
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
};

export const checkType = (file: File, types: Array<string>): boolean => {
  const extension: string = file.name.split('.').pop() as string;
  const loweredTypes = types.map((type) => type.toLowerCase());

  return loweredTypes.includes(extension.toLowerCase());
};

export const acceptedExt = (types: Array<string> | undefined) => {
  if (types === undefined) return '';

  return types.map((type) => `.${type.toLowerCase()}`).join(',');
};

export const calculateStringMatchingPercentage = (string1, string2) => {
  const distance = calculateLevenshteinDistance(string1, string2);
  const maxLength = Math.max(string1.length, string2.length);
  const matchingPercentage = ((maxLength - distance) / maxLength) * 100;

  return matchingPercentage.toFixed(2); // Return percentage with 2 decimal places
};

export const calculateLevenshteinDistance = (string1, string2) => {
  const matrix = Array(string1.length + 1)
    .fill(null)
    .map(() => Array(string2.length + 1).fill(null));

  for (let i = 0; i <= string1.length; i++) {
    matrix[i][0] = i;
  }

  for (let j = 0; j <= string2.length; j++) {
    matrix[0][j] = j;
  }

  for (let i = 1; i <= string1.length; i++) {
    for (let j = 1; j <= string2.length; j++) {
      const cost = string1[i - 1] === string2[j - 1] ? 0 : 1;
      matrix[i][j] = Math.min(matrix[i - 1][j] + 1, matrix[i][j - 1] + 1, matrix[i - 1][j - 1] + cost);
    }
  }

  return matrix[string1.length][string2.length];
};

export const objectsEqual = (obj1, obj2) => {
  return (
    obj1?.addressLine1?.toLowerCase() === obj2['addressLine1'][0]?.toLowerCase() &&
    obj1?.addressLine2?.toLowerCase() === obj2['addressLine2']?.toLowerCase() &&
    obj1?.city?.toLowerCase() === obj2['city']?.toLowerCase() &&
    obj1?.zipCode?.toLowerCase() === obj2['zipCode']?.toLowerCase() &&
    obj1?.province?.toLowerCase() === obj2['province']?.toLowerCase() &&
    obj1?.state?.toLowerCase() === obj2['state']?.toLowerCase()
  );
};

export const trimFileName = (name) => {
  const maxLength = 100;

  return name.length > maxLength ? `${name.slice(0, maxLength)}...` : name;
};

export const removeParams = (paramNames) => {
  const url = new URL(window.location.href);
  const searchParams = url.searchParams;

  paramNames.forEach((paramName) => {
    searchParams.delete(paramName);
  });

  window.history.replaceState(null, '', url.toString());
};

export const modifyUrl = (url) => {
  if (url.includes('be-ql')) {
    url = url.replace('be-ql', 'fe');
  }

  const index = url.indexOf('.com');
  if (index !== -1) {
    url = url.substring(0, index + 4);
  }

  return url;
};
