import React, { useEffect, useMemo, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from 'formik';
import { debounce } from 'lodash';
import { useSearchParams } from 'react-router-dom';

import { Button } from '../../common';
import FormikControl from '../../common/FormikControl/FormikControl';
import { getCountryConfig } from '../../../store/actions/corporateAccountActions';
import { setContact, setContactProp, validatePhoneNumber } from '../../../store/actions/contactActions';
import { numberRegex } from '../../../utils/regEx';
import {
  DEFAULT_COMPANY_FORM,
  DEFAULT_COMPANY_INFORMATION,
  DEFAULT_CONTACT_FORM,
  FIRST_NAME_PLACEHOLDER,
  LAST_NAME_PLACEHOLDER,
  EMAIL_PLACEHOLDER,
  PHONE_CODE_PLACEHOLDER,
  PHONE_NUMBER_PLACEHOLDER,
  PHONE_EXTENSION_PLACEHOLDER,
  JOB_PLACEHOLDER,
  pageMapper,
} from '../../../utils/constants';
import { setCompany } from '../../../store/actions/companyActions';
import CountryModal from '../Company/CountryModal';
import { postUserTrackId } from '../../../store/actions/appActions';
import { setDaoProp } from '../../../store/actions/daoActions';
import './Contact.css';
import { setAccountProp } from '../../../store/actions/accountActions';

const ContactPage = ({ formik }) => {
  const { values, setFieldValue, setFieldTouched, resetForm } = formik;
  const dispatch = useDispatch<any>();
  const [showCountryModal, setShowCountryModal] = useState(false);

  const { clientId, customerInfoDhlPass } = useSelector((state: any) => state.app);
  const {
    userTrackId,
    country,
    contactBreRegex,
    isoCountriesListLocaleTranslation,
    dhlPassTrkId,
    landingPage,
    deviceType,
    deviceName,
  } = useSelector((state: any) => state.corporateAccount);
  const { emailBreRegexValue } = contactBreRegex;
  const {
    contact,
    validatePhone,
    contactMandatoryFields,
    preferredLanguages,
    preferredCommunicationMethods,
    contactFields,
  } = useSelector((state: any) => state.contact);
  const { hideSalutationField, showConsent } = contactFields;
  const countryCode = contact.country[0]?.code;
  const { phoneNumberValidating, validPhone, e164NoFmt } = validatePhone;
  const { translations, daoConfigAkamai } = useSelector((state: any) => state.account);
  const {
    common: { select, dropDown },
    contact: {
      label,
      tooltip,
      DEPARTMENTS,
      SALUTATIONS,
      phoneTypes,
      modal,
      accessibility,
      preferredLanguageTooltip,
      comm,
      heading,
      error,
    },
    shipping: { doclang },
    review: { common },
  } = translations;
  const [searchParams] = useSearchParams();
  const paramsCountryCode = searchParams.get('country')?.toUpperCase();
  const paramsClientId = searchParams.get('clientid');
  const paramKeyNo = searchParams.get('p') ?? searchParams.get('P');
  const operationTypeValue = searchParams.get('test');
  const operationType = operationTypeValue?.toLowerCase() === 'true' ? 'D' : 'I';
  const showDaoThankYouPage = searchParams.get('showdaothankyoupage');
  const showDaoThankYouPageType = showDaoThankYouPage?.toLowerCase() === 'true' ? 'D' : null;

  const { bullets } = preferredLanguageTooltip;
  const { method } = comm;
  const skipPhoneValidation = daoConfigAkamai && daoConfigAkamai['skp-ph-vald']?.includes(values.countryCode[0]?.code);

  const localDepartment = DEPARTMENTS ? DEPARTMENTS?.split(',') : [];
  const localSalutation = SALUTATIONS ? SALUTATIONS?.split(',') : [];

  const departmentOptions = ['', ...localDepartment].map((department) => {
    return {
      value: department,
      label: department,
    };
  });
  const salutationOptions = ['', ...localSalutation].map((salutation) => {
    return {
      value: salutation,
      label: salutation,
    };
  });

  const phoneTypesOptions = Object.entries(phoneTypes).map(([value, label]) => ({
    value,
    label,
  }));

  const commMethodOptions = preferredCommunicationMethods.map((commMethod) => ({
    value: commMethod,
    ...(method[commMethod] && { label: method[commMethod] }),
  }));

  useEffect(() => {
    if (!countryCode) {
      dispatch(setContactProp('country', country));
      setFieldValue('country', country);
    }
  }, [country]);

  useEffect(() => {
    if (userTrackId || dhlPassTrkId) {
      dispatch(
        postUserTrackId({
          trackingId: userTrackId || dhlPassTrkId,
          activityEndPage: pageMapper.contact,
          accReqId: '',
          cntryCd: paramsCountryCode,
          ...(landingPage === 'contact' || dhlPassTrkId
            ? {
                clientId: paramsClientId,
                paramKey: paramKeyNo || null,
                opsTy: operationType || showDaoThankYouPageType,
                deviceName,
                deviceType,
              }
            : {}),
        })
      );
      if (customerInfoDhlPass?.given_name) {
        setTimeout(() => setFieldTouched('firstName', true));
      }
      if (customerInfoDhlPass?.family_name) {
        setTimeout(() => setFieldTouched('lastName', true));
      }
    }
  }, []);

  const phoneTypeChangeHandler = (e) => {
    setFieldValue('countryCode', values.countryCode, false);
    setFieldValue('number', values.number, false);
    setFieldValue('ext', '', false);
    setFieldTouched('number', true, true);
    numberHandler(e.target.value, values.countryCode, values.number);
  };

  const countryCodeChangeHandler = () => {
    setFieldValue('number', '', false);
    setFieldTouched('number', false);
    setTimeout(() => setFieldTouched('countryCode', true));
  };

  const commPrefLangOptions = preferredLanguages.map((commLang) => ({
    value: commLang.label,
    label: doclang[commLang.label.toUpperCase()],
  }));

  const isValidNumber = (number) => {
    return number && number.length <= 15 && numberRegex.test(number);
  };

  useEffect(() => {
    setFieldValue('validPhone', validPhone);
    setFieldValue('e164NoFmt', e164NoFmt);
  }, [validPhone]);

  const changeCountry = (value) => {
    if (value) {
      DEFAULT_CONTACT_FORM.country = DEFAULT_CONTACT_FORM.countryCode = values.country;
      resetForm({ values: DEFAULT_CONTACT_FORM });
      dispatch(setContact(DEFAULT_CONTACT_FORM));
      dispatch(setDaoProp('companyInformation', DEFAULT_COMPANY_INFORMATION));
      dispatch(setCompany(DEFAULT_COMPANY_FORM));
      dispatch(setContactProp('country', values.country));
      dispatch(setContactProp('countryCode', values.country));
      dispatch(getCountryConfig({ ctryCd: values.country[0]?.code, srcApp: clientId }));
    } else {
      setFieldValue('country', contact.country);
    }
    setShowCountryModal(false);
  };

  const numberHandler = (phoneType, countryCode, number) => {
    if (isValidNumber(number) && !skipPhoneValidation) {
      const req = {
        phoneType,
        phoneNumber: `${countryCode[0]?.callCode}${number}`,
        countryCode: countryCode[0]?.code,
      };
      dispatch(validatePhoneNumber(req));
    }
  };

  const debouncedNumberHandler = useMemo(() => debounce(numberHandler, 800, { leading: true }), []);

  const preferredLangChangeHandler = (e) => {
    const preferredLanguage = e.target.value;
    const filteredLang = preferredLanguages.find((lang) => lang.label === preferredLanguage);
    dispatch(setAccountProp('altLanguageCode', filteredLang?.value));
  };

  const getFieldInfo = () => {
    return (
      <>
        <span dangerouslySetInnerHTML={{ __html: preferredLanguageTooltip.desc }} />
        <ul>
          {Object.keys(bullets).map((item) => {
            return (
              <li key={item}>
                <span dangerouslySetInnerHTML={{ __html: bullets[item] }} />
              </li>
            );
          })}
        </ul>
      </>
    );
  };

  const isSubmitDisable = () => {
    if (showConsent) {
      if (values.acceptTermsAndConditions) {
        return false;
      }

      return true;
    }
  };

  return (
    <>
      <Form>
        <div className="row">
          <div className="col-md-12 shadow rounded bg-white form-content">
            <div className="row">
              {!hideSalutationField && (
                <div className="col-md-2 mb-4">
                  <FormikControl
                    control="select"
                    name="title"
                    ariaLabel={accessibility.label.TITLE}
                    label={label.TITLE}
                    mandatoryLabel={contactMandatoryFields.includes('title') ? '*' : ''}
                    placeholder={select.INITIAL_VALUE}
                    options={salutationOptions}
                    {...(tooltip?.TITLE && { fieldInfo: { info: tooltip.TITLE } })}
                  />
                </div>
              )}
              <div className={`mb-4 ${!hideSalutationField ? 'col-md-5' : 'col-md-6'}`}>
                <FormikControl
                  control="input"
                  name="firstName"
                  type="text"
                  ariaLabel={accessibility.label.FIRST_NAME}
                  label={label.FIRST_NAME}
                  mandatoryLabel={contactMandatoryFields.includes('firstName') ? '*' : ''}
                  placeholder={FIRST_NAME_PLACEHOLDER}
                  {...(tooltip?.FIRST_NAME && { fieldInfo: { info: tooltip.FIRST_NAME } })}
                />
              </div>
              <div className={`mb-4 ${!hideSalutationField ? 'col-md-5' : 'col-md-6'}`}>
                <FormikControl
                  control="input"
                  name="lastName"
                  type="text"
                  ariaLabel={accessibility.label.LAST_NAME}
                  label={label.LAST_NAME}
                  mandatoryLabel={contactMandatoryFields.includes('lastName') ? '*' : ''}
                  placeholder={LAST_NAME_PLACEHOLDER}
                  {...(tooltip?.LAST_NAME && { fieldInfo: { info: tooltip.LAST_NAME } })}
                />
              </div>
            </div>
            {heading.FIRST_LAST_NAME_CHAR_LIMIT_MSG && (
              <div className="row mb-4">
                <div className="col-md-12">
                  <Row className="field-row">
                    <Col>
                      <div
                        className="banner-msg"
                        dangerouslySetInnerHTML={{
                          __html: heading.FIRST_LAST_NAME_CHAR_LIMIT_MSG,
                        }}
                      ></div>
                    </Col>
                  </Row>
                </div>
              </div>
            )}
            <div className="row">
              <div className="col-md-12 mb-4">
                <FormikControl
                  control="input"
                  name="email"
                  type={emailBreRegexValue ? '' : 'email'}
                  ariaLabel={accessibility.label.EMAIL}
                  label={label.EMAIL}
                  mandatoryLabel={contactMandatoryFields.includes('email') ? '*' : ''}
                  placeholder={EMAIL_PLACEHOLDER}
                  disabled={customerInfoDhlPass?.email}
                  checkMark={!formik.errors.email}
                  delayErrorMsg={true}
                  mandatoryMsg={error.email.EMPTY}
                  {...(tooltip?.EMAIL && { fieldInfo: { info: tooltip.EMAIL } })}
                />
              </div>
            </div>
            {heading.DHL_PASS_EMAIL_MSG && (
              <div className="row mb-4">
                <div className="col-md-12">
                  <Row className="field-row">
                    <Col>
                      <div
                        className="banner-msg"
                        dangerouslySetInnerHTML={{
                          __html: heading.DHL_PASS_EMAIL_MSG,
                        }}
                      ></div>
                    </Col>
                  </Row>
                </div>
              </div>
            )}
            <div className="row">
              <div className="col-md-3 mb-4">
                <FormikControl
                  control="select"
                  name="phoneType"
                  ariaLabel={accessibility.label.PHONE_TYPE}
                  label={label.PHONE_TYPE}
                  placeholder={select.INITIAL_VALUE}
                  options={phoneTypesOptions}
                  onChange={phoneTypeChangeHandler}
                  {...(tooltip?.PHONE_TYPE && { fieldInfo: { info: tooltip.PHONE_TYPE } })}
                />
              </div>
              <div className="col-md-3 mb-4">
                <FormikControl
                  control="typeahead"
                  name="countryCode"
                  className="flag-label country-code"
                  ariaLabel={accessibility.label.PHONE_CODE}
                  label={label.PHONE_CODE}
                  mandatoryLabel={contactMandatoryFields.includes(phoneTypesOptions[0].value.toLowerCase()) ? '*' : ''}
                  emptyLabel={dropDown.EMPTY_LABEL}
                  labelKey="callCode"
                  options={isoCountriesListLocaleTranslation}
                  placeholder={PHONE_CODE_PLACEHOLDER}
                  onChange={countryCodeChangeHandler}
                  filterBy={(option, props) => {
                    if (props.selected.length) {
                      return true;
                    }

                    return ['name', 'callCode', 'code'].some(
                      (key) => option[key].toLowerCase().indexOf(props.text.toLowerCase()) !== -1
                    );
                  }}
                  menuItemChildren={(option: any) => {
                    return (
                      <>
                        <div className="typeahead-menu-item">
                          <span className={`fi fi-${option.code.toLowerCase()} typeahead-menu-flag`}></span>
                          <span>
                            {option.name} {option.callCode}
                          </span>
                        </div>
                      </>
                    );
                  }}
                  {...(tooltip?.PHONE_CODE && { fieldInfo: { info: tooltip.PHONE_CODE } })}
                />
              </div>
              <div className={`mb-4 ${formik.values.phoneType === 'Office' ? 'col-md-4' : 'col-md-6'}`}>
                <FormikControl
                  control="input"
                  name="number"
                  type="text"
                  ariaLabel={accessibility.label.PHONE_NUMBER}
                  label={label.PHONE_NUMBER}
                  mandatoryLabel={contactMandatoryFields.includes(phoneTypesOptions[0].value.toLowerCase()) ? '*' : ''}
                  placeholder={PHONE_NUMBER_PLACEHOLDER}
                  checkMark={validPhone}
                  loading={phoneNumberValidating}
                  onChange={(e) => {
                    debouncedNumberHandler(values.phoneType, values.countryCode, e.target.value);
                  }}
                  delayErrorMsg={false}
                  mandatoryMsg={error.phone_number.EMPTY}
                  {...(tooltip?.PHONE_NUMBER && { fieldInfo: { info: tooltip.PHONE_NUMBER } })}
                />
              </div>
              {formik.values.phoneType === 'Office' ? (
                <div className="col-md-2 mb-4">
                  <FormikControl
                    control="input"
                    name="ext"
                    type="text"
                    ariaLabel={accessibility.label.PHONE_EXT}
                    label={label.PHONE_EXT}
                    mandatoryLabel={contactMandatoryFields.includes('ext') ? '*' : ''}
                    placeholder={PHONE_EXTENSION_PLACEHOLDER}
                    {...(tooltip?.PHONE_EXT && { fieldInfo: { info: tooltip.PHONE_EXT } })}
                  />
                </div>
              ) : null}
            </div>
            <div className="row">
              <div className="col-md-6 mb-4">
                <FormikControl
                  control="input"
                  name="job"
                  type="text"
                  ariaLabel={accessibility.label.JOB_TITLE}
                  label={label.JOB}
                  mandatoryLabel={contactMandatoryFields.includes('job') ? '*' : ''}
                  placeholder={JOB_PLACEHOLDER}
                  {...(tooltip?.JOB && { fieldInfo: { info: tooltip.JOB } })}
                />
              </div>
              <div className="col-md-6 mb-4">
                <FormikControl
                  control="select"
                  name="department"
                  ariaLabel={accessibility.label.DEPARTMENT}
                  label={label.DEPARTMENT}
                  mandatoryLabel={contactMandatoryFields.includes('department') ? '*' : ''}
                  placeholder={select.INITIAL_VALUE}
                  options={departmentOptions}
                  {...(tooltip?.DEPARTMENT && { fieldInfo: { info: tooltip.DEPARTMENT } })}
                />
              </div>
            </div>
            {preferredLanguages.length > 1 && (
              <div className="row">
                <div className="col-md-12 mb-4">
                  <FormikControl
                    control="select"
                    name="preferredLanguage"
                    ariaLabel={accessibility.label.PREFERRED_LANGUAGE}
                    label={label.PREFERRED_LANGUAGE}
                    placeholder={select.INITIAL_VALUE}
                    options={commPrefLangOptions}
                    onChange={preferredLangChangeHandler}
                    {...(preferredLanguageTooltip?.desc && { fieldInfo: { info: getFieldInfo() } })}
                  />
                </div>
              </div>
            )}
            {preferredCommunicationMethods.length > 1 && (
              <div className="row">
                <div className="col-md-12">
                  <FormikControl
                    control="select"
                    name="preferredCommunicationMethod"
                    ariaLabel={accessibility.label.PREFERRED_COMM_METHOD}
                    label={label.PREFERRED_COMM_METHOD}
                    mandatoryLabel={contactMandatoryFields.includes('prefCommMthd') ? '*' : ''}
                    placeholder={select.INITIAL_VALUE}
                    options={commMethodOptions}
                    {...(tooltip?.PREFERRED_COMM_METHOD && { fieldInfo: { info: tooltip.PREFERRED_COMM_METHOD } })}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
        {showConsent && (
          <>
            <div className="row mt-4">
              <div className="p-0 d-flex">
                <FormikControl
                  control="checkbox"
                  name="acceptTermsAndConditions"
                  ariaLabel={common.CHECK_TNC_ALT}
                  label={common.LABEL_TNC}
                  mandatoryLabel="*"
                />
              </div>
            </div>
            <div className="row mt-4">
              <div className="p-0 d-flex">
                <FormikControl
                  control="checkbox"
                  name="receiveNewsAndUpdates"
                  ariaLabel={common.CHECK_NND_ALT}
                  label={common.LABEL_NND}
                />
              </div>
            </div>
          </>
        )}
        <Row className="py-4">
          <Col className="text-end p-0 dao-mobile-next-btn">
            <Button
              type="submit"
              aria-label={
                !formik.isValid ? accessibility?.label?.NEXT_BUTTON_DISABLED : accessibility?.label?.NEXT_BUTTON_ENABLED
              }
              disabled={!formik.isValid || phoneNumberValidating || isSubmitDisable()}
              className="px-4 py-2"
            >
              {label.NEXT_BUTTON}
            </Button>
          </Col>
        </Row>
        <CountryModal
          show={showCountryModal}
          changeCountry={changeCountry}
          modal={modal}
        />
      </Form>
    </>
  );
};

export default ContactPage;
