import { contactMapper } from '../../utils/constants';
import { getMappedFields } from '../../utils/helper';
import {
  SET_CONTACT,
  SET_CONTACT_PROP,
  SET_CONTACT_KEY,
  SET_VALIDATE_PHONE,
  VALIDATE_MY_BILL,
  VALIDATE_MY_BILL_SUCCESS,
  VALIDATE_PHONE_NUMBER,
  SET_VALIDATE_MY_BILL_ERROR,
} from './actionTypes';

export const setContact = (contact) => {
  return {
    type: SET_CONTACT,
    payload: contact,
  };
};

export const setContactProp = (key, value) => {
  return {
    type: SET_CONTACT_PROP,
    payload: { key, value },
  };
};

export const setValidatePhone = (key, value) => {
  return {
    type: SET_VALIDATE_PHONE,
    payload: { key, value },
  };
};

export const setContactFieldRules = (configurations) => {
  let mandatoryList = [];
  if (configurations.ctryRules.rules) {
    mandatoryList = configurations.ctryRules.rules
      .filter((e) => {
        return e.mandatoryInd === 'Y';
      })
      .map((e) => {
        return e.attributeName;
      });
  }
  const contactMandatoryFields = getMappedFields(mandatoryList, contactMapper);

  return {
    type: SET_CONTACT_KEY,
    payload: { key: 'contactMandatoryFields', value: contactMandatoryFields },
  };
};

export const setPreferredLanguages = (configurations) => {
  const preferredLanguages: any = [];

  configurations.ctryCfgs.forEach((ctryCfg) => {
    if (ctryCfg.typeCd === 'COMM_LANGUAGE') {
      preferredLanguages.push({
        value: ctryCfg.value,
        label: ctryCfg.langDescription,
      });
    }
  });

  return {
    type: SET_CONTACT_KEY,
    payload: { key: 'preferredLanguages', value: preferredLanguages },
  };
};

export const setPreferredCommunicationMethods = (configurations) => {
  const preferredCommunicationMethods: any = [''];

  configurations.ctryCfgs.forEach((ctryCfg) => {
    if (ctryCfg.typeCd === 'COMM_METHOD') {
      preferredCommunicationMethods.push(ctryCfg.value);
    }
  });

  return {
    type: SET_CONTACT_KEY,
    payload: { key: 'preferredCommunicationMethods', value: preferredCommunicationMethods },
  };
};

export const validateMyBill = (request) => {
  return {
    type: VALIDATE_MY_BILL,
    payload: request,
  };
};

export const validateMyBillSuccess = (response) => {
  return {
    type: VALIDATE_MY_BILL_SUCCESS,
    payload: response,
  };
};

export const validatePhoneNumber = (request) => {
  return {
    type: VALIDATE_PHONE_NUMBER,
    payload: request,
  };
};

export const setValidateMyBillError = (error) => {
  const validateMyBillHttpStatus = error?.response?.status;

  return {
    type: SET_VALIDATE_MY_BILL_ERROR,
    payload: validateMyBillHttpStatus,
  };
};

export const setContactFields = (configurations) => {
  const hideSalutationField =
    configurations?.ctryCfgs?.find((ctryCfg) => ctryCfg.cfgCatType === 'HIDE_FIELD')?.value === 'Y';
  const showConsent = configurations?.ctryCfgs?.find((ctryCfg) => ctryCfg.typeCd === 'SHOW_IN_CNT')?.value === 'Y';

  const ixopayCurrency = configurations?.ctryCfgs?.find((ctryCfg) => ctryCfg.typeCd === 'IXOPAY_CURRENCY')?.value;

  return {
    type: SET_CONTACT_KEY,
    payload: { key: 'contactFields', value: { hideSalutationField, ixopayCurrency, showConsent } },
  };
};

export const setContactKeyProp = (key, value) => {
  return {
    type: SET_CONTACT_KEY,
    payload: { key, value },
  };
};
