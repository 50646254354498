export const GET_COUNTRY_REF_DATA = '/api/v113/reference';
export const GET_COUNTRY_CONFIG = '/api/v113/country/config';
export const GET_DATA_MAINTAINANCE_FIELDS = '/api/v113/businessrule/reference';
export const VALIDATE_MY_BILL = '/api/v113/mybill/validate';
export const VALIDATE_PHONE = '/api/v113/validatephone';
export const GET_ADDRESS_SUGGESTIONS = '/api/v113/address/suggest';
export const GET_ADDRESS_DETAILS = '/api/v113/address/detail';
export const GET_VALIDATED_ADDRESS_DETAILS = '/api/v113/registration/validate';
export const SUBMIT_CUSTOMER = '/api/v2/summary';
export const GET_SIGNED_DOC = '/api/v1/esign/download';
export const GET_CUSTOMER_DETAILS = '/api/v1/account';
export const GET_DEBIT = '/api/v1/payment/register';
export const CREATE_ACCOUNT = '/api/v2/account'; // URL is same as GET_CUSTOMER_DETAILS but with POST action.
export const GET_BRE_BUSINESS_RULE_VALIDATE = '/api/v1/businessrule/validate';
export const GET_PAYMENT_PROFILE = '/api/v1/payment/profile';
export const GET_PAYMENT_STATUS = '/api/v1/payment/status';
export const GET_TRANSLATIONS_AKAMAI = '/assets/translation/ui';
export const GET_SUMMARY_STATUS = '/api/v1/summary/status';
export const GET_SHIPPING_RATES_SERVICE_DOCS = '/api/v1/documents';
export const GET_SAVE_CONSENT = '/api/v2/saveconsent';
export const GET_CUSTOMER_DETAILS_FLOW_TWO = '/api/v2/account';
export const GENERATE_TRACK_ID = '/api/v1/usertrackid';
export const GET_USER_ACTIVITY = '/api/v1/useractivity';
export const PATCH_ACCOUNTS = '/api/v1/patchaccounts';
export const GET_USER_TRACK_ID = '/api/v1/trackingid';
export const SET_PAYMENT_LOADOUT = '/api/v1/payment/loadout';
export const GET_LANGUAGE_CODE = '/api/v113/language/code';
export const GET_DOCS_REGENERATE = '/api/v1/documents/regenerate';
export const GET_DOC_UPLOAD = '/api/v1/company/upload';
export const GET_DOC_DELETE = '/api/v1/company/deleteFile';
export const GET_LANDING_PAGE = '/api/v113/clientconfig';
export const GET_DAO_CONFIG_AKAMAI = '/assets/config';
export const GET_PAYMENT_METHOD_TYPE = '/api/v2/updatepaymtmth';
export const GET_IXOPAY_DETAILS = '/api/v1/getIxopayResponse';
export const GET_TIMEOUT_SESSION = '/api/v1/ui/log';
export const GET_CUSTOMER_INFO = '/api/v1/dhlpass/token/validate';
export const SEND_COOKIE_CONSENT = '/api/v2/cookie/cons';
