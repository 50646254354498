import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Row } from 'react-bootstrap';
import { replace } from 'lodash';
import { useSearchParams } from 'react-router-dom';

import {
  getDebit,
  getPaymentProfile,
  getPaymentStatus,
  setIxopayRidirect,
  setPaymentEtlList,
  setPaymentProp,
  setPaymentLoadout,
  getPaymentMethodType,
} from '../../../store/actions/paymentActions';
import { setThankYouPage } from '../../../store/actions/corporateAccountActions';
import { Button, BasicModal, BasicModal2, Spinner } from '../../common';
import {
  conventionalThankyouSubType,
  daoSteps,
  flowOneSteps,
  ixopayRedirectTransStatus,
  MAX_RETRY_COUNT,
  nonDaoSteps,
  pageMapper,
  paymentExitSteps,
  paymentExitTypes,
  paymentTransactionStatus,
} from '../../../utils/constants';
import AddLaterModal from './AddLaterModal';
import './PaymentPage.css';
import { postUserTrackId, setAppProp, setEtlList } from '../../../store/actions/appActions';
import { Etl } from '../../../utils/types';
import { patchAccActivity } from '../../../store/actions/shippingActions';
import { removeParams, storage } from '../../../utils/helper';
import { useMediaQuery } from '../../../hooks/useMediaQuery';
import { getBreInfo } from '../../../store/actions/companyActions';
import { useTranslationSteps } from '../../../hooks/useTranslationSteps';

function PaymentPage(props) {
  const { etlList, appTimers, clientId } = useSelector((state: any) => state.app);
  const { userTrackId, ixopayDetails, dhlPassTrkId } = useSelector((state: any) => state.corporateAccount);
  const { customer, accountRequestId, uuid } = ixopayDetails;
  const { loadingSubmitData, submitPostData, breDetails } = useSelector((state: any) => state.DAOReducer);
  const { paymentIxopayIframeTimeout, paymentExitSubType, ixopayPageRedirect } = appTimers;
  const { contact, contactFields } = useSelector((state: any) => state.contact);
  const { country, email, firstName, lastName } = contact;
  const { company } = useSelector((state: any) => state.company);
  const { companyName, legalCodes } = company;
  const { selectedLanguage } = useSelector((state: any) => state.account);
  const dispatch = useDispatch<any>();
  const {
    debit,
    loadingDebit,
    loadingProfileSucess,
    paymentProfile,
    paymentStatus,
    cardDetails,
    ixopayIframe,
    loadingIframe,
    paymentCard,
    paymentError,
    debitError,
    paymentProfileCode,
    iframeRetryCount,
    transactionRetryCount,
    timeoutRetryCount,
    iframeTimeoutCount,
  } = useSelector((state: any) => state.payment);
  const { paymentInstruments } = paymentProfile;
  const { redirectUrl } = debit;
  const { translations } = useSelector((state: any) => state.account);
  const { payment, accessibility, IXOPAY_IFRAME_HEIGHT, IXOPAY_MOBILE_IFRAME_HEIGHT } = translations;
  const { errorCode } = paymentStatus;

  const [showAddLaterModal, setShowAddLaterModal] = useState(false);
  const [showIXOIframeRetryModal, setShowIXOIframeRetryModal] = useState(false);
  const [showTransactionRetryModal, setShowTransactionRetryModal] = useState(false);
  const [showTimeoutRetryModal, setShowTimeoutRetryModal] = useState(false);
  const [addLaterClick, setAddLaterClick] = useState(false);
  const [showExitModal, setShowExitModal] = useState(false);
  const [showExitTransactionErrorModal, setShowExitTransactionErrorModal] = useState(false);
  const [showIframeTimeoutModal, setShowIframeTimeoutModal] = useState(false);
  const closeAddLaterModal = () => setShowAddLaterModal(false);
  const clickToShowAddLaterModal = () => {
    setAddLaterClick(true);
    if (paymentExitTypes.includes(paymentExitSubType)) {
      const savePaymentMethodType = {
        accReqId: submitPostData.accountId,
        paymtMth: paymentExitSubType,
      };
      dispatch(getPaymentMethodType(savePaymentMethodType));
      dispatch(getBreInfo(legalCodes));
    } else {
      setShowAddLaterModal(true);
    }
  };
  const paymentStatusIntervalRef = useRef<any>();
  const isMobileDevice = useMediaQuery('only screen and (min-width : 320px) and (max-width : 767px)');
  const [searchParams] = useSearchParams();
  const paramsPaymentStatus = searchParams.get('payment');
  const clientIdName = searchParams.get('clientid');
  const billingCountryCode = searchParams.get('country');
  const langName = searchParams.get('lang');
  const retryCount = searchParams.get('retryct');
  const rtyCnt: number | null = retryCount !== null ? parseInt(retryCount, 10) : null;
  const [translatedDaoSteps] = useTranslationSteps(daoSteps, nonDaoSteps, flowOneSteps, paymentExitSteps);

  useEffect(() => {
    if (paymentExitTypes.includes(paymentExitSubType) && breDetails && addLaterClick) {
      const fldErrs = breDetails?.breRes?.fldErrs;
      const noBillingTermsFromBre = !!fldErrs?.find((error) => error?.ErrMsgDtl?.Sev === 40);

      if (noBillingTermsFromBre) {
        props.setFlow(conventionalThankyouSubType.sorry);
        dispatch(setThankYouPage(true));
        const saveAccActivityData = {
          accRqId: submitPostData.accountId,
          data: {
            rejectionReasonCode: '2006',
            requestId: submitPostData.accountId,
            conventionalIndicator: 'Y',
            conventionalStatus: 'PENDING',
          },
        };
        dispatch(patchAccActivity(saveAccActivityData));
      } else if (fldErrs === null) {
        props.submitCreateAccount();
      }
    }
  }, [breDetails]);

  useEffect(() => {
    if (paymentCard && paymentProfile.success && cardDetails && cardDetails[0]?.paymentData) {
      props.submitCreateAccount();
    }
  }, [paymentCard, paymentProfile.success, cardDetails]);

  useEffect(() => {
    if (debit.success && !ixopayPageRedirect) {
      dispatch(setPaymentProp('iframeRetryCount', 0));
      dispatch(setIxopayRidirect(redirectUrl));
      paymentStatusIntervalRef.current = setInterval(() => {
        const paymentPollingStatus = storage.get('paymentStatus');
        if (paymentPollingStatus === paymentTransactionStatus.SUCCESS) {
          storage.remove('paymentStatus');
          callPaymentProfile();
        } else if (paymentPollingStatus === paymentTransactionStatus.ERROR) {
          storage.remove('paymentStatus');
          callPaymentStatus();
        }
      }, 2000);

      return () => clearInterval(paymentStatusIntervalRef.current);
    }
  }, [debit, paymentProfile]);

  useEffect(() => {
    if (ixopayPageRedirect && debit.success) {
      window.location.href = redirectUrl;
    }
  }, [ixopayPageRedirect, debit]);

  useEffect(() => {
    if (paramsPaymentStatus === ixopayRedirectTransStatus.success) {
      callPaymentProfile();
    } else if (paramsPaymentStatus === ixopayRedirectTransStatus.failed) {
      callPaymentStatus();
    }
  }, []);

  useEffect(() => {
    if (!ixopayPageRedirect) {
      if (paymentProfile.success || paymentStatus) {
        clearInterval(paymentStatusIntervalRef.current);
      }
    }
  }, [paymentProfile, paymentStatus]);

  useEffect(() => {
    if (paymentProfile.success && cardDetails?.length > 0) {
      callPaymentStatus();
    }
  }, [paymentProfile, cardDetails]);

  useEffect(() => {
    if (paramsPaymentStatus) {
      dispatch(setAppProp('steps', translatedDaoSteps));
    }
  }, [translations]);

  useEffect(() => {
    if (
      redirectUrl &&
      paymentIxopayIframeTimeout &&
      !loadingIframe &&
      iframeTimeoutCount <= MAX_RETRY_COUNT &&
      !ixopayPageRedirect
    ) {
      const paymentIxopayIframeTimer = setTimeout(() => {
        if (iframeTimeoutCount < MAX_RETRY_COUNT) {
          setShowIframeTimeoutModal(true);
        }
      }, paymentIxopayIframeTimeout);

      return () => {
        clearTimeout(paymentIxopayIframeTimer);
      };
    }

    if (redirectUrl && !showIframeTimeoutModal && !iframeTimeoutCount && !ixopayPageRedirect) {
      callIframeLoadedStatus();
    }

    if (iframeTimeoutCount >= MAX_RETRY_COUNT) {
      callIframeNotLoadedStatus();
      setShowExitModal(true);
    }
  }, [paymentIxopayIframeTimeout, loadingIframe, redirectUrl]);

  useEffect(() => {
    dispatch(setPaymentEtlList());
    if (debitError && iframeRetryCount < MAX_RETRY_COUNT) {
      setShowIXOIframeRetryModal(true);
    } else if (debitError && iframeRetryCount >= MAX_RETRY_COUNT) {
      setShowIXOIframeRetryModal(false);
      setShowExitModal(true);
      const saveAccActivityData = {
        accRqId: submitPostData.accountId,
        data: {
          rejectionReasonCode: '5003',
          requestId: submitPostData.accountId,
          conventionalIndicator: 'Y',
          conventionalStatus: 'PENDING',
        },
      };
      dispatch(patchAccActivity(saveAccActivityData));
    }
    if (
      paymentError &&
      ((!ixopayPageRedirect && timeoutRetryCount < MAX_RETRY_COUNT) ||
        (ixopayPageRedirect && rtyCnt !== null && rtyCnt < MAX_RETRY_COUNT))
    ) {
      setShowTimeoutRetryModal(true);
    } else if (
      paymentError &&
      ((!ixopayPageRedirect && timeoutRetryCount >= MAX_RETRY_COUNT) ||
        (ixopayPageRedirect && rtyCnt !== null && rtyCnt >= MAX_RETRY_COUNT))
    ) {
      setShowTimeoutRetryModal(false);
      setShowExitModal(true);
      const saveAccActivityData = {
        accRqId: submitPostData.accountId || accountRequestId,
        data: {
          rejectionReasonCode: '5005',
          requestId: submitPostData.accountId || accountRequestId,
          conventionalIndicator: 'Y',
          conventionalStatus: 'PENDING',
        },
      };
      dispatch(patchAccActivity(saveAccActivityData));
    }
  }, [paymentError, debitError]);

  useEffect(() => {
    dispatch(setPaymentEtlList());
    if (paymentProfileCode >= 400) {
      const saveAccActivityData = {
        accRqId: submitPostData.accountId || accountRequestId,
        data: {
          rejectionReasonCode: '9007',
          requestId: submitPostData.accountId || accountRequestId,
          conventionalIndicator: 'Y',
          conventionalStatus: 'PENDING',
        },
      };
      dispatch(patchAccActivity(saveAccActivityData));
      props.setFlow(conventionalThankyouSubType.sorry);
      dispatch(setThankYouPage(true));
      if (paramsPaymentStatus) {
        removeParams(['payment', 'lang', 'accrqid', 'retryct', 'trkid']);
      }
    }
  }, [paymentProfileCode]);

  useEffect(() => {
    dispatch(setPaymentEtlList());
    if (paymentStatus.success === false) {
      if (
        (!ixopayPageRedirect && transactionRetryCount < MAX_RETRY_COUNT) ||
        (ixopayPageRedirect && rtyCnt !== null && rtyCnt < MAX_RETRY_COUNT)
      ) {
        setShowTransactionRetryModal(true);
      } else if (
        (!ixopayPageRedirect && transactionRetryCount >= MAX_RETRY_COUNT) ||
        (ixopayPageRedirect && rtyCnt !== null && rtyCnt >= MAX_RETRY_COUNT)
      ) {
        setShowTransactionRetryModal(false);
        setShowExitTransactionErrorModal(true);
        patchActivityTransactionFailed();
      }
    }
  }, [paymentStatus]);

  const redirectToConventionalThankYou = () => {
    const etLocalList: Etl[] = etlList;
    etLocalList.push({ code: '5001', source: 'payment' });
    dispatch(setEtlList(etLocalList));
    const saveAccActivityData = {
      accRqId: submitPostData.accountId,
      data: {
        rejectionReasonCode: '5001',
        requestId: submitPostData.accountId,
        conventionalIndicator: 'Y',
        conventionalStatus: 'PENDING',
      },
    };
    dispatch(patchAccActivity(saveAccActivityData));
    props.setFlow(conventionalThankyouSubType.request);
    dispatch(setThankYouPage(true));
  };

  useEffect(() => {
    if (submitPostData.accountId) {
      if (!paramsPaymentStatus) {
        callDebitApi();
      }
      if (userTrackId || dhlPassTrkId) {
        dispatch(
          postUserTrackId({
            trackingId: userTrackId || dhlPassTrkId,
            activityEndPage: pageMapper.payment,
            accReqId: submitPostData.accountId,
          })
        );
      }
    }
  }, [submitPostData.accountId]);

  const callDebitApi = () => {
    const transactionData = {
      currency: contactFields?.ixopayCurrency,
      merchantTransactionId: submitPostData.accountId || accountRequestId,
      description: payment.PAYMENT_GATEWAY_TRANSACTION_DESCRIPTION, // description is required for worldpay.
      withRegister: true,
      customer: {
        firstName: firstName || customer?.firstname,
        lastName: lastName || customer?.lastName,
        billingCountry: country[0]?.code || billingCountryCode,
        ...(country[0]?.code === 'US' && {
          billingAddress1: company.bAddress.addressLine1[0] || customer?.billingAddress1,
          billingAddress2: company.bAddress.addressLine2 || customer?.billingAddress2,
          billingCity: company.bAddress.city || customer?.billingCity,
          billingState: company.bAddress.state || customer?.billingState,
          billingPostcode: company.bAddress.zipCode || customer?.billingPostcode,
        }),
        ...(country[0]?.code === 'ZA' && {
          billingPhone: `${contact.number}${contact.ext ? `-${contact.ext}` : ''}`,
        }),
        company: companyName || customer?.company,
        email: email || customer?.email,
        emailVerified: false,
      },
      customerProfileData: {
        customerIdentification: email || customer?.email,
        markAsPreferred: true,
      },
      paymentRetry: Math.floor(Math.random() * 1000).toString(),
      language: selectedLanguage || langName,
      countryCode: country[0]?.code || billingCountryCode,
      clientId: clientId || clientIdName,
      retryCt: rtyCnt !== null ? rtyCnt + 1 : 0,
    };
    dispatch(getDebit(transactionData));
  };

  const callPaymentProfile = () => {
    const profileData = {
      customerIdentification: email || customer?.email,
      billingCountryCode: country[0]?.code || billingCountryCode,
      accountRequestId: submitPostData.accountId || accountRequestId,
      makeDebitCall: true,
      amount: payment.TRANSACTION_AMOUNT,
      currency: contactFields?.ixopayCurrency,
      description: payment.PAYMENT_GATEWAY_TRANSACTION_DESCRIPTION,
    };
    dispatch(getPaymentProfile(profileData));
  };

  const callPaymentStatus = () => {
    const profileData = {
      uuid: debit.uuid || uuid,
      billingCountryCode: country[0]?.code || billingCountryCode,
      accountRequestId: submitPostData.accountId || accountRequestId,
    };
    dispatch(getPaymentStatus(profileData));
  };

  const retryIXOIframe = () => {
    setShowIXOIframeRetryModal(false);
    dispatch(setPaymentProp('iframeRetryCount', iframeRetryCount + 1));
    callDebitApi();
  };

  const retryTransaction = () => {
    setShowTransactionRetryModal(false);
    dispatch(setPaymentProp('transactionRetryCount', transactionRetryCount + 1));
    callDebitApi();
  };

  const retryTimeout = () => {
    setShowTimeoutRetryModal(false);
    dispatch(setPaymentProp('timeoutRetryCount', timeoutRetryCount + 1));
    callDebitApi();
  };

  const exitRegistration = () => {
    setShowExitModal(false);
    setShowExitTransactionErrorModal(false);
    props.setFlow(conventionalThankyouSubType.sorry);
    dispatch(setThankYouPage(true));
    if (paramsPaymentStatus) {
      removeParams(['payment', 'lang', 'accrqid', 'retryct', 'trkid']);
    }
  };

  const retryIframeTimeoutModal = () => {
    setShowIframeTimeoutModal(false);
    dispatch(setPaymentProp('iframeTimeoutCount', iframeTimeoutCount + 1));
    callDebitApi();
  };

  const patchActivityTransactionFailed = () => {
    const saveAccActivityData = {
      accRqId: submitPostData.accountId || accountRequestId,
      data: {
        rejectionReasonCode: '5006',
        requestId: submitPostData.accountId || accountRequestId,
        conventionalIndicator: 'Y',
        conventionalStatus: 'PENDING',
      },
    };
    dispatch(patchAccActivity(saveAccActivityData));
  };

  const callIframeNotLoadedStatus = () => {
    const iframeData = {
      merchantTransactionId: submitPostData.accountId,
      iframeLoaded: false,
    };
    dispatch(setPaymentLoadout(iframeData));
  };

  const callIframeLoadedStatus = () => {
    const iframeData = {
      merchantTransactionId: submitPostData.accountId,
      iframeLoaded: true,
    };
    dispatch(setPaymentLoadout(iframeData));
  };

  const getIxopayErrorMessage = (code) => {
    const errorMessage = code in payment.error.ixopay ? payment.error.ixopay[code] : payment.error.ixopay.default;

    return replace(payment.modal.ERROR_BODY, '{{ErrorMessage}}', errorMessage);
  };

  return (
    <Row>
      <Col className="py-5 px-0">
        <Row>
          <Col xs={{ span: 12 }}>
            <div className="m-0">
              <h2 className="payment-header">{payment.PAYMENT_TITLE}</h2>
            </div>
            <p className="payment-details">
              {payment[clientId?.toLowerCase()]?.PAYMENT_SUBTITLE ?? payment.PAYMENT_SUBTITLE}
            </p>
          </Col>
        </Row>
        <Row>
          <div className="iframe-container">
            {(loadingSubmitData || loadingDebit || loadingProfileSucess || !loadingIframe) && (
              <div className="iframe-spinner">
                <Spinner
                  animation="border"
                  variant="warning"
                />
              </div>
            )}
            {!ixopayPageRedirect && ixopayIframe && (
              <Col>
                <iframe
                  id="iframe_id"
                  title="IXOPAY_IFRAME"
                  width="100%"
                  height={isMobileDevice ? IXOPAY_MOBILE_IFRAME_HEIGHT ?? '600px' : IXOPAY_IFRAME_HEIGHT ?? '400px'}
                  scrolling="no"
                  src={redirectUrl}
                  onLoad={() => dispatch(setPaymentProp('loadingIframe', true))}
                ></iframe>
              </Col>
            )}
          </div>
        </Row>
        <BasicModal
          show={showIXOIframeRetryModal}
          title={payment.modal.RETRY_TITLE}
          content={payment.modal.RETRY_MESSAGE}
          action1Title={payment.modal.RETRY_BUTTON}
          action1Handler={retryIXOIframe}
        />
        <BasicModal
          show={showTransactionRetryModal}
          title={payment.modal.ERROR_TITLE}
          content={getIxopayErrorMessage(errorCode)}
          action1Title={payment.modal.ERROR_BUTTON}
          action1Handler={retryTransaction}
        />
        <BasicModal
          show={showTimeoutRetryModal}
          title={payment.modal.TIMOUT_TITLE}
          content={payment.modal.TIMOUT_BODY}
          action1Title={payment.modal.TIMOUT_BUTTON}
          action1Handler={retryTimeout}
        />
        <BasicModal2
          show={showExitModal}
          title={payment.modal.EXIT_TITLE}
          content={payment.modal.EXIT_BODY}
          actions={[{ type: 'secondary', name: payment.modal.EXIT_BUTTON }]}
          actionHandler={exitRegistration}
        />
        <BasicModal2
          show={showExitTransactionErrorModal}
          title={payment?.modal?.ERROR_EXIT_TITLE}
          content={payment?.modal?.ERROR_EXIT_BODY}
          actions={[{ type: 'secondary', name: payment?.modal?.ERROR_EXIT_BUTTON }]}
          actionHandler={exitRegistration}
        />
        <div className="row">
          <div className="col-md-6 mt-4 prevBtn order-sm-1 order-md-0">
            {!paymentInstruments?.length && (
              <Button
                variant="link"
                aria-label={accessibility?.label?.ADD_PAYMENT_LATER}
                className="payment-later-link"
                onClick={clickToShowAddLaterModal}
              >
                {payment.BUTTON_PAYMENT_ADD_LATER}
              </Button>
            )}
          </div>
          <div className="col-md-6 mt-4 nextBtn">
            <AddLaterModal
              showModal={showAddLaterModal}
              handleClose={closeAddLaterModal}
              addLater={redirectToConventionalThankYou}
            />
          </div>
        </div>
        <BasicModal2
          show={showIframeTimeoutModal}
          title={payment?.modal?.IFRAME_TIMEOUT_TITLE}
          content={payment?.modal?.IFRAME_TIMEOUT_MESSAGE}
          actions={[{ type: 'secondary', name: payment?.modal?.IFRAME_TIMEOUT_BUTTON }]}
          actionHandler={retryIframeTimeoutModal}
        />
      </Col>
    </Row>
  );
}
export default PaymentPage;
