import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import * as Yup from 'yup';

import CompanyPage from './CompanyPage';
import { setCompany, getBreInfo } from '../../../store/actions/companyActions';
import { companyLegalMapper } from '../../../utils/constants';
import { websitePattern1, websitePattern2, websitePattern3, websiteUSPattern2 } from '../../../utils/regEx';

const CompanyContainer = (props) => {
  const { brvError } = useSelector((state: any) => state.DAOReducer);
  const {
    company,
    mandatoryVisitingAddress,
    mandatoryBillingAddress,
    filteredLegalIds,
    mandatoryVisitingAddressFields,
    mandatoryBillingAddressFields,
    companyConfig,
    companyMandatoryFields,
  } = useSelector((state: any) => state.company);
  const companyCountryCode = company.registeredCountry[0]?.code;
  const { hideCountryAndLegalIdSection } = companyConfig;
  const { isEtl, blackListUrls } = useSelector((state: any) => state.app);
  const [inputValue, setInputValue] = useState('');
  const [inputVAddressCountryValue, setInputVAddressCountryValue] = useState('');
  const [inputBAddressCountryValue, setInputBAddressCountryValue] = useState('');
  const { translations } = useSelector((state: any) => state.account);
  const {
    company: { error },
  } = translations;
  const {
    companyLegalBreRegex,
    companyNameBreRegex,
    companyNameDaoRegex,
    visitingBreRegex,
    billingBreRegex,
    visitingDaoRegex,
    billingDaoRegex,
  } = useSelector((state: any) => state.corporateAccount);
  const { companyNameBreRegexValue } = companyNameBreRegex;
  const { companyNameDaoRegexValue1, companyNameDaoRegexValue2 } = companyNameDaoRegex;
  const { companyLegalBreRegexValue } = companyLegalBreRegex;
  const companyLegalBreRegexList = Object.fromEntries(
    companyLegalBreRegexValue?.map((entry) => [
      entry.attributeName.replace(companyLegalMapper.companyLegal, ''),
      new RegExp(entry.regexVal),
    ]) ?? []
  );

  const {
    vAddressLine1BreRegexValue,
    vAddressLine2BreRegexValue,
    vAddressCityBreRegexValue,
    vAddressStateBreRegexValue,
    vAddressZipcodeBreRegexValue,
    vAddressProvinceBreRegexValue,
  } = visitingBreRegex;

  const {
    bAddressLine1BreRegexValue,
    bAddressLine2BreRegexValue,
    bAddressCityBreRegexValue,
    bAddressStateBreRegexValue,
    bAddressZipcodeBreRegexValue,
    bAddressProvinceBreRegexValue,
  } = billingBreRegex;

  const {
    vAddressLine1DaoRegexValue1,
    vAddressLine1DaoRegexValue2,
    vAddressLine2DaoRegexValue1,
    vAddressLine2DaoRegexValue2,
    vAddressCityDaoRegexValue,
    vAddressStateDaoRegexValue,
    vAddressZipCodeDaoRegexValue,
    vAddressProvinceDaoRegexValue,
  } = visitingDaoRegex;

  const {
    bAddressLine1DaoRegexValue1,
    bAddressLine1DaoRegexValue2,
    bAddressLine2DaoRegexValue1,
    bAddressLine2DaoRegexValue2,
    bAddressCityDaoRegexValue,
    bAddressStateDaoRegexValue,
    bAddressZipCodeDaoRegexValue,
    bAddressProvinceDaoRegexValue,
  } = billingDaoRegex;
  const dispatch = useDispatch<any>();

  const companyNameDaoRegex1 = new RegExp(companyNameDaoRegexValue1);
  const companyNameDaoRegex2 = new RegExp(companyNameDaoRegexValue2);
  const vAddressLine1DaoRegex1 = new RegExp(vAddressLine1DaoRegexValue1);
  const vAddressLine1DaoRegex2 = new RegExp(vAddressLine1DaoRegexValue2);
  const vAddressLine2DaoRegex1 = new RegExp(vAddressLine2DaoRegexValue1);
  const vAddressLine2DaoRegex2 = new RegExp(vAddressLine2DaoRegexValue2);
  const vAddressCityDaoRegex = new RegExp(vAddressCityDaoRegexValue);
  const vAddressStateDaoRegex = new RegExp(vAddressStateDaoRegexValue);
  const vAddressZipCodeDaoRegex = new RegExp(vAddressZipCodeDaoRegexValue);
  const vAddressProvinceDaoRegex = new RegExp(vAddressProvinceDaoRegexValue);
  const bAddressLine1DaoRegex1 = new RegExp(bAddressLine1DaoRegexValue1);
  const bAddressLine1DaoRegex2 = new RegExp(bAddressLine1DaoRegexValue2);
  const bAddressLine2DaoRegex1 = new RegExp(bAddressLine2DaoRegexValue1);
  const bAddressLine2DaoRegex2 = new RegExp(bAddressLine2DaoRegexValue2);
  const bAddressCityDaoRegex = new RegExp(bAddressCityDaoRegexValue);
  const bAddressStateDaoRegex = new RegExp(bAddressStateDaoRegexValue);
  const bAddressZipCodeDaoRegex = new RegExp(bAddressZipCodeDaoRegexValue);
  const bAddressProvinceDaoRegex = new RegExp(bAddressProvinceDaoRegexValue);

  const regexcompanyNameBre = new RegExp(companyNameBreRegexValue);
  const vAddressStateBreRegex = new RegExp(vAddressStateBreRegexValue);
  const bAddressStateBreRegex = new RegExp(bAddressStateBreRegexValue);
  const vAddressCityBreRegex = new RegExp(vAddressCityBreRegexValue);
  const bAddressCityBreRegex = new RegExp(bAddressCityBreRegexValue);
  const vAddressProvinceBreRegex = new RegExp(vAddressProvinceBreRegexValue);
  const bAddressProvinceBreRegex = new RegExp(bAddressProvinceBreRegexValue);
  const vAddressZipcodeBreRegex = new RegExp(vAddressZipcodeBreRegexValue);
  const bAddressZipcodeBreRegex = new RegExp(bAddressZipcodeBreRegexValue);

  const onSubmit = (values) => {
    dispatch(setCompany(values));
    if (!isEtl) {
      dispatch(getBreInfo(values.legalCodes));
    }
    props.nextPage();
  };

  const companyValidationSchema = Yup.object().shape({
    branchCode: Yup.string().when(['registeredCountry[0].code', 'registrationNotPresent'], {
      is: (registeredCountry, registrationNotPresent) => registeredCountry === 'TH' && !registrationNotPresent,
      then: Yup.string()
        .required(error.branch_code?.EMPTY)
        .matches(/^\d{5}$/, error.branch_code?.FORMAT),
    }),
    companyName: Yup.string()
      .required(error.company_name.EMPTY)
      .max(100, error.company_name.LENGTH)
      .test('companyNameRegex', (value = '', { createError, path }) => {
        if (value && companyNameDaoRegexValue1?.length > 0 && !companyNameDaoRegex1.test(value)) {
          return createError({
            path,
            message: error.company_name.CHARACTER,
          });
        }
        if (value && companyNameDaoRegexValue2?.length > 0 && !companyNameDaoRegex2.test(value)) {
          return createError({
            path,
            message: error.company_name.FORMAT,
          });
        }
        if (value && companyNameBreRegexValue !== null && !regexcompanyNameBre.test(value)) {
          return createError({
            path,
            message: error.company_name.CHARACTER,
          });
        } else return true;
      }),
    vAddress: Yup.object().shape({
      addressLine2: Yup.string()
        .nullable()
        .min(5, error.address_line_2.LENGTH_MIN)
        .max(45, error.address_line_2.LENGTH_MAX)
        .test('addressLine2Regex', (value = '', { createError, path }) => {
          if (value && vAddressLine2DaoRegexValue1?.length > 0 && !vAddressLine2DaoRegex1.test(value)) {
            return createError({
              path,
              message: error.address_line_2.CHARACTER,
            });
          }
          if (value && vAddressLine2DaoRegexValue2?.length > 0 && !vAddressLine2DaoRegex2.test(value)) {
            return createError({
              path,
              message: error.address_line_2.FORMAT,
            });
          } else return true;
        }),
      city: Yup.string()
        .nullable()
        .max(35, error.city.LENGTH)
        .test('cityRegex', (value = '', { createError, path }) => {
          if (value && vAddressCityDaoRegexValue?.length > 0 && !vAddressCityDaoRegex.test(value)) {
            return createError({
              path,
              message: error.city.CHARACTER,
            });
          }
          if (value && vAddressCityBreRegexValue !== null && !vAddressCityBreRegex.test(value)) {
            return createError({
              path,
              message: error.city.FORMAT,
            });
          } else return true;
        }),
      state: Yup.string()
        .nullable()
        .max(35, error.state.LENGTH)
        .test('stateRegex', (value = '', { createError, path }) => {
          if (value && vAddressStateDaoRegexValue?.length > 0 && !vAddressStateDaoRegex.test(value)) {
            return createError({
              path,
              message: error.state.CHARACTER,
            });
          }
          if (value && vAddressStateBreRegexValue !== null && !vAddressStateBreRegex.test(value)) {
            return createError({
              path,
              message: error.state.FORMAT,
            });
          } else return true;
        }),
      zipCode: Yup.string()
        .nullable()
        .max(10, error.postal_code.LENGTH)
        .test('zipcodeRegex', (value = '', { createError, path }) => {
          if (value && vAddressZipCodeDaoRegexValue?.length > 0 && !vAddressZipCodeDaoRegex.test(value)) {
            return createError({
              path,
              message: error.postal_code.CHARACTER,
            });
          }
          if (value && vAddressZipcodeBreRegexValue !== null && !vAddressZipcodeBreRegex.test(value)) {
            return createError({
              path,
              message: error.postal_code.FORMAT,
            });
          } else return true;
        }),
      province: Yup.string()
        .nullable()
        .max(35, error.province.LENGTH)
        .test('provinceRegex', (value = '', { createError, path }) => {
          if (value && vAddressProvinceDaoRegexValue?.length > 0 && !vAddressProvinceDaoRegex.test(value)) {
            return createError({
              path,
              message: error.province.CHARACTER,
            });
          }
          if (value && vAddressProvinceBreRegexValue !== null && !vAddressProvinceBreRegex.test(value)) {
            return createError({
              path,
              message: error.province.FORMAT,
            });
          } else return true;
        }),
    }),
    bAddress: Yup.object().shape({
      addressLine2: Yup.string()
        .nullable()
        .min(5, error.address_line_2.LENGTH_MIN)
        .max(45, error.address_line_2.LENGTH_MAX)
        .test('addressLine2Regex', (value = '', { createError, path }) => {
          if (value && bAddressLine2DaoRegexValue1?.length > 0 && !bAddressLine2DaoRegex1.test(value)) {
            return createError({
              path,
              message: error.address_line_2.CHARACTER,
            });
          }
          if (value && bAddressLine2DaoRegexValue2?.length > 0 && !bAddressLine2DaoRegex2.test(value)) {
            return createError({
              path,
              message: error.address_line_2.FORMAT,
            });
          } else return true;
        }),
      city: Yup.string()
        .nullable()
        .max(35, error.city.LENGTH)
        .test('cityRegex', (value = '', { createError, path }) => {
          if (value && bAddressCityDaoRegexValue?.length > 0 && !bAddressCityDaoRegex.test(value)) {
            return createError({
              path,
              message: error.city.CHARACTER,
            });
          }
          if (value && bAddressCityBreRegexValue !== null && !bAddressCityBreRegex.test(value)) {
            return createError({
              path,
              message: error.city.FORMAT,
            });
          } else return true;
        }),
      state: Yup.string()
        .nullable()
        .max(35, error.state.LENGTH)
        .test('stateRegex', (value = '', { createError, path }) => {
          if (value && bAddressStateDaoRegexValue?.length > 0 && !bAddressStateDaoRegex.test(value)) {
            return createError({
              path,
              message: error.state.CHARACTER,
            });
          }
          if (value && bAddressStateBreRegexValue !== null && !bAddressStateBreRegex.test(value)) {
            return createError({
              path,
              message: error.state.FORMAT,
            });
          } else return true;
        }),
      zipCode: Yup.string()
        .nullable()
        .max(10, error.postal_code.LENGTH)
        .test('zipcodeRegex', (value = '', { createError, path }) => {
          if (value && bAddressZipCodeDaoRegexValue?.length > 0 && !bAddressZipCodeDaoRegex.test(value)) {
            return createError({
              path,
              message: error.postal_code.CHARACTER,
            });
          }
          if (value && bAddressZipcodeBreRegexValue !== null && !bAddressZipcodeBreRegex.test(value)) {
            return createError({
              path,
              message: error.postal_code.FORMAT,
            });
          } else return true;
        }),
      province: Yup.string()
        .nullable()
        .max(35, error.province.LENGTH)
        .test('provinceRegex', (value = '', { createError, path }) => {
          if (value && bAddressProvinceDaoRegexValue?.length > 0 && !bAddressProvinceDaoRegex.test(value)) {
            return createError({
              path,
              message: error.province.CHARACTER,
            });
          }
          if (value && bAddressProvinceBreRegexValue !== null && !bAddressProvinceBreRegex.test(value)) {
            return createError({
              path,
              message: error.province.FORMAT,
            });
          } else return true;
        }),
    }),
    companyWebsite: Yup.string()
      .test('companyWebsiteRequired', error.website.EMPTY, (value) =>
        companyMandatoryFields?.includes('companyWebsite') ? !!value && value.length > 0 : true
      )
      .max(50, error.website.LENGTH)
      .test('companyWebsiteRegex', (value = '', { createError, path }) => {
        // regex example: /\b(?:\.example\.|example\.|\.nowebsite\.|nowebsite\.)\b/
        let pattern = '';
        blackListUrls.forEach((blackListUrl, index) => {
          pattern += `\\.${blackListUrl}\\.|${blackListUrl}\\.${index < blackListUrls.length - 1 ? '|' : ''}`;
        });
        const blackListUrlRegex = new RegExp(`\\b(?:${pattern})\\b`, 'g');
        if (companyCountryCode === 'US') {
          if (
            websitePattern1.test(value) ||
            websitePattern2.test(value) ||
            (blackListUrls.length && blackListUrlRegex.test(value))
          ) {
            return createError({
              path,
              message: error.website.FORMAT,
            });
          }
          if (value && !websiteUSPattern2.test(value)) {
            return createError({
              path,
              message: error.website.FORMAT,
            });
          } else return true;
        } else {
          if (
            websitePattern1.test(value) ||
            websitePattern2.test(value) ||
            (blackListUrls.length && blackListUrlRegex.test(value))
          ) {
            return createError({
              path,
              message: error.website.FORMAT,
            });
          }
          if (value && !websitePattern3.test(value)) {
            return createError({
              path,
              message: error.website.FORMAT,
            });
          } else return true;
        }
      }),
  });

  const validate = (values) => {
    const errors = {};
    const {
      vAddress,
      bAddress,
      billingAddressSame,
      legalCodes,
      registrationNotPresent,
      registeredCountry,
      companyAddressCountry,
      billingAddressCountry,
      conditionalLegalIdType,
      tradingCompanyNameSame,
      tradingName,
      companyIndustry,
    } = values;
    for (const vAdd in vAddress) {
      const isArray = Array.isArray(vAddress[vAdd]);
      if (isArray) {
        if (
          (mandatoryVisitingAddress.includes(vAdd) || mandatoryVisitingAddressFields?.includes(vAdd)) &&
          vAddress[vAdd].length === 0
        ) {
          if (!errors['vAddress']) {
            errors['vAddress'] = {};
          }
          errors['vAddress'][vAdd] = error.address_line_1.EMPTY;
        } else if (vAddress[vAdd].length === 1) {
          const addLine1 = vAddress[vAdd][0];
          const addLine2 = vAddress.addressLine2;
          if (addLine1?.length > 45) {
            if (!errors['vAddress']) {
              errors['vAddress'] = {};
            }
            errors['vAddress'][vAdd] = error.address_line_1.LENGTH_MAX;
          }
          if (addLine1?.length < 5) {
            if (!errors['vAddress']) {
              errors['vAddress'] = {};
            }
            errors['vAddress'][vAdd] = error.address_line_1.LENGTH_MIN;
          }

          if (vAddressLine1DaoRegexValue2?.length > 0 && !vAddressLine1DaoRegex2.test(addLine1)) {
            if (!errors['vAddress']) {
              errors['vAddress'] = {};
            }
            errors['vAddress'][vAdd] = error.address_line_1.FORMAT;
          }

          if (vAddressLine1DaoRegexValue1?.length > 0 && !vAddressLine1DaoRegex1.test(addLine1)) {
            if (!errors['vAddress']) {
              errors['vAddress'] = {};
            }
            errors['vAddress'][vAdd] = error.address_line_1.CHARACTER;
          }

          const vAddressLine1BreRegex = new RegExp(vAddressLine1BreRegexValue);
          const vAddressLine2BreRegex = new RegExp(vAddressLine2BreRegexValue);

          if (vAddressLine1BreRegexValue !== null && !vAddressLine1BreRegex.test(addLine1)) {
            if (!errors['vAddress']) {
              errors['vAddress'] = {};
            }
            errors['vAddress'][vAdd] = error.address_line_1.FORMAT;
          }

          if (vAddressLine2BreRegexValue !== null && !vAddressLine2BreRegex.test(addLine2)) {
            if (!errors['vAddress']) {
              errors['vAddress'] = {};
            }
            errors['vAddress'].addressLine2 = error.address_line_2.FORMAT;
          }
        }
      }

      if (
        (mandatoryVisitingAddress.includes(vAdd) || mandatoryVisitingAddressFields?.includes(vAdd)) &&
        !vAddress[vAdd]
      ) {
        if (!errors['vAddress']) {
          errors['vAddress'] = {};
        }
        switch (vAdd) {
          case 'city':
            errors['vAddress'][vAdd] = error.city.EMPTY;
            break;
          case 'zipCode':
            errors['vAddress'][vAdd] = error.postal_code.EMPTY;
            break;
          case 'state':
            errors['vAddress'][vAdd] = error.state.EMPTY;
            break;
          case 'province':
            errors['vAddress'][vAdd] = error.province.EMPTY;
            break;
          default:
            errors['vAddress'][vAdd] = error.address_line_1.EMPTY;
            break;
        }
      }
    }
    if (!hideCountryAndLegalIdSection) {
      for (const legalCode in legalCodes) {
        const code = legalCodes[legalCode];
        if (code) {
          if (code.length > 50) {
            if (!errors['legalCodes']) {
              errors['legalCodes'] = {};
            }
            errors['legalCodes'][legalCode] = error.legal[legalCode]?.LENGTH ?? error.legal.LENGTH;
          }
        }
        if (!code && legalCode === conditionalLegalIdType) {
          if (!errors['legalCodes']) {
            errors['legalCodes'] = {};
          }
          errors['legalCodes'][legalCode] = error.legal[legalCode]?.EMPTY ?? error.legal.EMPTY;
        }
        if (Object.prototype.hasOwnProperty.call(companyLegalBreRegexList, legalCode)) {
          const isValid = companyLegalBreRegexList[legalCode].test(code);
          if (!isValid) {
            if (!errors['legalCodes']) {
              errors['legalCodes'] = {};
            }
            errors['legalCodes'][legalCode] = error.legal[legalCode]?.FORMAT ?? error.legal.FORMAT;
          }
        }
        if (brvError[legalCode]) {
          if (!errors['legalCodes']) {
            errors['legalCodes'] = {};
          }
          errors['legalCodes'][legalCode] = error.brv[brvError[legalCode]['error']] ?? error.brv.default;
        }
      }
    }

    if (!billingAddressSame) {
      for (const bAdd in bAddress) {
        const isArray = Array.isArray(bAddress[bAdd]);
        if (isArray) {
          if (
            (mandatoryBillingAddress.includes(bAdd) || mandatoryBillingAddressFields?.includes(bAdd)) &&
            bAddress[bAdd].length === 0
          ) {
            if (!errors['bAddress']) {
              errors['bAddress'] = {};
            }
            errors['bAddress'][bAdd] = error.address_line_1.EMPTY;
          } else if (bAddress[bAdd].length === 1) {
            const addLine1 = bAddress[bAdd][0];
            const addLine2 = bAddress.addressLine2;
            if (addLine1?.length > 45) {
              if (!errors['bAddress']) {
                errors['bAddress'] = {};
              }
              errors['bAddress'][bAdd] = error.address_line_1.LENGTH_MAX;
            }
            if (addLine1?.length < 5) {
              if (!errors['bAddress']) {
                errors['bAddress'] = {};
              }
              errors['bAddress'][bAdd] = error.address_line_1.LENGTH_MIN;
            }

            if (bAddressLine1DaoRegexValue2?.length > 0 && !bAddressLine1DaoRegex2.test(addLine1)) {
              if (!errors['bAddress']) {
                errors['bAddress'] = {};
              }
              errors['bAddress'][bAdd] = error.address_line_1.FORMAT;
            }

            if (bAddressLine1DaoRegexValue1?.length > 0 && !bAddressLine1DaoRegex1.test(addLine1)) {
              if (!errors['bAddress']) {
                errors['bAddress'] = {};
              }
              errors['bAddress'][bAdd] = error.address_line_1.CHARACTER;
            }

            const bAddressLine1BreRegex = new RegExp(bAddressLine1BreRegexValue);
            const bAddressLine2BreRegex = new RegExp(bAddressLine2BreRegexValue);

            if (bAddressLine1BreRegexValue !== null && !bAddressLine1BreRegex.test(addLine1)) {
              if (!errors['bAddress']) {
                errors['bAddress'] = {};
              }
              errors['bAddress'][bAdd] = error.address_line_1.FORMAT;
            }

            if (bAddressLine2BreRegexValue !== null && !bAddressLine2BreRegex.test(addLine2)) {
              if (!errors['bAddress']) {
                errors['bAddress'] = {};
              }
              errors['bAddress'].addressLine2 = error.address_line_2.FORMAT;
            }
          }
        }

        if (
          (mandatoryBillingAddress.includes(bAdd) || mandatoryBillingAddressFields?.includes(bAdd)) &&
          !bAddress[bAdd]
        ) {
          if (!errors['bAddress']) {
            errors['bAddress'] = {};
          }
          switch (bAdd) {
            case 'city':
              errors['bAddress'][bAdd] = error.city.EMPTY;
              break;
            case 'zipCode':
              errors['bAddress'][bAdd] = error.postal_code.EMPTY;
              break;
            case 'state':
              errors['bAddress'][bAdd] = error.state.EMPTY;
              break;
            case 'province':
              errors['bAddress'][bAdd] = error.province.EMPTY;
              break;
            default:
              errors['bAddress'][bAdd] = error.address_line_1.EMPTY;
              break;
          }
        }
      }
    }

    if (!tradingCompanyNameSame) {
      if (tradingName?.length === 0) {
        if (!errors['tradingName']) {
          errors['tradingName'] = {};
        }
        errors['tradingName'] = error.company_name.EMPTY;
      } else if (tradingName?.length > 0) {
        if (tradingName?.length > 100) {
          if (!errors['tradingName']) {
            errors['tradingName'] = {};
          }
          errors['tradingName'] = error.company_name.LENGTH;
        }
        if (companyNameDaoRegexValue1?.length > 0 && !companyNameDaoRegex1.test(tradingName)) {
          if (!errors['tradingName']) {
            errors['tradingName'] = {};
          }
          errors['tradingName'] = error.company_name.CHARACTER;
        }
        if (companyNameDaoRegexValue2?.length > 0 && !companyNameDaoRegex2.test(tradingName)) {
          if (!errors['tradingName']) {
            errors['tradingName'] = {};
          }
          errors['tradingName'] = error.company_name.FORMAT;
        }
        if (companyNameBreRegexValue !== null && !regexcompanyNameBre.test(tradingName)) {
          if (!errors['tradingName']) {
            errors['tradingName'] = {};
          }
          errors['tradingName'] = error.company_name.CHARACTER;
        }
      }
    }

    // LegalId's validation
    if (!registrationNotPresent && !hideCountryAndLegalIdSection) {
      filteredLegalIds.forEach((filteredLegalId) => {
        if (filteredLegalId.mandatory && !legalCodes[filteredLegalId.legalIdCode]) {
          if (!errors['legalCodes']) {
            errors['legalCodes'] = {};
          }
          errors['legalCodes'][filteredLegalId.legalIdCode] =
            error.legal[filteredLegalId.legalIdCode]?.EMPTY ?? error.legal.EMPTY;
        }
        if (filteredLegalId.legalIdRegEx && legalCodes[filteredLegalId.legalIdCode]) {
          const fieldRegEx = new RegExp(filteredLegalId.legalIdRegEx);
          if (!fieldRegEx.test(legalCodes[filteredLegalId.legalIdCode])) {
            if (!errors['legalCodes']) {
              errors['legalCodes'] = {};
            }
            errors['legalCodes'][filteredLegalId.legalIdCode] = 'Please enter a valid value to proceed';
          }
        }
      });
    }

    if (!registeredCountry.length && !inputValue) {
      errors['registeredCountry'] = error.country.EMPTY;
    }

    if (!registeredCountry.length && inputValue) {
      errors['registeredCountry'] = error.country.INVALID;
    }

    if (!companyAddressCountry?.length && !inputVAddressCountryValue) {
      errors['companyAddressCountry'] = error?.company_country?.EMPTY;
    }
    if (!companyAddressCountry?.length && inputVAddressCountryValue) {
      errors['companyAddressCountry'] = error?.company_country?.INVALID;
    }

    if (!billingAddressCountry?.length && !inputBAddressCountryValue) {
      errors['billingAddressCountry'] = error?.billing_country?.EMPTY;
    }
    if (!billingAddressCountry?.length && inputBAddressCountryValue) {
      errors['billingAddressCountry'] = error?.billing_country?.INVALID;
    }
    if (companyMandatoryFields.includes('companyIndustry') && !companyIndustry?.length) {
      errors['companyIndustry'] = error?.industry?.EMPTY;
    }

    return errors;
  };

  return (
    <Formik
      initialValues={company}
      validationSchema={companyValidationSchema}
      validateOnMount
      validateOnChange
      onSubmit={onSubmit}
      validate={validate}
    >
      {(formik) => {
        return (
          <CompanyPage
            formik={formik}
            onInputChange={setInputValue}
            prev={props.prev}
            onInputVAddressCountryChange={setInputVAddressCountryValue}
            onInputBAddressCountryChange={setInputBAddressCountryValue}
          />
        );
      }}
    </Formik>
  );
};

export default CompanyContainer;
