import { get, post } from './axiosWrapper';
import * as url from './apiRoutes';
import {
  BE_AS_AXIOS,
  BE_QL_AXIOS,
  BE_AC_AXIOS,
  X_DPDHL_INSTANCE_ID,
  X_DPDHL_Sender,
  X_DPDHL_Originator,
  CONTENT_TYPE,
  CACHE_CONTROL,
  AKAMAI_AXIOS,
} from './constants';

export const getCountryRefData = (data) =>
  post(
    url.GET_COUNTRY_REF_DATA,
    data,
    {
      headers: {
        [X_DPDHL_INSTANCE_ID]: Date.now().toString(36),
        [X_DPDHL_Sender]: 'DAO-FE',
        [X_DPDHL_Originator]: 'DAO-FE',
        [CACHE_CONTROL]: 'no-cache, no-store, max-age=0, must-revalidate',
      },
    },
    BE_QL_AXIOS
  );

export const getAddressSuggestions = (data) =>
  get(
    url.GET_ADDRESS_SUGGESTIONS,
    { params: { query: data.query, countryCd: data.countryCd, endClientId: Date.now().toString(36) } },
    BE_QL_AXIOS
  );

export const getAddressDetails = (data) =>
  get(
    url.GET_ADDRESS_DETAILS,
    {
      params: {
        countryCd: data.countryCd,
        providerId: data.providerId,
        addressId: data.addressId,
        endClientId: Date.now().toString(36),
      },
    },
    BE_QL_AXIOS
  );

export const getValidateAddressDetails = (data) =>
  post(
    url.GET_VALIDATED_ADDRESS_DETAILS,
    data,
    {
      headers: {
        [X_DPDHL_INSTANCE_ID]: Date.now().toString(36),
        [X_DPDHL_Sender]: 'DAO-FE',
        [CACHE_CONTROL]: 'no-cache, no-store, max-age=0, must-revalidate',
      },
    },
    BE_QL_AXIOS
  );

export const submitCustomer = (data, flowType) =>
  post(
    url.SUBMIT_CUSTOMER + '?flowType=' + flowType,
    data,
    { headers: { [X_DPDHL_INSTANCE_ID]: Date.now().toString(36), [X_DPDHL_Sender]: 'DAO-FE' } },
    BE_AS_AXIOS,
    process.env.REACT_APP_API_SUBMIT_CUSTOMER_TIMEOUT as unknown as number
  );

export const getSignedDoc = (data) =>
  get(
    url.GET_SIGNED_DOC + '/' + data,
    { headers: { [X_DPDHL_INSTANCE_ID]: Date.now().toString(36), [X_DPDHL_Sender]: 'DAO-FE' }, responseType: 'blob' },
    BE_AS_AXIOS
  );

export const getCustomerDetails = (data) =>
  get(
    url.GET_CUSTOMER_DETAILS + '?packageId=' + data,
    { headers: { [X_DPDHL_INSTANCE_ID]: Date.now().toString(36), [X_DPDHL_Sender]: 'DAO-FE' } },
    BE_AS_AXIOS
  );

export const getDataMaintainanceForLegal = (data) =>
  post(
    url.GET_DATA_MAINTAINANCE_FIELDS,
    data,
    {
      headers: {
        [X_DPDHL_INSTANCE_ID]: Date.now().toString(36),
        [X_DPDHL_Sender]: 'DAO-FE',
        [X_DPDHL_Originator]: 'DAO-FE',
        [CACHE_CONTROL]: 'no-cache, no-store, max-age=0, must-revalidate',
      },
    },
    BE_QL_AXIOS
  );

export const getLanguageCode = (data, selectedLanguage) =>
  post(
    url.GET_LANGUAGE_CODE + '?lngCd=' + selectedLanguage,
    data,
    {
      headers: {
        [X_DPDHL_INSTANCE_ID]: Date.now().toString(36),
        [X_DPDHL_Sender]: 'DAO-FE',
        [X_DPDHL_Originator]: 'DAO-FE',
        [CACHE_CONTROL]: 'no-cache, no-store, max-age=0, must-revalidate',
      },
    },
    BE_QL_AXIOS
  );

export const getDocsRegenerate = (data) =>
  post(
    url.GET_DOCS_REGENERATE,
    data,
    {
      headers: {
        [X_DPDHL_INSTANCE_ID]: Date.now().toString(36),
        [X_DPDHL_Sender]: 'DAO-FE',
        [X_DPDHL_Originator]: 'DAO-FE',
      },
    },
    BE_AS_AXIOS
  );

export const getCountryConfig = (data) =>
  post(
    url.GET_COUNTRY_CONFIG,
    data,
    {
      headers: {
        [X_DPDHL_INSTANCE_ID]: Date.now().toString(36),
        [X_DPDHL_Sender]: 'DAO-FE',
        [CACHE_CONTROL]: 'no-cache, no-store, max-age=0, must-revalidate',
      },
    },
    BE_QL_AXIOS
  );

export const generateTrackId = (data) =>
  get(
    url.GENERATE_TRACK_ID,
    {
      headers: {
        [X_DPDHL_INSTANCE_ID]: Date.now().toString(36),
        [X_DPDHL_Sender]: 'DAO-FE',
        [X_DPDHL_Originator]: 'DAO-FE',
      },
    },
    BE_AC_AXIOS
  );

export const postUserTrackById = (data) =>
  post(
    url.GET_USER_ACTIVITY,
    data,
    {
      headers: {
        [X_DPDHL_INSTANCE_ID]: Date.now().toString(36),
        [X_DPDHL_Sender]: 'DAO-FE',
        [X_DPDHL_Originator]: 'DAO-FE',
      },
    },
    BE_AC_AXIOS
  );

export const validateMyBill = (data) =>
  post(
    url.VALIDATE_MY_BILL,
    data,
    {
      headers: {
        [X_DPDHL_INSTANCE_ID]: Date.now().toString(36),
        [X_DPDHL_Sender]: 'DAO-FE',
        [CACHE_CONTROL]: 'no-cache, no-store, max-age=0, must-revalidate',
      },
    },
    BE_QL_AXIOS
  );

export const validatePhone = (data) =>
  post(
    url.VALIDATE_PHONE,
    data,
    {
      headers: {
        [X_DPDHL_INSTANCE_ID]: Date.now().toString(36),
        [X_DPDHL_Sender]: 'DAO-FE',
        [CACHE_CONTROL]: 'no-cache, no-store, max-age=0, must-revalidate',
      },
    },
    BE_QL_AXIOS
  );

export const getDebit = (data) =>
  post(
    url.GET_DEBIT,
    data,
    { headers: { [X_DPDHL_INSTANCE_ID]: Date.now().toString(36), [X_DPDHL_Sender]: 'DAO-FE' } },
    BE_AC_AXIOS
  );

export const createAccount = (data) =>
  post(
    url.CREATE_ACCOUNT,
    data,
    { headers: { [X_DPDHL_INSTANCE_ID]: Date.now().toString(36), [X_DPDHL_Sender]: 'DAO-FE' } },
    BE_AC_AXIOS
  );

export const getBREBusinessRuleValidate = (data) =>
  post(
    url.GET_BRE_BUSINESS_RULE_VALIDATE,
    data,
    { headers: { [X_DPDHL_INSTANCE_ID]: Date.now().toString(36), [X_DPDHL_Sender]: 'DAO-FE' } },
    BE_AC_AXIOS
  );

export const getTranslationsAkamai = (data) =>
  get(
    url.GET_TRANSLATIONS_AKAMAI + '/' + data,
    {
      headers: {
        [X_DPDHL_INSTANCE_ID]: Date.now().toString(36),
        [X_DPDHL_Sender]: 'DAO-FE',
        [CACHE_CONTROL]: 'no-cache, no-store, max-age=0, must-revalidate',
      },
    },
    AKAMAI_AXIOS
  );

export const getDaoConfigAkamai = (data) =>
  get(
    url.GET_DAO_CONFIG_AKAMAI + '/' + data,
    {
      headers: {
        [X_DPDHL_INSTANCE_ID]: Date.now().toString(36),
        [X_DPDHL_Sender]: 'DAO-FE',
        [CACHE_CONTROL]: 'no-cache, no-store, max-age=0, must-revalidate',
      },
    },
    AKAMAI_AXIOS
  );

export const getPaymentProfile = (data) =>
  post(
    url.GET_PAYMENT_PROFILE,
    data,
    { headers: { [X_DPDHL_INSTANCE_ID]: Date.now().toString(36), [X_DPDHL_Sender]: 'DAO-FE' } },
    BE_AC_AXIOS
  );

export const getPaymentStatus = (data) =>
  post(
    url.GET_PAYMENT_STATUS,
    data,
    { headers: { [X_DPDHL_INSTANCE_ID]: Date.now().toString(36), [X_DPDHL_Sender]: 'DAO-FE' } },
    BE_AC_AXIOS
  );

export const getSummaryStatus = (data) =>
  get(
    url.GET_SUMMARY_STATUS + '?accountId=' + data,
    { headers: { [X_DPDHL_INSTANCE_ID]: Date.now().toString(36), [X_DPDHL_Sender]: 'DAO-FE' } },
    BE_AS_AXIOS
  );

export const getShippingRatesServiceDocs = (data) =>
  get(
    url.GET_SHIPPING_RATES_SERVICE_DOCS + '/' + data,
    { headers: { [X_DPDHL_INSTANCE_ID]: Date.now().toString(36), [X_DPDHL_Sender]: 'DAO-FE' } },
    BE_AS_AXIOS
  );

export const getSaveConsent = (data) =>
  post(
    url.GET_SAVE_CONSENT,
    data,
    { headers: { [X_DPDHL_INSTANCE_ID]: Date.now().toString(36), [X_DPDHL_Sender]: 'DAO-FE' } },
    BE_AS_AXIOS
  );

export const getCustomerDetailsFlowTwo = (data) =>
  get(
    url.GET_CUSTOMER_DETAILS_FLOW_TWO + '?accountId=' + data,
    { headers: { [X_DPDHL_INSTANCE_ID]: Date.now().toString(36), [X_DPDHL_Sender]: 'DAO-FE' } },
    BE_AS_AXIOS
  );

export const patchAccountEntity = (request) =>
  post(
    url.PATCH_ACCOUNTS + '/' + request.accRqId,
    request.data,
    {
      headers: {
        [X_DPDHL_INSTANCE_ID]: Date.now().toString(36),
        [X_DPDHL_Sender]: 'DAO-FE',
        [X_DPDHL_Originator]: 'DAO-FE',
      },
    },
    BE_AS_AXIOS
  );

export const getUserTrackId = (data) =>
  post(
    url.GET_USER_TRACK_ID,
    data,
    {
      headers: {
        [X_DPDHL_INSTANCE_ID]: Date.now().toString(36),
        [X_DPDHL_Sender]: 'DAO-FE',
        [X_DPDHL_Originator]: 'DAO-FE',
      },
    },
    BE_AC_AXIOS
  );

export const setPaymentLoadout = (data) =>
  post(
    url.SET_PAYMENT_LOADOUT,
    data,
    {
      headers: {
        [X_DPDHL_INSTANCE_ID]: Date.now().toString(36),
        [X_DPDHL_Sender]: 'DAO-FE',
        [X_DPDHL_Originator]: 'DAO-FE',
      },
    },
    BE_AC_AXIOS
  );

export const getDocUpload = (formData, languageCode) =>
  post(
    url.GET_DOC_UPLOAD + '?lngCd=' + languageCode,
    formData,
    {
      headers: {
        [CONTENT_TYPE]: 'multipart/form-data',
        [X_DPDHL_INSTANCE_ID]: Date.now().toString(36),
        [X_DPDHL_Sender]: 'DAO-FE',
        [X_DPDHL_Originator]: 'DAO-FE',
      },
    },
    BE_AS_AXIOS,
    0,
    true
  );

export const getDocDelete = (data) =>
  post(
    url.GET_DOC_DELETE,
    data,
    {
      headers: {
        [X_DPDHL_INSTANCE_ID]: Date.now().toString(36),
        [X_DPDHL_Sender]: 'DAO-FE',
        [X_DPDHL_Originator]: 'DAO-FE',
      },
    },
    BE_AS_AXIOS
  );

export const getLandingPage = (data) =>
  post(
    url.GET_LANDING_PAGE,
    data,
    {
      headers: {
        [X_DPDHL_INSTANCE_ID]: Date.now().toString(36),
        [X_DPDHL_Sender]: 'DAO-FE',
        [CACHE_CONTROL]: 'no-cache, no-store, max-age=0, must-revalidate',
      },
    },
    BE_QL_AXIOS
  );

export const getPaymentMethodType = (data) =>
  post(
    url.GET_PAYMENT_METHOD_TYPE,
    data,
    {
      headers: {
        [X_DPDHL_INSTANCE_ID]: Date.now().toString(36),
        [X_DPDHL_Sender]: 'DAO-FE',
        [X_DPDHL_Originator]: 'DAO-FE',
      },
    },
    BE_AS_AXIOS
  );

export const getMaintenanceStatus = () =>
  get(
    process.env.REACT_APP_URL + '/assets/maintenance.json',
    {
      headers: {
        [X_DPDHL_INSTANCE_ID]: Date.now().toString(36),
        [X_DPDHL_Sender]: 'DAO-FE',
        [CACHE_CONTROL]: 'no-cache, no-store, max-age=0, must-revalidate',
      },
    },
    AKAMAI_AXIOS
  );

export const getIxopayDetails = (data) =>
  get(
    url.GET_IXOPAY_DETAILS + '?accountRequestId=' + data,
    { headers: { [X_DPDHL_INSTANCE_ID]: Date.now().toString(36), [X_DPDHL_Sender]: 'DAO-FE' } },
    BE_AC_AXIOS
  );
export const getTimeoutSession = (data) =>
  post(
    url.GET_TIMEOUT_SESSION,
    data,
    {
      headers: {
        [X_DPDHL_INSTANCE_ID]: Date.now().toString(36),
        [X_DPDHL_Sender]: 'DAO-FE',
        [X_DPDHL_Originator]: 'DAO-FE',
      },
    },
    BE_QL_AXIOS
  );

export const getCustomerInfo = (data) =>
  post(
    url.GET_CUSTOMER_INFO,
    data,
    {
      headers: {
        [X_DPDHL_INSTANCE_ID]: Date.now().toString(36),
        [X_DPDHL_Sender]: 'DAO-FE',
        [X_DPDHL_Originator]: 'DAO-FE',
      },
    },
    BE_QL_AXIOS
  );

export const sendCookieConsent = (data) =>
  post(
    url.SEND_COOKIE_CONSENT,
    data.content,
    {
      headers: {
        [X_DPDHL_INSTANCE_ID]: Date.now().toString(36),
        [X_DPDHL_Sender]: 'DAO-FE',
        [X_DPDHL_Originator]: 'DAO-FE',
        dhlTrackingId: data.trackingId,
      },
    },
    BE_AS_AXIOS
  );
