import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { replace } from 'lodash';

import checkmark_circle_green from '../../../assets/icons/checkmark_circle_green.svg';

import './ThankYouPage.css';
import { postUserTrackId } from '../../../store/actions/appActions';
import { pageMapper } from '../../../utils/constants';

function DaoThankYouPage() {
  const { translations } = useSelector((state: any) => state.account);
  const { thankyou: thankyouContent } = translations;
  const { dao_created, dao_link_sent } = thankyouContent;

  const { ossTimeout } = useSelector((state: any) => state.shipping);
  const { userTrackId, ixopayDetails, dhlPassTrkId } = useSelector((state: any) => state.corporateAccount);
  const dispatch = useDispatch();
  const { submitPostData } = useSelector((state: any) => state.DAOReducer);
  const { clientId } = useSelector((state: any) => state.app);

  useEffect(() => {
    // TODO: As discussed with team, needs a better solution for this
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  const updatedContactLangauge = thankyouContent.CONTACT_US_INFORMATION_LINK;

  const setContactUsText = (text) => {
    return replace(text, '{{contactLink}}', updatedContactLangauge);
  };

  useEffect(() => {
    if (
      (userTrackId && submitPostData?.accountId) ||
      (ixopayDetails?.dhltrackingid && ixopayDetails?.accountRequestId) ||
      (dhlPassTrkId && submitPostData?.accountId)
    ) {
      dispatch(
        postUserTrackId({
          trackingId: userTrackId || ixopayDetails.dhltrackingid || dhlPassTrkId,
          activityEndPage: pageMapper.thankyou,
          accReqId: submitPostData.accountId || ixopayDetails.accountRequestId,
        })
      );
    }
  }, [submitPostData?.accountId, ixopayDetails]);

  return (
    <div className="row thankyou-card-wrap dao-thankyou-section">
      <div className="col-md-12">
        {!ossTimeout ? (
          <>
            <div className="text-center mx-auto thankyou-page-section1">
              <img
                src={checkmark_circle_green}
                alt={dao_created?.ICON_CHECKMARK_ALT}
                className="thankyou-dao-success-img"
              />
              <p className="thankyou-title">
                <span
                  dangerouslySetInnerHTML={{
                    __html: dao_created?.TITLE,
                  }}
                ></span>
              </p>
              <p className="thankyou-subtitle_2">
                <span
                  dangerouslySetInnerHTML={{
                    __html: dao_created?.SUBTITLE,
                  }}
                ></span>
              </p>
              <p className="thankyou-subtitle_2">
                <span
                  dangerouslySetInnerHTML={{
                    __html: dao_created?.SUBTITLE2,
                  }}
                ></span>
              </p>
              <p className="thankyou-dao-contactus">
                <span
                  dangerouslySetInnerHTML={{
                    __html: setContactUsText(dao_created?.CONTACT_US_INFORMATION),
                  }}
                ></span>
              </p>
            </div>
            {dao_created[clientId] && dao_created[clientId].BUTTON_LINK && dao_created[clientId].BUTTON_TEXT && (
              <div className="text-center thankyou-action-wrap">
                <a
                  className="mt-0 btn btn-primary"
                  href={dao_created[clientId]?.BUTTON_LINK}
                >
                  {dao_created[clientId]?.BUTTON_TEXT}
                </a>
              </div>
            )}
          </>
        ) : (
          <div className="text-center mx-auto thankyou-page-section1">
            <p className="thankyou-title">
              <span
                dangerouslySetInnerHTML={{
                  __html: dao_link_sent?.TITLE,
                }}
              ></span>
            </p>
            <p className="thankyou-subtitle">
              <span
                dangerouslySetInnerHTML={{
                  __html: dao_link_sent?.SUBTITLE,
                }}
              ></span>
            </p>
            <p className="thankyou-dao-contactus">
              <span
                dangerouslySetInnerHTML={{
                  __html: setContactUsText(dao_link_sent?.CONTACT_US_INFORMATION),
                }}
              ></span>
            </p>
          </div>
        )}
      </div>
    </div>
  );
}
export default DaoThankYouPage;
